import { Button, Popconfirm } from "antd";
import { useSearchParams } from "react-router-dom";
import { DisconnectOutlined, InfoCircleFilled } from "@ant-design/icons";
import Paragraph from "antd/es/typography/Paragraph";

import { BodyText } from "@/components/styled_text";
import { useAuthStore } from "@/state/auth_store";
import { getCurrentOrganisation } from "@/utilities/organisations";
import { Column, Row } from "@/components/styled_layout";
import { ruminatiColors } from "@/utilities/colors";
import { useOrganisationMembers, useOrganisationPartners } from "@/hooks/organisations";
import { composeIconUrl } from "@/utilities/partners";
import { RelationshipRecipientTypeOrganisationTB } from "@ruminati/types/relationship";
import LoadingPlaceholder from "@/components/loading_placeholder";
import { formatDateTimestampAsPrettyString2 } from "@/utilities/dates";
import { useCreateRelationship, useDeleteRelationship } from "@/hooks/relationships";
import { ResourceTypeOrganisationTB } from "@ruminati/types/resource";
import { getResourceRelationship } from "@/utilities/permissions";
import { ROLE_ORG_ADMIN } from "@ruminati/permissions/roles";

export enum PartnerManagementPopupId {
  AddMember = 'add-member',
  AddPropertySpecificRole = 'add-property-specific-role',
  RoleDescriptionPopup = 'role-description',
  DeleteMember = 'delete-member'
}

export default function OrgPartnersTab() {
  const authStore = useAuthStore()
  const [_searchParams, setSearchParams] = useSearchParams()

  const currentOrg = getCurrentOrganisation(authStore.user!);

  if (!authStore.user || !currentOrg) {
    return undefined
  }

  const {
    isPending: allAvailablePartnersPending,
    data: allAvailablePartners
  } = useOrganisationPartners()

  const {
    isPending: orgMembersPending,
    data: allOrgMembers
  } = useOrganisationMembers()

  const createRelationship = useCreateRelationship()
  const deleteRelationship = useDeleteRelationship()
  const orgResourcePermissions = getResourceRelationship(authStore.user!, currentOrg.id)
  const userIsOrgAdmin = orgResourcePermissions?.roleId === ROLE_ORG_ADMIN.id

  const partnerOrgs = allOrgMembers?.filter(m => m.recipientType === RelationshipRecipientTypeOrganisationTB.const)
  const activePartnerOrgIds = partnerOrgs?.map(p => p.recipientId)

  const usedPartners = allAvailablePartners?.filter(p => activePartnerOrgIds?.includes(p.id))
  const unusedPartners = allAvailablePartners?.filter(p => !activePartnerOrgIds?.includes(p.id))

  if (orgMembersPending || allAvailablePartnersPending) return <LoadingPlaceholder />

  return (<Column style={{ padding: '0px 20px' }}>
    <Row style={{
      width: "100%",
      marginTop: '20px',
      marginBottom: '40px'
    }}>
      <BodyText style={{ lineHeight: '20px' }}>The Ruminati Partner Network is a group of companies who can help you understand the emissions reporting space, as well as assist in completing your emissions report.</BodyText>
    </Row>
    
    {(usedPartners && usedPartners?.length > 0) && 
      <Row style={{ width: "100%", justifyContent: 'flex-start', marginBottom: '16px' }}>
        <BodyText style={{ fontSize: '16px', fontWeight: 600 }}>Active Partners</BodyText>
      </Row>
    }
    
    {(usedPartners && usedPartners?.length > 0) && usedPartners?.map(p => {
      const partnerMembership = partnerOrgs?.find(po => po.recipientId === p.id)!

      return <Row
          key={p.id}
          style={{
            border: `1px solid ${ruminatiColors.green_3_15}`,
            background: ruminatiColors.green_3_5,
            borderRadius: '8px',
            padding: '20px',
            width: '100%',
          }}
        >
          {p.iconSmallUrl &&
            <Column style={{ alignItems: 'flex-start' }}>
              <img src={composeIconUrl(p.iconSmallUrl)} height="40px" style={{ borderRadius: '4px', marginRight: '12px' }} />
            </Column>
          }
          <Column style={{ width: '35%', alignItems: 'flex-start' }}>
            <Row style={{ marginBottom: '4px' }}>
              <BodyText style={{ color: ruminatiColors.dark_green }}>{p.name}</BodyText>
            </Row>
            <Row>
              {partnerMembership.joinedDate &&
                <BodyText style={{ color: ruminatiColors.green_3_50 }}>
                  Connected {formatDateTimestampAsPrettyString2(partnerMembership?.joinedDate)}
                </BodyText>
              }
              {partnerMembership.invitedDate && !partnerMembership.joinedDate &&
                <BodyText style={{ color: ruminatiColors.green_3_50 }}>
                  Awaiting Acceptance - {formatDateTimestampAsPrettyString2(partnerMembership?.invitedDate)}
                </BodyText>
              }
            </Row>
          </Column>
          <Column style={{ flexGrow: 1 }}>
            <Button type="link" onClick={() => {
              setSearchParams({
                tab: 'access_management',
                partner: p.id
              })
            }}>
              View Allocated Members
            </Button>
          </Column>
          <Column>
            <Popconfirm
              title="Remove Partner"
              overlayStyle={{
                width: '300px'
              }}
              description={`Are you sure to remove ${p.name} as a partner? This will remove access for all ${p.name} members.`}
              onConfirm={async () => {
                if (partnerMembership) {
                  await deleteRelationship.mutateAsync({
                    relationshipId: partnerMembership.relationshipId!
                  })
                }
              }}
              color={ruminatiColors.bone}
            >
              <Button
                color="danger"
                variant="link"
                icon={<DisconnectOutlined />}
                disabled={!userIsOrgAdmin}
              >
                Disconnect
              </Button>
            </Popconfirm>
          </Column>
        </Row>
    })}

    <Row style={{
      width: "100%",
      justifyContent: 'flex-start',
      marginBottom: '16px',
      marginTop: usedPartners && usedPartners?.length > 0 ? '40px' : '20px'
    }}>
      <BodyText style={{ fontSize: '16px', fontWeight: 600 }}>
        Available Partners
      </BodyText>
    </Row>

    <Row style={{
      width: "100%",
      justifyContent: "flex-start",
      columnGap: '24px',
      rowGap: '24px',
      marginBottom: '20px'
    }}>
      <Column
        style={{
          border: `1px solid ${ruminatiColors.green_3_15}`,
          background: ruminatiColors.green_3_10,
          borderRadius: '8px',
          padding: '20px',
          alignItems: 'center',
          justifyContent: 'center',
          width: '33%',
          height: '270px'
        }}
      >
        <Row style={{ marginBottom: '10px' }}>
          <Column>
            <BodyText style={{ fontSize: '15px', color: ruminatiColors.dark_green }}>Want to list your business?</BodyText>
          </Column>
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <BodyText style={{
            lineHeight: '20px',
            color: ruminatiColors.dark_green,
            textAlign: 'center'
          }}>
            If you'd like to join the Ruminati Partner Network contact us at hello@ruminati.com.au
          </BodyText>
        </Row>
      </Column>
      {unusedPartners?.map(p => {
        return <Column
          key={p.id}
          style={{
            border: `1px solid ${ruminatiColors.green_3_15}`,
            background: ruminatiColors.green_3_5,
            borderRadius: '8px',
            padding: '20px',
            alignItems: 'flex-start',
            width: '33%',
            height: '270px'
          }}
        >
          <Row style={{ marginBottom: '10px' }}>
            {p.iconSmallUrl &&
              <Column style={{ marginRight: '12px' }}>
                <img src={composeIconUrl(p.iconSmallUrl)} height="40px" style={{ borderRadius: '4px' }} />
              </Column>
            }
            <Column>
              <BodyText style={{ color: ruminatiColors.dark_green }}>{p.name}</BodyText>
            </Column>
          </Row>
          <Row>
            <Paragraph ellipsis={{ rows: 6 }}>
              {p.metadata?.description}
            </Paragraph>
          </Row>
          <Popconfirm
            title="Request Partner Support"
            overlayStyle={{
              width: '300px'
            }}
            icon={<InfoCircleFilled style={{
              color: ruminatiColors.green_3
            }} />}
            description={`Adding ${p.name} as a partner will enable them to allocate their staff members to assist you. You'll recieve an email notification when this happens.`}
            onConfirm={async () => {
              await createRelationship.mutateAsync({
                resourceId: currentOrg.id,
                resourceType: ResourceTypeOrganisationTB.const,
                recipientId: p.id,
                recipientType: ResourceTypeOrganisationTB.const,
                isPending: true,
                revokedPermissions: false
              })
            }}
            color={ruminatiColors.bone}
          >
            <Button ghost disabled={!userIsOrgAdmin}>Request</Button>
          </Popconfirm>
        </Column>
      })}
    </Row>

  </Column>
  );
}
