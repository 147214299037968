import styled from "styled-components";
import { Avatar } from "antd";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

import { BodyText } from "@/components/styled_text";
import { useAuthStore } from "@/state/auth_store";
import { getCurrentOrganisation } from "@/utilities/organisations";
import { Column, Row } from "@/components/styled_layout";
import { ruminatiColors } from "@/utilities/colors";
import { usePopupStore } from "@/state/popup_store";
import Icon from "@/components/icon";
import { useOrganisationMembers, useOrganisationPartners } from "@/hooks/organisations";
import { 
  ROLE_ORG_ADMIN,
  ROLE_PARTNER_ORG_ADMIN,
  INTERNAL_FARM_ORG_ROLES,
  EXTERNAL_FARM_ORG_ROLES,
  PARTNER_ORG_ROLES
 } from "@ruminati/permissions/roles";

import {
  getResourcePermissions, 
  permissionsAllowCreateInvite,
  permissionsAllowEdit
} from "@/utilities/permissions";
import { UserClientT, UserMemberOfOrganisationT } from "@ruminati/types/user";
import { RelationshipRecipientTypeOrganisationTB, RelationshipRecipientTypeUserTB } from "@ruminati/types/relationship";
import MainButton from "@/components/buttons/main_button";
import { composeIconUrl } from "@/utilities/partners";
import { OrganisationT, OrganisationTypeRecordsTB } from "@ruminati/types/organisation";
import MemberRow from "@/components/access/member_row";
import LoadingPlaceholder from "@/components/loading_placeholder";
import { SubscriptionPrimeTB } from "@ruminati/types/subscription";


export enum AccessManagementPopupId {
    AddMember = 'add-member',
    AddPropertySpecificRole = 'add-property-specific-role',
    RoleDescriptionPopup = 'role-description',
    DeleteMember = 'delete-member'
}

type PartnerWithMembers = {
  partner: OrganisationT
  members: UserMemberOfOrganisationT[]
}

export default function AccountAccessManagementTab() {
  const authStore = useAuthStore()
  const popupStore = usePopupStore()
  const [searchParams] = useSearchParams()
 
  useEffect(() => {
    if (searchParams.has('partner')) {
      setTimeout(() => {
        const id = searchParams.get('partner')
        const element = document.getElementById(id!)
        if (element) element.scrollIntoView()
      }, 0);
    }
  }, [searchParams]);

  const currentOrg = getCurrentOrganisation(authStore.user!);
  
  if (!authStore.user || !currentOrg) {
    return undefined
  }

  const orgIsPrime = currentOrg.subscription.type === SubscriptionPrimeTB.const

  const {
    isPending: partnersPending, 
    data: allPartners
  } = useOrganisationPartners()

  const {
    isPending, 
    data: allOrgMembers
  } = useOrganisationMembers()

  const orgPermissions = getResourcePermissions(authStore.user, currentOrg.id)
  const allowedToInvite = permissionsAllowCreateInvite(orgPermissions)

  if (isPending || partnersPending) return <LoadingPlaceholder/>
  if (!allOrgMembers || !authStore.user || !orgPermissions) return undefined

  const partnerOrgs = allOrgMembers.filter(m => m.recipientType === RelationshipRecipientTypeOrganisationTB.const)
  const partnerOrgIds = partnerOrgs.map(p => p.recipientId)

  const userMembers = allOrgMembers.filter(m => m.recipientType === RelationshipRecipientTypeUserTB.const)

  const partnerMembers = userMembers.filter(m => m.recipientDetails.primaryOrganisationId && partnerOrgIds.indexOf(m.recipientDetails.primaryOrganisationId) !== -1)

  const partnerMemberIds = partnerMembers.map(p => p.recipientId).filter(pid => pid !== undefined)

  const regularMembers = userMembers.filter(m => (m.recipientId && partnerMemberIds.indexOf(m.recipientId) === -1) || (!m.recipientId && m.recipientDetails.email))

  const partnerMembersGrouped: PartnerWithMembers[] = []
  partnerOrgs?.forEach(orgRecipient => {
    const partner = allPartners?.find(p => p.id === orgRecipient.recipientId)
    const members = partnerMembers.filter(m => m.recipientDetails.primaryOrganisationId === orgRecipient.recipientId)
    if (partner) {
      partnerMembersGrouped.push({
        partner,
        members
      })
    }
  })
  const adminRoleForOrg = currentOrg.type === OrganisationTypeRecordsTB.const ? ROLE_PARTNER_ORG_ADMIN : ROLE_ORG_ADMIN

  const orgAdmins = regularMembers.filter((m) => {
    if (m.role.id === adminRoleForOrg.id) return true;
    return false;
  })

  const orgMembers = regularMembers.filter((m) => {
    if (m.role.id !== adminRoleForOrg?.id && m.direct) return true;
    return false;
  })

  const currentOrgIsPartnerOrg = currentOrg?.type === OrganisationTypeRecordsTB.const
  const userIsFromPartnerOrg = partnerOrgIds.indexOf(authStore.user.userPreferences?.primaryOrganisationId) > -1

  const hasMoreThanOneActiveOrgAdmin = orgAdmins.length > 1 && orgAdmins.filter((a) => a.joinedDate).length > 1;

  return (
    <Column
      style={{
        padding: '0px 20px'
      }}
    >

    <Row style={{
      width: "100%",
      justifyContent: "space-between",
      marginTop: '20px',
      marginBottom: '10px'
    }}>
      <Column>
        <SectionHeading>Organisation Members</SectionHeading>
      </Column>
      <Column>
        <MainButton
          colorScheme="green"
          onClick={() => popupStore.addPopup(AccessManagementPopupId.AddMember)}
          disabled={!allowedToInvite || !orgIsPrime}
          disabledMessage={
            !orgIsPrime ? "Upgrade your Organisation to PRIME to invite members" :
            "You do not enough permissions to invite others to this Organisation"
          }
          size="small"
        >
          <div style={{ padding: "0 8px 0 4px" }}>
            <Icon icon="add" />
          </div>
          Add Member
        </MainButton>
      </Column>
    </Row>

    {orgAdmins.map((m, idx) => {
      // Note - this prevents the Admin for making themselves 'Not Admin'
      // when there is only 1 Admin
      const onlyOneAdmin = m.joinedDate && !hasMoreThanOneActiveOrgAdmin ? true : false;
      const relationshipPermissions = getResourcePermissions(authStore.user!, m.relationshipId)
      const allowedToEdit = permissionsAllowEdit(relationshipPermissions)

      return <MemberRow
        key={idx}
        member={m}
        overrideDisable={!orgIsPrime ? {
          reason: "Upgrade your Organisation to PRIME to modify existing members roles"
        } : userIsFromPartnerOrg ? {
          reason: "You do not have enough permissions to Edit this role"
        } : (onlyOneAdmin && allowedToEdit) ? {
          reason: "There is only 1 Administrator in this Organisation"
        } : undefined}
        availableRoles={currentOrgIsPartnerOrg ? PARTNER_ORG_ROLES : INTERNAL_FARM_ORG_ROLES}
      />
    })}

    {orgMembers.map((m, idx) => <MemberRow
      availableRoles={currentOrgIsPartnerOrg ? PARTNER_ORG_ROLES : INTERNAL_FARM_ORG_ROLES}
      overrideDisable={!orgIsPrime ? {
        reason: "Upgrade your Organisation to PRIME to modify existing members roles"
      } : userIsFromPartnerOrg ? {
        reason: "You do not have enough permissions to Edit this role"
      } : undefined}
      key={idx}
      member={m}
    />)}

    {partnerMembersGrouped.length > 0 && 
      <PartnerSection 
        partnerMembersGrouped={partnerMembersGrouped} 
        currentUser={authStore.user}
      />
    }

    </Column>
  );
}

function PartnerSection (props: {
  partnerMembersGrouped: PartnerWithMembers[]
  currentUser: UserClientT
}) {
  return <>
      <Row style={{
      width: "100%",
      justifyContent: "space-between",
      marginTop: '80px',
      marginBottom: '20px',
    }}>
      <Column>
        <SectionHeading>Partner Members</SectionHeading>
      </Column>
    </Row>
    
    {
      props.partnerMembersGrouped?.map((p, pIdx) => {
        return <Row 
        key={pIdx}
        id={p.partner.id}
        style={{
          width: "100%",
          justifyContent: "space-between",
          marginBottom: '10px',
          border: `1px solid ${ruminatiColors.green_3_15}`,
          background: ruminatiColors.green_3_5,
          borderRadius: '8px',
          padding: '20px',
        }}>
          <Column style={{width: "100%"}}>
          <Row style={{
            width: '100%',
            justifyContent: 'flex-start', 
            columnGap: '12px',
            paddingBottom: '12px',
            borderBottom: `1px solid ${ruminatiColors.green_3_30}`
          }}>
            <Avatar src={p.partner.iconSmallUrl ? composeIconUrl(p.partner.iconSmallUrl) : undefined} />
            <BodyText>{p.partner.name}</BodyText> 
          </Row>
          
          {p.members.length > 0 && p.members.map((m, mIdx) => {
            return <MemberRow
              key={mIdx}
              member={m}
              availableRoles={EXTERNAL_FARM_ORG_ROLES}
            />
          })}
          {p.members.length === 0 && 
            <BodyText style={{marginTop: '30px', marginBottom: '10px'}}>
              No staff have been allocated yet - this will be done by {p.partner.name}.
            </BodyText>
          }
          </Column>
        </Row>
      })
    }
    </>
}

const SectionHeading = styled(BodyText)`
  color: ${ruminatiColors.green_3};
  font-size: 16px;
  font-weight: 600;
`;


