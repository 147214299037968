import { Button } from "antd"
import { useAcceptRelationship } from "@/hooks/relationships"
import { Column, Row } from "../styled_layout"
import { LooserBodyText } from "../styled_text"
import { ruminatiColors } from "@/utilities/colors"
import { useAuthStore } from "@/state/auth_store"

export default function RelationshipUserInvitedToOrgTo (props: {
  relationshipId: string
  orgName: string
}) {
  const acceptRelationship = useAcceptRelationship()
  const authStore = useAuthStore()
  const relationship = authStore.user?.relationships?.find(r => r.id === props.relationshipId)
  if (!relationship || relationship.deletedAt || !authStore.user) return

  return <>
  <Row style={{
    width: '100%',
    justifyContent: 'flex-start',
    marginBottom: '12px'
  }}>
    <Column style={{
      flexGrow: 1,
      alignItems: 'flex-start'
      }}>
      <LooserBodyText style={{
        color: ruminatiColors.green_4
      }}>
         You've been invited to help {props.orgName} with their emissions data.
         {relationship?.isPending && 
            <Button 
            type="primary" 
            size="small" 
            ghost
            onClick={() => {
              if (!authStore.user) return
              acceptRelationship.mutateAsync({
                relationshipId: props.relationshipId,
                recipientId: authStore.user.uid
              })
            }}
            >
              Accept
            </Button>
         }
      </LooserBodyText>
    </Column>
  </Row>
</>
}