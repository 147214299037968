import {
    MouseEventHandler,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import styled from "styled-components";
import SearchIcon from "/icons/search-32.svg";
import AnimateHeight from "react-animate-height";
import MainButton from "./buttons/main_button";
import { Column, Row } from "./styled_layout";
import { BodyText, ButtonText } from "./styled_text";
import { CheckBox } from "./form/check_box";
import {
    DropdownOption,
    DropdownOptions,
    InputWrapper,
    StyledInput,
} from "./styled_input";
import { ruminatiColors } from "../utilities/colors";
import useWindowSize from "../hooks/useWindowSize";
import OutlineButton from "./buttons/outline_button";
import Icon from "./icon";

/**
 * @param buttonText text of the button to open/close the dropdown menu
 * @param options an array of checkbox options.
 * @param chosenOptions an array of checked options.
 * @param searchable optional. Whether to show search bar, defaults to `false`.
 * @param searchPlaceholder optional. Search placeholder text,
 * defaults to "Search".
 * @param onApply callback when `Apply` button is clicked, or when a chosen
 * option is cancelled.
 */
export type Menu = {
    buttonText: string;
    options: string[];
    searchable?: boolean;
    searchPlaceholder?: string;
};

/**
 * Props required by {@link DropdownMultiselectGroup}.
 *
 * @param menus an array of {@link Menu}
 * @param options an array of checkbox options.
 * @param chosenOptions an array of checked options.
 * @param searchable optional. Whether to show search bar, defaults to `false`.
 * @param searchPlaceholder optional. Search placeholder text,
 * defaults to "Search".
 * @param onApply callback when `Apply` button is clicked, or when a chosen
 * option is cancelled.
 */

type DropdownMultiselectGroupProps = {
    menus: Menu[];
    chosenOptions: string[];
    onApply: (options: string[]) => void;
};

/**
 * Props required by {@link DropdownMultiselectGroup}.
 *
 * @param menus an array of {@link Menu}
 * @param options an array of checkbox options.
 * @param chosenOptions an array of checked options.
 * @param searchable optional. Whether to show search bar, defaults to `false`.
 * @param searchPlaceholder optional. Search placeholder text,
 * defaults to "Search".
 * @param onApply callback when `Apply` button is clicked, or when a chosen
 * option is cancelled.
 */
export default function DropdownMultiselectGroup(
    props: DropdownMultiselectGroupProps
) {
    return (
        <Row>
            {props.menus.map((menu: Menu, index) => (
                <DropdownMultiselect
                    key={index}
                    menu={menu}
                    chosenOptions={props.chosenOptions}
                    onApply={props.onApply}
                />
            ))}

            <Row style={{ flexWrap: "wrap", justifyContent: "normal" }}>
                {props.chosenOptions.map((option: string, index) => (
                    <ActiveOption
                        key={index}
                        text={option}
                        onClick={() => {
                            props.onApply(
                                props.chosenOptions.filter(
                                    (checkedOption) => checkedOption !== option
                                )
                            );
                        }}
                    />
                ))}
            </Row>
        </Row>
    );
}

/**
 * Props required by {@link DropdownMultiselect}.
 *
 * @param menu the dropdown {@link Menu}
 * @param chosenOptions an array of checked options.
 * @param onApply callback when `Apply` button is clicked, or when a chosen
 * checkbox option is cancelled.
 */
type DropdownMultiselectProps = {
    menu: Menu;
    chosenOptions: string[];
    onApply: (options: string[]) => void;
};

/**
 * A dropdown menu with checkboxes.
 * @param props {@link DropdownMultiselectProps}.
 */
function DropdownMultiselect(props: DropdownMultiselectProps) {
    // All options must be unique.
    useEffect(() => {
        if (new Set(props.menu.options).size !== props.menu.options.length) {
            throw new Error("Each menu option must be unique.");
        }
    }, [props.menu.options]);

    // Options filtered by applying `searchString`.
    const [filteredOptions, setFilteredOptions] = useState(props.menu.options);

    // Currently checked options.
    const [checkedOptions, setCheckedOptions] = useState(props.chosenOptions);

    // Search text.
    const [searchString, setSearchString] = useState<string>("");

    // Whether the dropdown menu is opened.
    const [isActive, setIsActive] = useState(false);

    const wrapperRef = useRef<HTMLDivElement>(null);

    const [, height] = useWindowSize();

    const [maxOptionHeight, setMaxOptionHeight] = useState<number>(100);

    const [maxOverlayHeight, setMaxOverlayHeight] = useState<number>(100);

    const [timeoutVal, setTimeoutVal] = useState<number | undefined>(undefined);

    // Close dropdown menu when clicked outside.
    const closeDropdown = (e: MouseEvent) => {
        if (
            wrapperRef.current &&
            isActive &&
            !wrapperRef.current.contains(e.target as Node)
        ) {
            window.clearTimeout(timeoutVal);
            setCheckedOptions(props.chosenOptions);
            setIsActive(false);
        }
    };

    // Calculate the distance of the select field to the bottom of the screen
    // and limit dropdown option box to that height.
    const calcMaxHeight = useCallback(() => {
        if (wrapperRef.current) {
            const top =
                window.scrollY + wrapperRef.current.getBoundingClientRect().top;

            const pageHeight = Math.max(
                document.body.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.clientHeight,
                document.documentElement.scrollHeight,
                document.documentElement.offsetHeight
            );

            const bottomDistance = pageHeight - top - 100;
            const viewportSpace = height ?? window.innerHeight / 2 - 100;

            const maxHeight = Math.min(bottomDistance, viewportSpace);
            setMaxOptionHeight(maxHeight);
        }
    }, [wrapperRef, height]);

    // Calculate the distance of the select field to the bottom of the screen
    // and limit the blur overlay to that height.
    const calcOverlayHeight = useCallback(() => {
        if (wrapperRef.current) {
            const top =
                window.scrollY + wrapperRef.current.getBoundingClientRect().top;

            const pageHeight = Math.max(
                document.body.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.clientHeight,
                document.documentElement.scrollHeight,
                document.documentElement.offsetHeight
            );

            setMaxOverlayHeight(pageHeight - top);
        }
    }, [wrapperRef]);

    // Add/Remove `option` from `chosenOptions` when checking/unchecking.
    const checkBoxOnClick = (option: string) => {
        if (!checkedOptions.includes(option)) {
            setCheckedOptions([...checkedOptions, option]);
        } else {
            setCheckedOptions(
                checkedOptions.filter(
                    (checkedOption) => checkedOption !== option
                )
            );
        }
    };

    useEffect(() => {
        calcMaxHeight();
        calcOverlayHeight();
        window.clearTimeout(timeoutVal);

        // Recalculate in the case of animated elements.
        setTimeoutVal(window.setTimeout(() => calcMaxHeight(), 300));
        setTimeoutVal(window.setTimeout(() => calcOverlayHeight(), 300));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calcMaxHeight, calcOverlayHeight]);

    // Update options after a small delay when search string changes.
    useEffect(() => {
        const timeout = setTimeout(() => {
            setFilteredOptions(
                props.menu.options.filter(
                    (option) =>
                        option
                            .toLowerCase()
                            .indexOf(searchString.toLowerCase()) !== -1
                )
            );
        }, 200);
        return () => clearTimeout(timeout);
    }, [searchString, props.menu.options]);

    // Listens to mouse down event.
    document.addEventListener("mousedown", closeDropdown);

    // Close dropdown when scrolling.
    window.addEventListener("scroll", () => setIsActive(false));

    const emptyOptionHeight = 48;
    const optionHeight = 61;

    return (
        <ButtonColumn
            style={{
                flexWrap: "wrap",
                justifyContent: "normal",
                position: "relative",
            }}
            ref={wrapperRef}
        >
            <MainButton
                colorScheme={isActive ? "orange" : "green"}
                size="small"
                onClick={() => {
                    if (isActive) setCheckedOptions(props.chosenOptions);
                    setIsActive(!isActive);
                }}
            >
                <Icon icon="chevronDown" />
                <ButtonText>{props.menu.buttonText}</ButtonText>{" "}
            </MainButton>
            {isActive &&
                <>
                    <BlurOverlay
                        top={48}
                        onClick={() => {
                            setIsActive(false);
                        }}
                        visible={isActive}
                        height={maxOverlayHeight}
                    />

                    <DropdownCheckboxOptions
                        className="dropdown-options"
                        top={48}
                        maxHeight={maxOptionHeight}
                        visible={isActive}
                    >
                        {" "}
                        {/* Search field */}
                        {props.menu.searchable !== undefined &&
                            props.menu.searchable && (
                                <DropdownOption
                                    tabIndex={-1}
                                    key={"search"}
                                    padding={"12px 16px 12px 16px"}
                                    style={{ color: ruminatiColors.orange, flexShrink: 0 }}
                                >
                                    <img src={SearchIcon} alt="search icon"/>
                                    <div style={{ marginLeft: "6px" }} />

                                    <InputWrapper>
                                        <StyledInput
                                            autoFocus
                                            placeholder={props.menu.searchPlaceholder ?? "Search"}
                                            type={"text"}
                                            color={ruminatiColors.orange}
                                            spellCheck="false"
                                            value={searchString ?? ""}
                                            style={{
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                fontFamily: "TTInterfaces",
                                                lineHeight: "32px",
                                                letterSpacing: "0.02em",
                                                color: ruminatiColors.orange,
                                            }}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>
                                            ) => setSearchString(event.target.value)}
                                        />
                                    </InputWrapper>
                                </DropdownOption>
                            )}
                        {/* Scrollable options. */}
                        <AnimateHeight
                            duration={300}
                            height={
                                filteredOptions.length === 0
                                    ? emptyOptionHeight
                                    : filteredOptions.length * optionHeight
                            }
                            style={{
                                color: ruminatiColors.orange,
                                overflow: "auto",
                                overflowY: "auto",
                            }}
                        >
                            {filteredOptions.length === 0 && (
                                <DropdownOption
                                    key={"no results"}
                                    tabIndex={-1}
                                    padding={"8px 19px 8px 19px"}
                                >
                                    <p style={{ fontWeight: "lighter" }}>
                                        {" "}
                                        No matches
                                    </p>
                                </DropdownOption>
                            )}
                            {filteredOptions.map((option: string, index) => {
                                return (
                                    <DropdownOption
                                        tabIndex={-1}
                                        key={index}
                                        padding={"8px 19px 8px 19px"}
                                    >
                                        <CheckBox
                                            checkBoxKey={index}
                                            label={option}
                                            checked={checkedOptions.includes(
                                                option
                                            )}
                                            size={"16px"}
                                            onChange={() => {
                                                checkBoxOnClick(option);
                                            }}
                                            labelColor={ruminatiColors.orange}
                                            labelFontWeight={"500"}
                                            labelWidth={"100%"}
                                        />
                                    </DropdownOption>
                                );
                            })}
                        </AnimateHeight>
                        {/* Bottom CTAs - Apply/Cancel buttons */}
                        <DropdownOption
                            tabIndex={-1}
                            key={"apply-button"}
                            padding={"12px 16px 12px 16px"}
                            style={{
                                flexShrink: 0,
                                borderTop: "1px solid",
                                borderTopWidth: "1px",
                                borderTopColor: ruminatiColors.orange_40,
                            }}
                        >
                            <Row>
                                <MainButton
                                    colorScheme={"orange"}
                                    size="small"
                                    width="78px"
                                    onClick={() => {
                                        props.onApply(checkedOptions);
                                        setIsActive(!isActive);
                                    }}
                                >
                                    <ButtonText> Apply </ButtonText>
                                </MainButton>

                                <div style={{ marginLeft: "8px" }} />

                                <OutlineButton
                                    width="78px"
                                    height="40px"
                                    activeColor={ruminatiColors.orange}
                                    textColor={ruminatiColors.orange}
                                    onClick={() => {
                                        setCheckedOptions(props.chosenOptions);
                                        setIsActive(false);
                                    }}
                                >
                                    <ButtonText> Cancel </ButtonText>
                                </OutlineButton>
                            </Row>
                        </DropdownOption>
                    </DropdownCheckboxOptions>
                </>
            }
        </ButtonColumn>
    );
}

type ActiveOptionProps = {
    text: string;
    onClick: MouseEventHandler;
};

function ActiveOption(props: ActiveOptionProps) {

    return (
        <Row
            style={{
                color: ruminatiColors.dark_green,
                marginLeft: "22px",
                cursor: 'pointer'
            }}
            onClick={props.onClick}
        >
            <Icon icon="x" />
            <div style={{ marginLeft: "10px" }} />
            <BodyText>{props.text}</BodyText>
        </Row>
    );
}

const ButtonColumn = styled(Column)`
    margin-right: 8px;
    &:last-child {
        margin-right: none;
    }
`;

const DropdownCheckboxOptions = styled(DropdownOptions).attrs((props: { visible: boolean }) => props)`
    width: max(32vw, 32vh);
    color: ${ruminatiColors.orange}
    font-family: "TTInterfaces", sans-serif;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    z-index: ${(props) => `${props.visible ? 9999 : 0}`};
    opacity: ${(props) => `${props.visible ? 1 : 0}`};
    transition: 0.2s ease opacity;
`;

const BlurOverlay = styled.div.attrs((props: { top: number, visible: boolean, height: number }) => props)`
    top: ${(props) => `${props.top}px`};
    position: absolute;
    height: ${(props) => `${props.height - 100}px`};
    width: 200vw;
    transition: 0.3s ease background-color;
    background-color: ${ruminatiColors.bone_60};
    overflow: hidden;
    z-index: ${(props) => `${props.visible ? 9998 : 0}`};;
    opacity: ${(props) => `${props.visible ? 1 : 0}`};
    transition: 0.2s ease opacity;
`;
