import styled from "styled-components";
import type { MapRef } from 'react-map-gl/maplibre';
import { FeatureCollection } from "geojson";
import { Component } from 'react';

import { ruminatiColors } from "../../utilities/colors";
import Icon from "../icon";
import { BodyText } from "../styled_text";
import DrawingTools from '../../libs/drawing-tools/index.js'
import '../../libs/drawing-tools/index.css'

import { withHooksHOC } from '../maps/map_hoc'

enum EditPropertyTools {
    Draw,
    Edit,
    Erase,
    None
}

type EditPropertyMenuProps = {
    map: MapRef;
    geojsonToEdit: FeatureCollection;
    finishEditing: (fc: FeatureCollection) => void;
    drawingColor?: string;
    drawnColor?: string;
};

type EditPropertyMenuState = {
    drawControl: undefined | DrawingTools
    selectedTool: EditPropertyTools
    mapHeight: number
};

class EditPropertyMenu extends Component<EditPropertyMenuProps, EditPropertyMenuState> {

    state: EditPropertyMenuState = {
        drawControl: undefined,
        selectedTool: EditPropertyTools.None,
        mapHeight: 500
    }

    componentDidMount(): void {
        // TO DO - This is being called multiple times but I'm not sure why ....
        if (this.mapIsMountedAndReadyForDrawingTools(this.props.map)) {
            this.setupDrawControl()
        }
    }

    componentDidUpdate(prevProps: EditPropertyMenuProps) {
        if (this.mapIsMountedAndReadyForDrawingTools(this.props.map) && (!this.mapIsMountedAndReadyForDrawingTools(prevProps.map) || this.state.drawControl === undefined)) {
            if (this.props.map.isStyleLoaded()) {
                this.setupDrawControl()
            } else {
                this.props.map.on('load', () => {
                    this.setupDrawControl()
                })
            }
        }
    }

    componentWillUnmount(): void {
        if (this.state.drawControl === undefined) return

        this.state.drawControl.deleteAll()
        this.props.map.removeControl(this.state.drawControl)
        this.setState({
            drawControl: undefined,
            selectedTool: EditPropertyTools.None,
            mapHeight: this.props.map.getCanvas().clientHeight
        })
    }

    mapIsMountedAndReadyForDrawingTools(map: MapRef | undefined): boolean {
        if (map === undefined) return false
        return true
    }

    setupDrawControl() {
        // It's important to make sure we only create a drawing tools once
        if (this.props.map.getSource('drawing-tools-drawn-polygon-src') === undefined) {
            const drawControl = new DrawingTools({
                drawControls: ['polygon'],
                updateModes: ['edit-vertices', 'delete'],
                showToolbar: false,
                showHelpText: false,
                styles: {
                    drawn: {
                        polygon: {
                            paint: {
                                'fill-opacity': [
                                    'case',
                                    ['boolean', ['feature-state', 'hovered'], false],
                                    0.6,
                                    0.4
                                  ]
                            }
                        },
                        linestring: {
                            paint: {
                                'line-width': 3.5
                            }
                        }
                    },
                    drawingColor: this.props.drawingColor ?? "#A8C8DA",
                    drawnColor: this.props.drawnColor ?? ruminatiColors.orange
                }
            })

            this.props.map.addControl(drawControl, 'top-left')
            drawControl.addGeojson(this.props.geojsonToEdit)
            drawControl.on('dt:draw-cancelled', () => {
                this.setState({
                    selectedTool: EditPropertyTools.None
                })
            })
            drawControl.on('dt:update-cancelled', () => {
                this.setState({
                    selectedTool: EditPropertyTools.None
                })
            })

            this.setState({
                drawControl,
                mapHeight: this.props.map.getCanvas().clientHeight
            })
        }
    }

    startDrawPolygon() {
        if (this.state.drawControl === undefined) return
        this.setState({
            selectedTool: EditPropertyTools.Draw
        })
        this.state.drawControl.setActiveMode('polygon')
        this.state.drawControl.on('dt:draw-finished', () => {
            if (this.state.drawControl) this.props.finishEditing(this.state.drawControl.getAll())
            this.setState({
                selectedTool: EditPropertyTools.None
            })
        })
    }

    startEditTool() {
        if (this.state.drawControl === undefined) return
        this.setState({
            selectedTool: EditPropertyTools.Edit
        })
        this.state.drawControl.setActiveMode('edit-vertices')
        this.state.drawControl.on('dt:update-finished', () => {
            if (this.state.drawControl) this.props.finishEditing(this.state.drawControl.getAll())
            this.setState({
                selectedTool: EditPropertyTools.None
            })
        })
    }

    startErasePolygon() {
        if (this.state.drawControl === undefined) return
        const currentFc = this.state.drawControl.getAll()
        this.setState({
            selectedTool: EditPropertyTools.Erase
        })
        if (currentFc.features.length === 0) {
            this.setState({
                selectedTool: EditPropertyTools.None
            })
            return
        }
        this.state.drawControl.setActiveMode('delete')
        this.state.drawControl.on('dt:update-finished', () => {
            if (this.state.drawControl) this.props.finishEditing(this.state.drawControl.getAll())
            this.setState({
                selectedTool: EditPropertyTools.None
            })
        })
    }


    render() {
        return <div>
                <EditBoundsWrapper>
                    <EditBoundsButton
                        className={
                            this.state.selectedTool === EditPropertyTools.Draw ? "active" : ""
                        }
                        onClick={this.startDrawPolygon.bind(this)}
                    >
                        <Icon icon="circlePlus" />
                        <BodyText>Add</BodyText>
                    </EditBoundsButton>

                    <EditBoundsButton
                        onClick={this.startEditTool.bind(this)}
                        className={
                            this.state.selectedTool === EditPropertyTools.Edit ? "active" : ""
                        }
                    >
                        <Icon icon="draw" />
                        <BodyText>Edit</BodyText>
                    </EditBoundsButton>

                    <EditBoundsButton
                        onClick={this.startErasePolygon.bind(this)}
                        className={
                            this.state.selectedTool === EditPropertyTools.Erase ? "active" : ""
                        }
                    >
                        <Icon icon="circleMinus" />
                        <BodyText>Remove</BodyText>
                    </EditBoundsButton>

                </EditBoundsWrapper>
        </div>
    }
}

export default withHooksHOC(EditPropertyMenu)

const EditBoundsWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 142px;
  z-index: 10;
  display: flex;
  flex-direction: row;
`;

const EditBoundsButton = styled.div`
    background-color: ${ruminatiColors.bone};
    border: 1px solid ${ruminatiColors.green_3_50};
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 4px 16px 4px 8px;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 8px;
    color: ${ruminatiColors.dark_green};
    transition: 0.3s ease;
    cursor: pointer;
    user-select: none;
    
    p {
        transition: 0.3s ease;
    }

    svg path, svg circle {
        transition: 0.3s ease;
    }

    &:hover {
        background-color: #F0EEE9;
        p {
          color: ${ruminatiColors.dark_green};
        }
        svg path {
            fill: ${ruminatiColors.dark_green};
        }
    }

    &.active {
       p {
            color: ${ruminatiColors.orange};
        }

        svg path {
            fill: ${ruminatiColors.orange};
        }
        svg circle {
            stroke: ${ruminatiColors.orange};
        }
    }
`;
