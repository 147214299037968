import { useState } from "react";

import { AccountPageContainer } from './account_screen'

import {
    LeftAligned,
    RightAligned
} from "../../components/styled_layout";
import { BodyText } from "../../components/styled_text";
import Table from "../../components/table";
import MainButton from "../../components/buttons/main_button";

import { useAuthStore } from "../../state/auth_store";
import { usePopupStore } from "../../state/popup_store";

import { sendForgotPassword } from "../../services/auth_service";
import { ruminatiColors } from "../../utilities/colors";


type AccountDatum = {
    value: string;
    name: string;
};

export enum AccountProfilePopupId {
    Edit = 'edit-account',
    ChangePassword = 'change-password'
}

export default function AccountProfileTab() {
    const authStore = useAuthStore()
    const popupStore = usePopupStore()
    const user = authStore.user
    const [reset, setReset] = useState<boolean>(false);

    // Timeout for each reset password attempt.
    const [timeoutValue, setTimeoutValue] = useState<number | undefined>(
        undefined
    );

    function personalInformationSection () {
        const profileData: AccountDatum[] = [
            {
                value: user?.firstName ?? "",
                name: "First Name",
            },
            {
                value: user?.lastName ?? "",
                name: "Last Name",
            }
        ];
        return (
            <Table<AccountDatum>
                headers={[
                    <LeftAligned>
                        <BodyText>Profile Information</BodyText>
                    </LeftAligned>,

                    <RightAligned>
                        <MainButton
                            onClick={() => {
                                popupStore.addPopup(AccountProfilePopupId.Edit)
                            }}
                        >
                            Update
                        </MainButton>{" "}
                    </RightAligned>,
                ]}
                data={profileData}
                headerBackgroundColor={ruminatiColors.green_3_5}
                builder={(d: AccountDatum) => [
                    <LeftAligned>
                        <BodyText>{d.value}</BodyText>
                    </LeftAligned>,
                    <RightAligned>
                        <BodyText>{d.name}</BodyText>
                    </RightAligned>,
                ]}
            />)
    }

    function resetPassword () {
        if (user?.email) {
            sendForgotPassword(user.email);
            popupStore.addPopup(AccountProfilePopupId.ChangePassword)
            window.clearTimeout(timeoutValue);
            setReset(true);
            setTimeoutValue(window.setTimeout(() => setReset(false), 10000));
        }
    }

    function securitySection () {
        const accountData: AccountDatum[] = [
            {
                value: user?.email ?? "email",
                name: "Email",
            },
            {
                value: "*********************",
                name: "Password",
            },
        ];

        return (
            <Table<AccountDatum>
                headers={[
                    <LeftAligned>
                        <BodyText>Security</BodyText>
                    </LeftAligned>,

                    <RightAligned>
                        <MainButton onClick={resetPassword} disabled={reset}>
                            {reset ? "Email Sent" : "Change Password"}
                        </MainButton>
                    </RightAligned>,
                ]}
                data={accountData}
                headerBackgroundColor={ruminatiColors.green_3_5}
                builder={(d: AccountDatum) => [
                    <LeftAligned>
                        <BodyText>{d.value}</BodyText>
                    </LeftAligned>,
                    <RightAligned>
                        <BodyText>{d.name}</BodyText>
                    </RightAligned>,
                ]}
            />)
    }

    return (
        <AccountPageContainer style={{ rowGap: "32px"}}>
            {personalInformationSection()}
            {securitySection()}
        </AccountPageContainer>
    );
}
