import { Column, Row } from "../../../components/styled_layout";
import { MultiInputField } from "../../../components/form/multi_input_field"
import { BodyText } from "../../../components/styled_text"
import { formatNumber, formatReportNumber } from "../../../utilities/functions"
import { FieldSize, FormFieldRenderOptions, InputFormField, InputFormFieldArgs, State, TypeOrFunctionOfState } from "./form_field"

import { VaryingInfo } from "./multi_input_form_field";

import { ruminatiColors } from "../../../utilities/colors";

export type PercentageFieldOption = {
    key: string
    label: string
}

export type PercentageFieldSeperatorOption = {
    break: boolean
}

export class PercentageSplitFormField extends InputFormField<VaryingInfo> {
    title: string | undefined; // If set to undefined, title won't show
    total: number | undefined; // If set to undefined, total won't be used (and unit values won't be used either)
    _units: string;
    options: (PercentageFieldOption | PercentageFieldSeperatorOption)[];
    columnWidth?: FieldSize
    _additionalAction?: TypeOrFunctionOfState<JSX.Element>

    get _realOptions(): PercentageFieldOption[] {
        return this.options.filter(o => !('break' in o)) as PercentageFieldOption[]
    }

    get count(): number {
        return this._realOptions.length;
    }

    get _default(): { [key: string]: undefined } {
        return Object.fromEntries(this._realOptions.map((e) => [e.key, undefined]));
    }

    get _defaultExpanded(): { [key: string]: number } {
        return Object.fromEntries(this._realOptions.map((e) => [e.key, 100 / this.count]));
    }

    constructor(
        args: InputFormFieldArgs<VaryingInfo> & {
            options: (PercentageFieldOption | PercentageFieldSeperatorOption)[],
            title?: string,
            total?: number,
            units: string,
            columnWidth?: FieldSize
            additionalAction?: TypeOrFunctionOfState<JSX.Element>
        }
    ) {
        super(args);
        this.title = args.title;
        this.options = args.options;
        this.total = args.total;
        this._units = args.units;
        this.columnWidth = args.columnWidth
        this._additionalAction = args.additionalAction
    }


    _unfilled = (values?: VaryingInfo): number => {
        return values ? this._realOptions.reduce<number>((total, o) => total + (values[o.key] !== undefined ? 0 : 1), 0) : this.count
    }

    _total = (values?: VaryingInfo): number => {
        return values ? this._realOptions.reduce<number>((total, o) => total + (values[o.key] ?? 0), 0) : 0
    }

    _expand = (values?: VaryingInfo): { [key: string]: number } => {
        if (values) {
            const remaining = 100 - this._total(values)
            return Object.fromEntries(this._realOptions.map((o) => {
                if (values[o.key] === undefined) return [o.key, Math.max(0, Math.min(100, remaining / this._unfilled(values)))]
                return [o.key, values[o.key]!]
            }))
        }
        return this._defaultExpanded
    }

    _unitValue = (value?: number): string => {
        if (this.total === undefined) return "";
        if (value === undefined) return "";
        return `${formatReportNumber(this.total * value / 100, { maxDecimalSigFig: 2 })} ${this._units}/year`
    }

    validate(values?: VaryingInfo): boolean {
        if (this._unfilled(values) === 0) {
            return this._total(values) === 100;
        } else {
            return false
        }
    }

    // TODO: this seems silly, having this as well as validate above... but we basically want to _both_ validate as the user fills in fields, AND validate when the user tries to submit the form. Each requires a slightly different approach for when not all the fields are filled in.
    // to be fixed in the future when re-doing forms
    validateForError = (values?: VaryingInfo): boolean => {
        if (this._unfilled(values) === 0) {
            return this._total(values) === 100;
        } else {
            return this._total(values) <= 100;
        }
    }

    transforms = (values?: { [key: string]: string | undefined }): VaryingInfo => {
        if (values) {
            return Object.fromEntries(Object.keys(values).map((k) => {
                const value = values[k]
                if (value !== undefined) {
                    const num = parseInt(value)
                    if (!Number.isNaN(num)) return [k, num]
                }
                return [k, undefined]
            }));
        }
        return this._default;
    }

    displayValues = (values?: VaryingInfo): { [key: string]: string | undefined } => {
        if (values) {
            return Object.fromEntries(Object.keys(values).map((k) => {
                const value = values[k];
                if (value !== undefined) {
                    return [k, formatNumber(value, { maxDecimalPlaces: 2 }).toString()]
                }
                return [k, undefined];
            }))
        }
        return this._default
    }

    placeholders = (values?: VaryingInfo): { [key: string]: string } => {
        const valid = this.validate(values);
        const expanded = this._expand(values);
        const expandedDisplay = this.displayValues(expanded);

        return Object.fromEntries(this._realOptions.map((o) => {
            if (!valid && values && values[o.key] !== undefined) return [o.key, "Invalid %"];
            return [o.key, `${expandedDisplay[o.key]}`];
        }));
    };

    _calculateActualUse = (values?: VaryingInfo): { [key: string]: string } => {
        const expanded = this._expand(values);
        return Object.fromEntries(this._realOptions.map((o) => {
            return [o.key, this._unitValue(expanded[o.key])];
        }))
    }

    additionalAction(state: State<any>) {
        return this._additionalAction && this._additionalAction instanceof Function ? this._additionalAction(state) : undefined;
    }

    render(options: FormFieldRenderOptions<VaryingInfo>): JSX.Element {
        return <>
            {this.title !== undefined && 
            <Row 
            style={{
                alignContent: 'center',
                marginBottom: '6px'
            }}>
                <Column style={{width: '50%', alignItems: 'flex-start'}}>
                    <BodyText
                        style={{
                            fontSize: "16px",
                            fontWeight: 500,
                        }}
                    >
                        {this.title}
                    </BodyText>
                </Column>
                <Column style={{width: '50%', alignItems: 'flex-end'}}>
                    {this.total !== undefined &&
                        <BodyText
                            style={{
                                color: ruminatiColors.light_green,
                                fontSize: "14px",
                                fontWeight: 500,
                            }}
                        >
                            You reported a total of {formatReportNumber(this.total)} {this._units.replace('/year', ' for the year')}
                        </BodyText>
                    }
                    {this._additionalAction !== undefined ? this.additionalAction(options.state) : undefined }                
                </Column>
            </Row>
            }
            <MultiInputField
                keys={this.options.map((o, i) => {
                    if ('break' in o) return `seperator_${i}`
                    return o.key
                })}
                values={this.displayValues(options.value)}
                onChange={(values, _dp) => {
                    options.onChange(this.transforms(values))
                }}
                unit="%"
                placeholders={this.placeholders(options.value)}
                // if showMissingFields is true, then check if any real options are undefined, OR if validation fails to show error (will show required in first instance, invalid in second)
                error={options.showMissingFields
                    ? (this._realOptions.some(o => options.value && options.value[o.key] === undefined) || !this.validateForError(options.value))
                    : !this.validateForError(options.value)
                }
                labelsAbove={Object.fromEntries(this._realOptions.map((o) => [o.key, o.label]))}
                labelsInside={this._calculateActualUse(options.value)}
                size={options.size}
                columnWidth={this.columnWidth}
                state={options.state}
                showMissingFields={options.showMissingFields}
                disabled={options.forceDisabled ?? this._disabled}
            />
        </>
    }
}