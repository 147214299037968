import { useState } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";

import { OrganisationT, OrganisationTypeRuminatiTB } from "@ruminati/types/organisation";

import Screen from "../screen";

import Tabs from "../../components/tabs";
import AccountProfileTab from "./account_profile_tab";
import AccountIntegrationsTab from "./account_integrations_tab";
import { Column, Row } from "../../components/styled_layout";
import Heading from "../../components/heading";
import { InfoCard } from "@/components/card";
import AccountOrgMembershipsTab from "./account_org_memberships_tab";
import { useAuthStore } from "@/state/auth_store";

type PageDetails = {
  id: string
  tabLabel: string
  tabIndex: number
  content: JSX.Element
}

function getPages (ruminatiOrg: OrganisationT | undefined): PageDetails[] {
  const pages: PageDetails[] = [
    {
      id: 'profile',
      tabLabel: 'My Profile',
      tabIndex: 0,
      content: <AccountProfileTab />
    },
  ]

  // support user does not need these tabs
  if (!ruminatiOrg) {
    pages.push({
      id: 'orgs',
      tabLabel: 'My Organisation Memberships',
      tabIndex: 1,
      content: <AccountOrgMembershipsTab />
    })
    pages.push({
      id: 'integrations',
      tabLabel: 'My Data Integrations',
      tabIndex: 2,
      content: <AccountIntegrationsTab />
    })
  }

  return pages
}


const pages: PageDetails[] = [
  {
    id: 'profile',
    tabLabel: 'My Profile',
    tabIndex: 0,
    content: <AccountProfileTab />
  },
  {
    id: 'orgs',
    tabLabel: 'My Organisation Memberships',
    tabIndex: 1,
    content: <AccountOrgMembershipsTab />
  },
  {
    id: 'integrations',
    tabLabel: 'My Data Integrations',
    tabIndex: 2,
    content: <AccountIntegrationsTab />
  }
]

function getPageById (id: string): PageDetails | undefined {
    return pages.find(p => p.id === id)
}

export default function AccountDetailsScreen() {
  const authStore = useAuthStore()
  const user = authStore.user
  const ruminatiOrg = user?.organisations.find(o => o.type === OrganisationTypeRuminatiTB.const)

  const pages = getPages(ruminatiOrg)

    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedPage, setSelectedPage] = useState<PageDetails>(searchParams.has('tab') && getPageById(searchParams.get('tab') as string) !== undefined ? getPageById(searchParams.get('tab') as string) as PageDetails : pages[0])

    return (
        <Screen pageTitle="Account">
            <AccountMasterContainer>
                <Row style={{ justifyContent: "space-between", marginBottom: '20px'}}>
                    <Heading level={3}>Account Settings</Heading>
                </Row>

                <Row style={{ marginBottom: '20px'}}>
                  <InfoCard content={"Account Settings allow you to manage your own Ruminati account."} />
                </Row>

                <Row>
                    <Column style={{ alignSelf: "center", width: "100%" }}>
                        <Tabs
                            selected={selectedPage.tabIndex}
                            tabs={pages.map((v) => {
                                return {text: v.tabLabel}
                            })}
                            onTabChange={(index: number) => {
                                const newTab = pages.find(p => p.tabIndex === index) as PageDetails
                                setSelectedPage(newTab)
                                setSearchParams({
                                    tab: newTab.id
                                })
                            }}
                            layoutType="Line"
                        />
                        <AccountPageContainer id={"account-page"}>
                            {selectedPage.content}
                        </AccountPageContainer>
                    </Column>
                </Row>
            </AccountMasterContainer>
        </Screen>
    );
}

export const AccountPageContainer = styled.div`
    width: 100%;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
`;

export const AccountMasterContainer = styled.div`
    width: 936px;
    display: inline-flex;
    flex-direction: column;
    padding-top: 60px;
    margin-bottom: 40px;
`;
