import { PropsWithChildren } from "react"
import { Column, Row } from "../styled_layout"

export default function GeneralInformation (props: PropsWithChildren) {
  return <>
    <Row style={{width: '100%', justifyContent: 'flex-start'}}>
      <Column style={{alignItems: 'flex-start', rowGap: '8px'}}>
        {props.children}
      </Column>
    </Row>
    {/* <Row style={{width: '100%', justifyContent: 'flex-start'}}>
      <Button type="primary" size="small">Accept</Button>
    </Row> */}
  </>
}