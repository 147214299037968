import { AccountPageContainer } from './organisation_screen'

import {
    LeftAligned,
    RightAligned
} from "../../components/styled_layout";
import { BodyText } from "../../components/styled_text";
import Table from "../../components/table";
import MainButton from "../../components/buttons/main_button";

import { useAuthStore } from "../../state/auth_store";
import { usePopupStore } from "../../state/popup_store";

import { ruminatiColors } from "../../utilities/colors";
import { getCurrentOrganisation } from "@/utilities/organisations";
import { OrganisationT } from "@ruminati/types/organisation";
import { getResourcePermissions, permissionsAllowEdit } from '@/utilities/permissions';

type AccountDatum = {
    value: string;
    name: string;
};

export enum OrgProfilePopupId {
    Edit = 'edit-profile',
}

export default function OrganisationGeneralInformationTab() {
    const authStore = useAuthStore()
    const user = authStore.user;
    
    const currentOrg = user ? getCurrentOrganisation(user) : undefined
    if (!user || !currentOrg) return

    const orgPermissions = getResourcePermissions(user, currentOrg.id)
    const editAllowed = permissionsAllowEdit(orgPermissions)

    return (
        <AccountPageContainer style={{ rowGap: "32px"}}>
            {currentOrg && <ProfileTable currentOrg={currentOrg} editAllowed={editAllowed}/>}
        </AccountPageContainer>
    );
}

function ProfileTable (props: {
    currentOrg: OrganisationT,
    editAllowed: boolean
}) {
    const popupStore = usePopupStore()

    return (
        <Table<AccountDatum>
            headers={[
                <LeftAligned>
                    <BodyText>General Information</BodyText>
                </LeftAligned>,

                <RightAligned>
                    <MainButton
                        disabled={!props.editAllowed}
                        disabledMessage='You do not have permissions to Edit Organisation Details'
                        onClick={() => {
                            popupStore.addPopup(OrgProfilePopupId.Edit)
                        }}
                    >
                        Update
                    </MainButton>{" "}
                </RightAligned>,
            ]}
            data={[
                {
                    value: props.currentOrg?.name ?? "",
                    name: "Organisation Name",
                },
                // {
                //     value: "...",
                //     name: "Logo",
                // },
                // {
                //     value: "...",
                //     name: "Small Logo",
                // }
            ]}
            headerBackgroundColor={ruminatiColors.green_3_5}
            builder={(d: AccountDatum) => [
                <LeftAligned>
                    <BodyText>{d.value}</BodyText>
                </LeftAligned>,
                <RightAligned>
                    <BodyText>{d.name}</BodyText>
                </RightAligned>,
            ]}
        />)
}
