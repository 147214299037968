import { useState } from 'react'

import { Column } from "../../components/styled_layout";
import { AccountPageContainer } from './account_screen'
import { BodyText } from "../../components/styled_text";
import { useIntegrationsStore } from "../../state/integrations";
import { disconnectAgriWebbIntegration, openAgriWebbOAuthFlow } from "../../services/agriwebb_service";

import SmallButton from "../../components/buttons/small_button";
import Icon from "../../components/icon";
import { ruminatiColors } from '@/utilities/colors';
import { IntegrationCard } from '@/components/cards/integration_card';
import { useSearchParams } from 'react-router-dom';
import { Button } from 'antd';

export default function AccountIntegrationsTab() {

    return (
        <AccountPageContainer style={{ rowGap: "32px" }}>
          <AgriWebbCard/>
        </AccountPageContainer>
    );
}

function AgriWebbCard () {
  const integrationsStore = useIntegrationsStore();
  const [searchParams, _setSearchParams] = useSearchParams()
  const integrations = integrationsStore.integrations;

  const [localState, setLocalState] = useState({
      agriwebb: integrations?.agriwebb ?? false,
  })

  async function connectToAgriWebb(): Promise<void> {
      await openAgriWebbOAuthFlow()
  }

  async function disconnectAgriWebbAccount(): Promise<void> {
      const newIntegrations = await disconnectAgriWebbIntegration()
      if (newIntegrations) integrationsStore.setIntegrations(newIntegrations)
      if (!newIntegrations?.agriwebb) {
          integrationsStore.clearAgriWebbFarms()
          setLocalState({
              ...localState,
              agriwebb: false
          })
      }
  }

  const hasAgriwebbError = searchParams.get('agriwebb_error') || localState.agriwebb === 'invalid'

  return (
    <IntegrationCard
      title='AgriWebb'
      logo="/images/agriwebb.png"
      action={
          hasAgriwebbError ? <>
            <BodyText style={{ 
              marginRight: '10px', 
              color: ruminatiColors.red,
              fontWeight: 600
            }}>
              Currently inactive
            </BodyText>
            <Column style={{ paddingRight: '10px' }}>
              <Button 
                color="danger"
                variant="solid" 
                onClick={connectToAgriWebb}
              >
                Reconnect
              </Button>
            </Column>
          </> : 
          localState.agriwebb ?
            <>
              <BodyText style={{ marginRight: '10px' }}>
                Connected!
              </BodyText>
              <Column style={{ paddingRight: '10px' }}>
                <SmallButton
                  colorScheme={'green'}
                  onClick={connectToAgriWebb}
                  children={<BodyText style={{ color: ruminatiColors.bone }}>Adjust Integration</BodyText>}
                />
              </Column>
              <Column>
                <SmallButton
                  colorScheme={'orange'}
                  onClick={disconnectAgriWebbAccount}
                  children={<BodyText style={{ color: ruminatiColors.bone }}>Disconnect</BodyText>}
                />
              </Column>
            </>
            :
            <>
              <BodyText style={{ marginRight: '10px', textAlign: 'right' }}>Existing AgriWebb account needed with Organisation Admin permissions</BodyText>
              <SmallButton
              colorScheme="outline"
              onClick={connectToAgriWebb}
              children={<>
                  <Icon icon="chain"/>
                  <BodyText style={{marginLeft: '10px'}}>Connect</BodyText>
              </>}
              />
            </>
      }
      description="Ruminati can import relevant data from an existing AgriWebb account to help fill in some of the information needed, such as your property boundaries and livestock information, to help determine your greenhouse emissions."
    />)
}
