import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { useAuthStore } from "../state/auth_store";
import { SubscriptionType } from "../models/subscription_model";
import { getCurrentOrganisation } from "@/utilities/organisations";

/**
 * Redirects the user if not PRIME tier
 * @param children the react page component
 */
export default function SubscriptionGuard({ children }: PropsWithChildren<unknown>) {
  const user = useAuthStore((state) => state.user);
  const currentOrg = user ? getCurrentOrganisation(user!) : undefined
  if (currentOrg?.subscription.type !== SubscriptionType.PRIME) {
    return <Navigate to="/farms" />;
  }
  return children
}
