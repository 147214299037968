import { Type, Static } from '@sinclair/typebox'

import { ResourceTypeTB } from './resource'

export const RelationshipRecipientTypeUserTB = Type.Literal('User')
export const RelationshipRecipientTypeOrganisationTB = Type.Literal('Organisation')
export const RelationshipRecipientTypeGroupTB = Type.Literal('Group')

export const RelationshipRecipientTypeTB = Type.Union([
  RelationshipRecipientTypeUserTB,
  RelationshipRecipientTypeOrganisationTB,
  RelationshipRecipientTypeGroupTB,
])
export type RelationshipRecipientTypeT = Static<typeof RelationshipRecipientTypeTB>

export const RelationshipBaseTB = Type.Object({
  id: Type.String(), // uuid
  invitedEmail: Type.Optional(Type.String()), // the email of the person recieving an invite
  recipientId: Type.Optional(Type.String()), // id of User, Organisation, or Group - if not present, indicates Relationship is not "active" (i.e. acting as an invitation)
  recipientType: RelationshipRecipientTypeTB,
  roleId: Type.Optional(Type.String()), // if roleId not present, means the Relationship confers no permissions
  isPending: Type.Boolean(),
  revokedPermissions: Type.Boolean(), // TODO: this may become obsoleted if we move to a Roles / Policies table
  resourceId: Type.String(), // uuid
  resourceType: ResourceTypeTB, // TODO: is this still necessary for queries etc?
  createdDate: Type.Date(),
  activatedDate: Type.Optional(Type.Date()),
  deletedAt: Type.Optional(Type.String())
})
export type RelationshipBaseT = Static<typeof RelationshipBaseTB>

export const RelationshipPendingTB = Type.Composite([
  Type.Omit(RelationshipBaseTB, ["activatedDate"]),
  Type.Object({
    isPending: Type.Literal(true)
  })
])
export type RelationshipPendingT = Static<typeof RelationshipPendingTB>

export const RelationshipActiveTB = Type.Composite([
  RelationshipBaseTB,
  Type.Object({
    isPending: Type.Literal(false),
    recipientId: Type.String(),
    activatedDate: Type.Date()
  })
])
export type RelationshipActiveT = Static<typeof RelationshipActiveTB>

export const RelationshipTB = Type.Union([
  RelationshipPendingTB,
  RelationshipActiveTB
])
export type RelationshipT = Static<typeof RelationshipTB>


// creating a pending Relationship always requires an additional email address to send a notification to
export const RelationshipPendingCreateTB = Type.Composite([
  Type.Omit(RelationshipPendingTB, ['id', 'createdDate', 'activatedDate']),
  Type.Object({
    email: Type.String()
  })
])
export type RelationshipPendingCreateT = Static<typeof RelationshipPendingCreateTB>

// creating a pending Relationship always requires an additional email address to send a notification to
export const RelationshipPartnershipPendingCreateTB = Type.Composite([
  Type.Omit(RelationshipPendingTB, ['id', 'createdDate', 'activatedDate']),
])
export type RelationshipPartnershipPendingCreateT = Static<typeof RelationshipPartnershipPendingCreateTB>

// N.B. we don't send the activatedDate on creation
export const RelationshipActiveCreateTB = Type.Omit(RelationshipActiveTB, ['id', 'createdDate', 'activatedDate'])
export type RelationshipActiveCreateT = Static<typeof RelationshipActiveCreateTB>

export const RelationshipCreateTB = Type.Union([
  RelationshipPendingCreateTB,
  RelationshipPartnershipPendingCreateTB,
  RelationshipActiveCreateTB
])
export type RelationshipCreateT = Static<typeof RelationshipCreateTB>

// specific type to narrow down request body for route that allows creating pending relationships via permission on the recipientId - we want that to be restricted to only pending, only recipientType = org Relationships
export const RelationshipOrgRecipientPendingCreateTB = Type.Composite([
  RelationshipPendingCreateTB,
  Type.Object({
    recipientType: RelationshipRecipientTypeOrganisationTB
  })
])
export type RelationshipOrgRecipientPendingCreateT = Static<typeof RelationshipOrgRecipientPendingCreateTB>


// can only update isPending or recipientId of a pending Relationship
export const RelationshipPendingUpdateTB = Type.Partial(Type.Pick(RelationshipTB, ["isPending", "recipientId"]))
export type RelationshipPendingUpdateT = Static<typeof RelationshipPendingUpdateTB>

// can only update role of a pending Relationship
export const RelationshipActiveUpdateTB = Type.Pick(RelationshipTB, ["roleId"])
export type RelationshipActiveUpdateT = Static<typeof RelationshipActiveUpdateTB>

export const RelationshipUpdateTB = Type.Union([
  RelationshipPendingUpdateTB,
  RelationshipActiveUpdateTB
])
export type RelationshipUpdateT = Static<typeof RelationshipUpdateTB>

// TODO: is this still useful?
// N.B. see getUserOrganisationIds in permissions package
export const RelationshipUserOrgsTB = Type.Pick(RelationshipTB, ['resourceId', 'roleId'])
export type RelationshipUserOrgsT = Static<typeof RelationshipUserOrgsTB>


export const RelationshipGraphItemTB = Type.Composite([
  Type.Pick(RelationshipBaseTB, ['id', 'recipientId', 'roleId', 'revokedPermissions', 'createdDate']),
  Type.Object({
    path: Type.String()
  })
])
export type RelationshipGraphItemT = Static<typeof RelationshipGraphItemTB>

export const ExplodedPathTB = Type.Array(Type.Object({
  id: Type.String(), // uuid
  type: ResourceTypeTB
}))
export type ExplodedPathT = Static<typeof ExplodedPathTB>

export const RelationshipGraphItemExplodedPathTB = Type.Composite([
  Type.Omit(RelationshipGraphItemTB, ['path']),
  Type.Object({
    path: ExplodedPathTB
  })
])
export type RelationshipGraphItemExplodedPathT = Static<typeof RelationshipGraphItemExplodedPathTB>
