// auth
export const TOKEN_KEY = "ruminatiToken";
export const REFRESH_TOKEN_KEY = "ruminatiRefreshToken";
export const USER_KEY = "ruminatiUser";
export const VERIFIED_KEY = "ruminatiVerified";
export const CURRENT_USER_ID_COOKIE_KEY = "userId"; // TODO: prefix this as per current org cookie?
export const CURRENT_ORGANISATION_ID_COOKIE_KEY = "ruminatiCurrentOrganisation";

// GCP
export const GOOGLE_REFRESH_TOKEN_URL = `https://securetoken.googleapis.com/v1/token`
export const GOOGLE_IDENTITY_ACCOUNTS_URL = 'https://identitytoolkit.googleapis.com/v1/accounts'

export const GOOGLE_IDENTITY_SIGNUP_PARAM = 'signUp'
export const GOOGLE_IDENTITY_SIGNUP_URL = `${GOOGLE_IDENTITY_ACCOUNTS_URL}:${GOOGLE_IDENTITY_SIGNUP_PARAM}`

export const GOOGLE_IDENTITY_SIGNIN_PARAM = 'signInWithPassword'
export const GOOGLE_IDENTITY_SIGNIN_URL = `${GOOGLE_IDENTITY_ACCOUNTS_URL}:${GOOGLE_IDENTITY_SIGNIN_PARAM}`

export const GOOGLE_IDENTITY_SEND_EMAIL_VERIFICATION_PARAM = 'sendOobCode'
export const GOOGLE_IDENTITY_SEND_EMAIL_VERIFICATION_URL = `${GOOGLE_IDENTITY_ACCOUNTS_URL}:${GOOGLE_IDENTITY_SEND_EMAIL_VERIFICATION_PARAM}`

export const GOOGLE_IDENTITY_USER_LOOKUP_PARAM = 'lookup'
export const GOOGLE_IDENTITY_USER_LOOKUP_URL = `${GOOGLE_IDENTITY_ACCOUNTS_URL}:${GOOGLE_IDENTITY_USER_LOOKUP_PARAM}`

export const GOOGLE_IDENTITY_SIGN_IN_WITH_IDP_PARAM = 'signInWithIdp'
export const GOOGLE_IDENTITY_SIGN_IN_WITH_IDP_URL = `${GOOGLE_IDENTITY_ACCOUNTS_URL}:${GOOGLE_IDENTITY_SIGN_IN_WITH_IDP_PARAM}`

// TO DO - Delete, seems like a duplicate of above?
// export const GOOGLE_IDENTITY_LOOKUP_PARAM = 'lookup'
// export const GOOGLE_IDENTITY_LOOKUP_URL = `${GOOGLE_IDENTITY_ACCOUNTS_URL}:${GOOGLE_IDENTITY_LOOKUP_PARAM}`

// permit
// TODO: replace with type literals .const, see @ruminati/types/permission.ts / @ruminati/types/resource.ts
export const PERMIT_RESOURCE_ORGANISATION = 'Organisation'
export const PERMIT_RESOURCE_PROPERTY = 'Property'
export const PERMIT_ROLE_OWNER = 'owner'
