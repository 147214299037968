import { Switch } from 'antd'


export type SwitchAntdProps = {
  disabled?: boolean
  checked: boolean
  onChange: (checked: boolean) => void
}

export default function SwitchAntd(props: SwitchAntdProps) {
  return <Switch
      checked={props.checked}
      disabled={props.disabled ?? false}
      onChange={props.onChange} />
}
