import { useState } from "react"
import { Tooltip } from "antd"
import { SyncOutlined } from '@ant-design/icons'

import { LinkedFarmStatusTypeAcceptedTB, LinkedFarmStatusTypeSharedTB, LinkedFarmStatusTypeT, LinkedFarmStatusTypeUnlinkedByOrganisationTB, LinkedFarmStatusTypeUnlinkedByOwnerTB, VisionLinkedFarmFarmClientT } from "@ruminati/types/vision_linked_farm";
import { VisionInvitationFarmClientT } from "@ruminati/types/vision_invitation";

import { Row, Column } from "./styled_layout"
import { BodyText } from "./styled_text"
import { ruminatiColors } from "@/utilities/colors"
import Icon, { IconType } from "./icon"
import { usePartnerStore } from "@/state/partners"
import { cancelFarmLinkage } from "@/services/vision_service"
import { formatDateTimestampAsPrettyString } from "@/utilities/dates"
import { usePopupStore } from "@/state/popup_store"
import { CorporatePartnerPopupId } from "@/screens/corporate_partners/corporate_partners"
import { useOrganisationMembers } from "@/hooks/organisations"
import { UserMemberOfOrganisationT } from "@ruminati/types/user"
import { useAuthStore } from "@/state/auth_store"
import { getCurrentOrganisation } from "@/utilities/organisations"
import { useDatabaseStore } from "@/state/database_store";
import { ROLE_ORG_ADMIN } from "@ruminati/permissions/roles";

export default function LinkedEnterpriseRow ({
  invite,
  linkedEnterprise,
  shareAllIsDisabled,
  shareAllRequiredUntil
}: {
  invite: VisionInvitationFarmClientT
  linkedEnterprise: VisionLinkedFarmFarmClientT
  shareAllIsDisabled: boolean | undefined
  shareAllRequiredUntil?: string
}) {
  const databaseStore = useDatabaseStore()
  const partnerStore = usePartnerStore()
  const popupStore = usePopupStore()
  const authStore = useAuthStore()

  const currentOrg = getCurrentOrganisation(authStore.user!);
  if (!authStore.user || !currentOrg) return

  const currentOrgUserRelationship = authStore.user.relationships.find(r => r.resourceId === currentOrg.id && r.recipientId === authStore.user?.uid)
  const hasOrgAdminRole = currentOrgUserRelationship?.roleId === ROLE_ORG_ADMIN.id

  const property = databaseStore.properties?.find(p => p.id === linkedEnterprise.linkedFarm.propertyId)

  const {
    isPending,
    data: allOrgMembers
  } = useOrganisationMembers()

  const [deletingLink, setDeletingLink] = useState<boolean>(false)
  if (isPending) return undefined
  function getPendingOrConnectedStyled(status: LinkedFarmStatusTypeT, linkage: VisionLinkedFarmFarmClientT): JSX.Element {
    function makeContent(label: string, color?: string, iconStyle?: IconType): JSX.Element {
      return (
        <BodyText style={{ color: color ?? ruminatiColors.green_3 }}>
          {iconStyle ? <span style={{ paddingRight: '6px' }}><Icon icon={iconStyle} /></span> : undefined}
          {label}
        </BodyText>
      )
    }

    if (status === LinkedFarmStatusTypeSharedTB.const) {
      return makeContent('Pending Partner Acceptance', ruminatiColors.orange);
    } else if (status === LinkedFarmStatusTypeAcceptedTB.const) {
      return makeContent('Connected', undefined, 'tick-short')
    } else if (status === LinkedFarmStatusTypeUnlinkedByOwnerTB.const) {
      return makeContent(!linkage.linkedFarm.partnershipRequestAccepted ? 'Cancelled by you' : 'Revoked by you')
    } else if (status === LinkedFarmStatusTypeUnlinkedByOrganisationTB.const) {
      return makeContent(!linkage.linkedFarm.partnershipRequestAccepted ? 'Declined' : 'Cancelled by Organisation');
    }
    return <></>;
  }

  async function cancelConnection(linkageId: string) {
    popupStore.addPopup(CorporatePartnerPopupId.RemoveLinkedEnterprise, undefined, {
      enterpriseName: property?.name,
      visionOrgName: linkedEnterprise.visionOrganisation.name,
      onConfirm: async () => {
        setDeletingLink(true)
        const modifiedLinkage = await cancelFarmLinkage(linkageId);
        setDeletingLink(false)
        partnerStore.removeLinkedFarmById(linkageId)
        if (modifiedLinkage) partnerStore.addLinkedFarm(modifiedLinkage)
      }
    })
  }

  const linkStatus = linkedEnterprise.linkedFarm.status

  function findOrgMemberByEmail(userId: string): UserMemberOfOrganisationT | undefined {
    return allOrgMembers?.find(m => m.recipientId === userId)
  }

  const linkedEnterpriseCreator = findOrgMemberByEmail(linkedEnterprise.linkedFarm.sharedByUserId)


  return (
    <Row
      key={linkedEnterprise.linkedFarm.id}
      style={{
        justifyContent: 'flex-start',
        width: '100%',
        marginBottom: '10px'
      }}>
      <Column style={{ width: '30%', alignItems: 'flex-start' }}>
        <BodyText>{property?.name}</BodyText>
      </Column>
      <Column style={{ width: '35%', alignItems: 'flex-start' }}>
        <BodyText
          style={{ paddingLeft: '20px', color: ruminatiColors.green_3_50 }}
        >
          Shared {formatDateTimestampAsPrettyString(linkedEnterprise.linkedFarm.partnershipRequestSent)} by {linkedEnterpriseCreator?.recipientDetails.firstName} {linkedEnterpriseCreator?.recipientDetails.lastName}
        </BodyText>
      </Column>
      <Column style={{ width: '25%', alignItems: 'center' }}>
        {getPendingOrConnectedStyled(linkStatus, linkedEnterprise)}
      </Column>
      <Column style={{ width: '10%', alignItems: 'flex-end' }}>
        {(linkStatus === LinkedFarmStatusTypeSharedTB.const || linkStatus === LinkedFarmStatusTypeAcceptedTB.const) ? (
          <Tooltip
          color={ruminatiColors.effective_black}
          title={shareAllIsDisabled && shareAllRequiredUntil ? `You must have sharing with ${invite.visionOrganisation.name} enabled until ${formatDateTimestampAsPrettyString(shareAllRequiredUntil)}` : ""}
          >
            <Row>
              {deletingLink ? <SyncOutlined
                spin={true}
                style={{
                  color: ruminatiColors.dark_green,
                  marginRight: '8px'
                }}
              /> : undefined
              }
              <BodyText
                style={{
                  borderBottom: `1px solid ${ruminatiColors.dark_green}`,
                  cursor: (shareAllIsDisabled || !hasOrgAdminRole) ? 'not-allowed' : 'pointer',
                  textAlign: 'right',
                  opacity: (shareAllIsDisabled || !hasOrgAdminRole) ? 0.4 : 1
                }}
                onClick={() => {
                  if (!shareAllIsDisabled || !hasOrgAdminRole) {
                    cancelConnection(linkedEnterprise.linkedFarm.id)
                  }
                }}
              >
                {linkStatus === LinkedFarmStatusTypeSharedTB.const ? 'Cancel' : 'Disconnect'}
              </BodyText>
            </Row>
        </Tooltip>
        ) : undefined}
      </Column>
    </Row>)
}
