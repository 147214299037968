import { FieldSize, FormField, FormFieldRenderOptions } from "./form_field";

export default class HiddenFormField implements FormField<string> {

    size = FieldSize.Full;
    displayValue = () => undefined;
    required = (_: any) => true;

    validate(): boolean {
        return true;
    }

    render(_options: FormFieldRenderOptions<string>): JSX.Element {
        return <></>;
    }
}
