import { CheckSquareOutlined } from "@ant-design/icons";
import { createGlobalStyle } from "styled-components";
import { useState } from "react";

import { NotificationAppT } from "@ruminati/types/notification";
import { ruminatiColors } from "@/utilities/colors";
import { formatDateTimestampAsPrettyString2 } from "@/utilities/dates";
import { Column, Row } from "../styled_layout";
import GeneralInformation from "./general-info";
import ShareInvite from "./share-invite";
import { BodyText } from "../styled_text";
import { LooserBodyText, TextLink } from "../styled_text"

import { useNotificationPatch } from "@/hooks/notifications";
import RelationshipAddedOrgToUser from "./relationship-created-org-to-user";
import RelationshipUpdated from "./relationship-updated";
import RelationshipDeletedFromUserToOrg from "./relationship-deleted-from-user-to-org";
import RelationshipUserInvitedToOrg from "./relationship-user-invited-to-org";
import RelationshipCreatedOrgToOrg from "./relationship-created-org-to-org";

type NotificationProps = {
  notification: NotificationAppT,
  disableMarkReadMechanism?: boolean
}

export default function NotificationItem (props: NotificationProps) {
  const [isMouseOver, setIsMouseOver] = useState(false)

  const notificationContent = getNotificationById(props.notification)
  const notificationPatch = useNotificationPatch(props.notification.id)
  if (!notificationContent) return undefined

  return <Row
    onMouseOver={() => setIsMouseOver(true)}
    onMouseOut={() => setIsMouseOver(false)}
    style={{
      width: '100%',
      justifyContent: 'flex-start',
      borderTop: `1px solid ${ruminatiColors.green_3_15}`,
      padding: '20px 20px',
      background: props.notification.read ? undefined : ruminatiColors.green_3_5,
      position: 'relative'
    }}>
    <Column style={{width: '100%' , justifyContent: 'flex-start'}}>
      <Row style={{
        width: '100%', 
        marginBottom: '12px',
        alignItems: 'flex-start'
      }}>
        <Column style={{flexGrow: 1, alignItems: 'flex-start'}}>
          <BodyText style={{fontWeight: 700}}>
            {notificationContent.title}
          </BodyText>
        </Column>
        <Column style={{width: '120px', alignItems: 'flex-end'}}>
          <BodyText style={{color: ruminatiColors.green_3_50}}>
            {formatDateTimestampAsPrettyString2(props.notification.createdDate)}
          </BodyText>
        </Column>
        {(!props.notification.read && !props.disableMarkReadMechanism) &&
          <>
            <NotificationCheckStyle/>
            <BodyText
             className="markReadBox"
             style={{
              position: 'absolute',
              right: '12px',
              zIndex: 100,
              background: ruminatiColors.bone,
              border: `1px solid ${ruminatiColors.green_3_10}`,
              borderRadius: '4px',
              padding: '3px 8px',
              opacity: isMouseOver ? 1 : 0,
              cursor: 'pointer'
            }}
            onClick={() => {
                if (!props.notification.read && notificationPatch.isIdle) {
                  notificationPatch.mutateAsync({read: true})
                }
              }}
            >
              Mark as Read
              <CheckSquareOutlined
              style={{
                marginLeft: '6px',
              }}
              />
            </BodyText>
          </>
          }
      </Row>

      <Row style={{width: '100%'}}>
        <Column style={{width: '100%'}}>
          {notificationContent.content}
        </Column>
      </Row>
    </Column>
  </Row>
}

export function getNotificationById (notification: NotificationAppT): {
  title: string;
  content: JSX.Element
 } | undefined {
  if (notification.template === 'general-info') {
    if (notification.templateParameters && 'key' in notification.templateParameters) {
      const info = GeneralInfoMap.get(notification.templateParameters.key)
      if (info) {
        return {
          title: info.title,
          content: <GeneralInformation>
            {info.content}
          </GeneralInformation>
        }
      }
    }
    return undefined

  } else if (notification.template === 'share-invite') {
    return {
      title: "Data Sharing Request",
      content: <ShareInvite inviteId={notification.templateParameters.inviteId}/>
    }
  } else if (notification.template === 'relationship-org-to-org-created') {
    return {
      title: `You've been invited to assist ${notification.templateParameters.orgName}`,
      content: <RelationshipCreatedOrgToOrg 
        relationshipId={notification.templateParameters.relationshipId}
        orgName={notification.templateParameters.orgName}
      />
    }
  } else if (notification.template === 'relationship-user-to-org-invited') {
    return {
      title: `You've been invited to ${notification.templateParameters.orgName}`,
      content: <RelationshipUserInvitedToOrg 
        relationshipId={notification.templateParameters.relationshipId}
        orgName={notification.templateParameters.orgName}
      />
    }
  } else if (notification.template === 'relationship-user-to-org-created') {
    return {
      title: `You've been added to ${notification.templateParameters.orgName}`,
      content: <RelationshipAddedOrgToUser 
        orgName={notification.templateParameters.orgName}
      />
    }
  } else if (notification.template === 'relationship-updated') {
    return {
      title: "Permissions Updated",
      content: <RelationshipUpdated 
        relationshipId={notification.templateParameters.relationshipId}
        newRoleId={notification.templateParameters.newRoleId}
      />
    }
  } else if (notification.template === 'relationship-to-org-deleted') {
    return {
      title: `You've been removed from ${notification.templateParameters.orgName}`,
      content: <RelationshipDeletedFromUserToOrg 
        orgName={notification.templateParameters.orgName}
      />
    }
  }
  return undefined
}

type GeneralInfoRecord = {
  title: string
  content: JSX.Element
}

const GeneralInfoMap = new Map<string, GeneralInfoRecord>([
  ['tou-update-dec2024', {
    title: 'Terms of Use Update',
    content: <>
      <LooserBodyText>Our terms of use are changing on the 15th of December 2024.</LooserBodyText>
      <LooserBodyText>If you're unsure about, or disagree, with these changes please get in touch with us at <TextLink>hello@ruminati.com.au</TextLink>.</LooserBodyText>
    </>
  }]
])

export const NotificationCheckStyle = createGlobalStyle`
  .markReadBox {
      color: ${ruminatiColors.green_3_50};
      transition: 0.2s;

      :hover {
        color: ${ruminatiColors.green_3};
      }
  }
`
