import { Type, Static } from '@sinclair/typebox'

export const SubscriptionBaseTB = Type.Literal('base')
export const SubscriptionPrimeTB = Type.Literal('prime')

export const SubscriptionTypeTB = Type.Union([
  SubscriptionBaseTB,
  SubscriptionPrimeTB
])
export type SubscriptionTypeT = Static<typeof SubscriptionTypeTB>

// Note there are a bunch of optional fields
// because of Orgs that don't have an 
// actual subscription
export const SubscriptionTB = Type.Object({
  id: Type.Optional(Type.String()),
  type: SubscriptionTypeTB,
  autoRenew: Type.Optional(Type.Boolean()),
  startDate: Type.Optional(Type.Number()),
  endDate: Type.Optional(Type.Number())
})

export type SubscriptionT = Static<typeof SubscriptionTB>

