import dayjs from "dayjs";

export function formatDateTimestampAsPrettyString(
    dateTimestamp: string
): string {
    return dayjs(dateTimestamp).format("D/MM/YYYY");
}

// returns 12 Sept 2024
export function formatDateTimestampAsPrettyString2(
    dateTimestamp: string | Date
): string {
    return dayjs(dateTimestamp).format("D MMM YYYY");
}

export const monthsForSelecting = [
    {
        name: "July",
        value: "july",
    },
    {
        name: "August",
        value: "august",
    },
    {
        name: "September",
        value: "september",
    },
    {
        name: "October",
        value: "october",
    },
    {
        name: "November",
        value: "november",
    },
    {
        name: "December",
        value: "december",
    },
    {
        name: "January",
        value: "january",
    },
    {
        name: "February",
        value: "february",
    },
    {
        name: "March",
        value: "march",
    },
    {
        name: "April",
        value: "april",
    },
    {
        name: "May",
        value: "may",
    },
    {
        name: "June",
        value: "june",
    },
];

export function sortArrayOfMonthValuesByFinancialYear(months: string[]) {
    const names = monthsForSelecting.map((m) => m.value);
    months.sort((a, b) => {
        if (names.indexOf(a) < names.indexOf(b)) return -1;
        return 1;
    });
}

// Converts "2022" to "FY2022 (July 21 - June 22)"
export function formatFinancialYearStringToPrettyFinancialYearString(
    year: string
): string {
    const currentYear = parseInt(year);
    const prevYear = currentYear - 1;

    return `FY${year} (July ${prevYear.toString().slice(2)} - June ${currentYear
        .toString()
        .slice(2)})`;
}

// Converts "2022" to "2021-22"
export function formatFinancialYearStringToSimpleFinancialYearString(
    year: string | number
): string {
    const currentYear: number = Number.isInteger(year)
        ? (year as number)
        : parseInt(year as string);
    const prevYear = currentYear - 1;

    return `${prevYear}-${currentYear.toString().slice(2)}`;
}

// Converts "2022" to "2021-2022"
export function formatYearStringToFinancialYearString(
    year: string | number
): string {
    const currentYear: number = Number.isInteger(year)
        ? (year as number)
        : parseInt(year as string);
    const prevYear = currentYear - 1;

    return `${prevYear}-${currentYear}`;
}

// Converts "2022" to "FY 2022"
export function formatFinancialYearStringToShortPrettyFinancialYearString(
    year: string
): string {
    return `FY ${year}`;
}

export function getCurrentFinancialYear(): number {
    const date = new Date();
    if (date.getMonth() >= 6) {
        return date.getFullYear() + 1;
    } else {
        return date.getFullYear();
    }
}

export function getNextFinancialYears(count: number): number[] {
    const current = getCurrentFinancialYear()
    return Array.from({ length: count }, (_, i) => current + i)
}

export function getNextNYears(year: number, n: number): number[] {
    const nextYears: number[] = [];
    for (let i = 1; i <= n; i++) {
        nextYears.push(year + i);
    }
    return nextYears;
}

export function getYearsBetween(year1: number, year2: number): number[] {
    if (year1 >= year2) return [];
    const years: number[] = [];
    for (let i = 1; i <= year2 - year1; i++) {
        years.push(year1 + i);
    }
    return years;
}
