import { InfoCard } from "../card";
import { Row, Column } from "../styled_layout";
import { Form, FormData } from "../../models/form/form";
import { DropdownFormField } from "@/models/form/form_fields/basic_form_fields";

import { useOrganisationClients, useOrganisationMembers } from "@/hooks/organisations";
import { BodyText } from "../styled_text";
import { ruminatiColors } from "@/utilities/colors";
import { AccessManagementPopupId } from "@/screens/organisation/member_tab_farm_org"
import { usePopupStore, PopupState } from "@/state/popup_store";
import { PermissionRoleT } from "@ruminati/types/permission";
import { RelationshipRecipientTypeUserTB } from "@ruminati/types/relationship";
import { ResourceTypeOrganisationTB } from "@ruminati/types/resource";
import { getCurrentOrganisation, getUserOrgMembers } from "@/utilities/organisations";
import { useAuthStore } from "@/state/auth_store";

import { ClientOfOrganisationT, UserMemberOfOrganisationT } from "@ruminati/types/user";
import { useCreateRelationship } from "@/hooks/relationships";
import { RoleT } from "@ruminati/types/role";
import { EXTERNAL_FARM_ORG_ROLES } from "@ruminati/permissions/roles";

export interface AddPartnerMemberDTO {
  recipientId: string;
  role: PermissionRoleT
}

const memberForm = (popupStore: PopupState, roles: RoleT[], availableOrgMembers: UserMemberOfOrganisationT[]): FormData<AddPartnerMemberDTO> => ({
  fields: {
    recipientId: new DropdownFormField({
      placeholder: "Member",
      options: availableOrgMembers.map(om => {
        return {
          value: om.recipientId as string,
          name: `${om.recipientDetails.firstName} ${om.recipientDetails.lastName}`
        }
      }),
      required: true,
    }),
    role: new DropdownFormField({
      placeholder: "Default Role",
      children: () => {
        return <BodyText
          onClick={(() => {
            popupStore.addPopup(AccessManagementPopupId.RoleDescriptionPopup)
          })}
          style={{
            cursor: 'pointer',
            borderBottom: `1px solid ${ruminatiColors.dark_green}`
          }}>Find out more about roles</BodyText>
      },
      options: roles.map(r => {
        return {
          value: r.id,
          name: r.name
        }
      }),
      required: true,
    }),
  }
})

export const ASSIGN_PARTNER_MEMBER_TO_CLIENT_POPUP_ID = 'ASSIGN_PARTNER_MEMBER_TO_CLIENT'

export default function AddPartnerMemberToClientPopup(props: {
  onSubmit: () => void
  clientOrg: ClientOfOrganisationT
}) {
  const popupStore = usePopupStore()
  const authStore = useAuthStore()

  const currentOrg = getCurrentOrganisation(authStore.user!);

  const {
    isPending,
    data: allOrgMembers
  } = useOrganisationMembers()

  const {
    isPending: orgClientsPending,
    data: allClients
  } = useOrganisationClients()

  if (isPending || orgClientsPending) return
  
  const userMembersInCurrentOrg = getUserOrgMembers(allOrgMembers ?? [])

  const clientMembersInClientOrg = allClients?.filter(m => m.recipientDetails.recipientType === RelationshipRecipientTypeUserTB.const && m.resourceDetails.resourceId === props.clientOrg.resourceDetails.resourceId)
  const orgMembersNotYetAllocated = userMembersInCurrentOrg?.filter(m => {
    return !clientMembersInClientOrg?.find(c => c.recipientDetails.recipientId === m.recipientId)
  })

  if (orgMembersNotYetAllocated?.length === 0) {
    return <InfoCard
      content={`All members of ${currentOrg?.name} are already allocated to ${props.clientOrg.resourceDetails.orgName}.`}
    />
  }

  const availableRoles = EXTERNAL_FARM_ORG_ROLES

  const {
    mutateAsync: createRelationshipMutateAsync,
    isPending: createOrgInvitationIsPending
  } = useCreateRelationship()

  return (<Column style={{
    padding: '20px 40px',
    width: '760px'
  }}>
    <InfoCard
      content={`Assign a member from ${currentOrg?.name} to ${props.clientOrg.resourceDetails.orgName}.`}
    />
    <Row style={{ height: '20px' }}></Row>
    <Row>
      <Form
        data={memberForm(popupStore, availableRoles, orgMembersNotYetAllocated ?? [])}
        width={"400px"}
        initialValue={{}}
        fieldSize="small"
        submitText={`Assign Member`}
        buttonSize="medium"
        disableSubmitButton={createOrgInvitationIsPending}
        onSubmit={async (value) => {
          if (value) {
            const existingPartnerOrgMember = allOrgMembers?.find(om => om.recipientId)
            if (!existingPartnerOrgMember) return

            const newMember = await createRelationshipMutateAsync({
              recipientType: RelationshipRecipientTypeUserTB.const,
              recipientId: value.recipientId,
              isPending: false,
              roleId: value.role,
              resourceId: props.clientOrg.resourceDetails.resourceId,
              resourceType: ResourceTypeOrganisationTB.const,
              revokedPermissions: false
            })
            if (newMember) props.onSubmit()
          }
        }}
      />
    </Row>
  </Column>
  );
}
