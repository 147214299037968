import { Switch, Tooltip } from "antd"
import { useState } from "react"
import dayjs from "dayjs"

import { LinkedFarmStatusTypeAcceptedTB, LinkedFarmStatusTypeSharedTB, VisionLinkedFarmFarmClientT } from "@ruminati/types/vision_linked_farm";
import { VisionInvitationFarmClientT } from "@ruminati/types/vision_invitation";

import { LinkShareAllDTO } from "@/models/vision"
import { Card } from "../card"
import { Row, Column } from "../styled_layout"
import { BodyText } from "../styled_text"
import { ruminatiColors } from "@/utilities/colors"
import { formatDateTimestampAsPrettyString } from "@/utilities/dates"
import SmallButton from "../buttons/small_button"
import { usePopupStore } from "@/state/popup_store"
import { usePartnerStore } from "@/state/partners"
import { deleteShareAll, getPendingOrAcceptedFarmLinkages } from "../../services/vision_service";
import { CorporatePartnerPopupId } from "@/screens/corporate_partners/corporate_partners"
import LinkedEnterpriseRow from "../linked_enterprise_row"
import { composeIconUrl } from "@/utilities/partners"
import { getCurrentOrganisation } from "@/utilities/organisations"
import { useAuthStore } from "@/state/auth_store"
import { ROLE_ORG_ADMIN } from "@ruminati/permissions/roles";

export const apiUrl = import.meta.env.REACT_APP_API_BASE_URL;

export type CorporateInviteProps = {
  invites: VisionInvitationFarmClientT[],
  propertyCount: number
  shareAll?: LinkShareAllDTO,
  showInviteDate?: boolean,
  action?: JSX.Element,
  linkedEnterprises?: VisionLinkedFarmFarmClientT[]
}

export default function CorporateInviteCard(props: CorporateInviteProps) {
  const popupStore = usePopupStore()
  const partnerStore = usePartnerStore()
  const authStore = useAuthStore()

  const [shareAllEnabled, setShareEnabled] = useState<boolean>(props.shareAll !== undefined)

  const currentOrg = getCurrentOrganisation(authStore.user!);
  if (!authStore.user || !currentOrg) return

  const currentOrgUserRelationship = authStore.user.relationships.find(r => r.resourceId === currentOrg.id && r.recipientId === authStore.user?.uid)
  const hasOrgAdminRole = currentOrgUserRelationship?.roleId === ROLE_ORG_ADMIN.id


  function goToLinkAddPage(shareAll: boolean, inviteId: string) {
    popupStore.addPopup(CorporatePartnerPopupId.ConnectPartner, undefined, {
      shareAll,
      inviteId,
      visionOrgName: props.invites[0].visionOrganisation.name,
      onSubmit: async () => {
        if (shareAll) {
          setShareEnabled(true)
          const updatedLinkedFarms = await getPendingOrAcceptedFarmLinkages()
          if (updatedLinkedFarms) partnerStore.setLinkedFarms(updatedLinkedFarms)
        }
      }
    })
  }

  async function cancelShareAll() {
    if (props.shareAll) {
      setShareEnabled(false)
      await deleteShareAll(props.shareAll.id)
      partnerStore.removeShareAllById(props.shareAll.id)
    }
  }

  function shareSelectedButton() {
    return <SmallButton
      colorScheme="outline"
      height="30px"
      onClick={() => goToLinkAddPage(false, props.invites[0].invite.id)}>
      Share additional enterprises
    </SmallButton>
  }

  const canDisableShares = props.shareAll && dayjs().isBefore(dayjs(props.shareAll.requiredUntil))
  const activeShareCount = props.linkedEnterprises ? props.linkedEnterprises.filter(le => le.linkedFarm.status === LinkedFarmStatusTypeAcceptedTB.const || le.linkedFarm.status === LinkedFarmStatusTypeSharedTB.const).length : 0
  const firstInvite = props.invites.at(0)
  if (!firstInvite) return undefined

  return <Card
    id={firstInvite.invite.id}
    key={firstInvite.invite.id}
    style={{
      marginBottom: '24px'
    }}>
    <Row style={{
      paddingBottom: '18px',
      borderBottom: `1px solid ${ruminatiColors.green_3_30}`,
      marginBottom: '18px'
    }}>
      <Column style={{ width: '50px', alignItems: 'flex-start' }}>
        {firstInvite.visionOrganisation.icon_small_url ?
          <img
            src={composeIconUrl(firstInvite.visionOrganisation.icon_small_url)}
            alt={firstInvite.visionOrganisation.name + " logo"}
            height="36px"
            style={{ marginRight: '20px' }}
           />
          : undefined}
      </Column>
      <Column style={{ flexGrow: '1', alignItems: 'flex-start' }}>
        <BodyText style={{ fontWeight: 500, fontSize: '15px' }}>{firstInvite.visionOrganisation.name}</BodyText>
      </Column>
      <Column style={{ flexGrow: '1', alignItems: 'flex-start' }}>
          {props.showInviteDate && <>
            <Row style={{width: '100%', justifyContent: 'flex-start'}}>
              <BodyText
                style={{ color: ruminatiColors.green_3_50 }}
              >
                  Data Sharing Request{props.invites.length > 1 && 's'} recieved by
              </BodyText>
            </Row>
            {props.invites.map((i, index) => {
            return <Row key={index} >
              <BodyText
                style={{ color: ruminatiColors.green_3_50 }}
              >
                {i.recipientDetails.email} on {formatDateTimestampAsPrettyString(i.invite.created)}

              </BodyText>
            </Row>
            })}
            </>
          }
      </Column>
      <Column style={{ width: '40%', alignItems: 'flex-end' }}>
          <Row>
            <BodyText style={{ marginRight: '18px' }}>
              {
                props.shareAll !== undefined ? 'Sharing all current and future enterprises' : 'Share all current and future enterprises'
              }
            </BodyText>
            <Tooltip
              title={canDisableShares && props.shareAll && props.shareAll.requiredUntil ? `You must have sharing with ${firstInvite.visionOrganisation.name} enabled until ${formatDateTimestampAsPrettyString(props.shareAll.requiredUntil)}` : ""}
              color={ruminatiColors.effective_black}
            >
              <Switch
                checked={shareAllEnabled}
                disabled={canDisableShares || !hasOrgAdminRole}
                onChange={async (checked) => {
                  if (checked) {
                    goToLinkAddPage(true, firstInvite.invite.id)
                  } else {
                    popupStore.addPopup(CorporatePartnerPopupId.RemoveShareAll, undefined, {
                      visionOrganisationName: firstInvite.visionOrganisation.name,
                      onConfirm: () => {
                        cancelShareAll()
                      }
                    })
                  }
                }} />
            </Tooltip>
          </Row>
      </Column>
    </Row>
    {
      props.linkedEnterprises && props.linkedEnterprises.length > 0 ? <>
        {props.linkedEnterprises.map(linkedEnterprise => {
          return <LinkedEnterpriseRow
            key={linkedEnterprise.linkedFarm.id}
            invite={firstInvite}
            linkedEnterprise={linkedEnterprise}
            shareAllIsDisabled={canDisableShares}
            shareAllRequiredUntil={props.shareAll?.requiredUntil ?? undefined}
            />
        })}
        <Row style={{
          width: '100%',
          justifyContent: 'center',
          marginTop: '20px'
        }}>
          {activeShareCount < props.propertyCount ?
            shareSelectedButton()
            : <BodyText style={{ textAlign: 'center' }}>You've shared data from all your enterprises with {firstInvite.visionOrganisation.name}</BodyText>
          }</Row>
      </> : <>
        <BodyText style={{ textAlign: 'center' }}>No Enterprise Data shared with {firstInvite.visionOrganisation.name}</BodyText>
        <Row style={{
          width: '100%',
          justifyContent: 'center',
          marginTop: '20px'
        }}>
          <Row style={{
            width: '60%',
            columnGap: '20px'
          }}>
            {props.propertyCount > 0 ? shareSelectedButton() : undefined}
            {!props.shareAll ?
              <SmallButton
                colorScheme="outline"
                onClick={() => goToLinkAddPage(true, firstInvite.invite.id)}
                height="30px"
                disabled={!hasOrgAdminRole}
              >
                Share all current & future enterprises
              </SmallButton> : undefined
            }
          </Row>
        </Row>
      </>
    }
  </Card>
}
