import { PropsWithChildren } from "react";
import { ruminatiColors } from "../../utilities/colors";
import Button from "./button";

export default function SmallButton({
    children,
    onClick,
    colorScheme,
    width,
    height,
    paddingWidth,
    borderRadius,
    hoverBackgroundColor,
    hoverTextColor,
    disabled,
    disabledMessage
}: PropsWithChildren<{
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    colorScheme?: "green" | "orange" | "outline";
    width?: string;
    height?: string;
    paddingWidth?: string;
    borderRadius?: string;
    hoverBackgroundColor?: string;
    hoverTextColor?: string;
    disabled?: boolean;
    disabledMessage?: string;
}>) {
    const colorMap = {
        green: ruminatiColors.green_3,
        orange: ruminatiColors.orange,
        outline: ruminatiColors.bone,
    };

    const borderColorMap = {
        green: ruminatiColors.green_3,
        orange: ruminatiColors.orange,
        outline: ruminatiColors.green_3_30,
    };

    const textColorMap = {
        green: ruminatiColors.bone,
        orange: ruminatiColors.bone,
        outline: ruminatiColors.green_3,
    };

    return (
        <Button
            padding={`2px ${paddingWidth ?? '16px'}`}
            height={height ?? "40px"}
            width={width ?? "auto"}
            onClick={onClick}
            backgroundColor={colorMap[colorScheme ?? "outline"]}
            borderRadius={borderRadius ?? "48px"}
            border={
                colorScheme === "outline"
                    ? `1px solid ${borderColorMap[colorScheme ?? "outline"]}`
                    : "none"
            }
            textColor={textColorMap[colorScheme ?? "outline"]}
            hoverBackgroundColor={hoverBackgroundColor}
            hoverTextColor={hoverTextColor}
            disabled={disabled}
            disabledMessage={disabledMessage}
        >
            {children}
        </Button>
    );
}
