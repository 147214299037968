import { Type, Static } from '@sinclair/typebox'

import { CountryCodeTB } from './country'
import { ReportTB } from './report'
import { PermissionRoleTB } from './permission'
import { MultiPolygonTB, PointTB } from './geojson'

export const FeedlotProductionSystemTypeDrylotTB = Type.Literal('drylot')
export const FeedlotProductionSystemTypeSolidStorageTB = Type.Literal('solid-storage')
export const FeedlotProductionSystemTypeCompostingTB = Type.Literal('composting')
export const FeedlotProductionSystemTypeAnaerobicLagoonTB = Type.Literal('anaerobic-lagoon')

export const FeedlotProductionSystemTypeTB = Type.Union([
  FeedlotProductionSystemTypeDrylotTB,
  FeedlotProductionSystemTypeSolidStorageTB,
  FeedlotProductionSystemTypeCompostingTB,
  FeedlotProductionSystemTypeAnaerobicLagoonTB,
])
export type FeedlotProductionSystemTypeT = Static<typeof FeedlotProductionSystemTypeTB>

export const DairyProductionSystemTypeIrrigatedPastureTB = Type.Literal('irrigated-pasture')
export const DairyProductionSystemTypeNonIrrigatedPastureTB = Type.Literal('non-irrigated-pasture')
export const DairyProductionSystemTypeIrrigatedCropTB = Type.Literal('irrigated-crop')
export const DairyProductionSystemTypeNonIrrigatedCropTB = Type.Literal('non-irrigated-crop')

export const DairyProductionSystemTypeTB = Type.Union([
  DairyProductionSystemTypeIrrigatedPastureTB,
  DairyProductionSystemTypeNonIrrigatedPastureTB,
  DairyProductionSystemTypeIrrigatedCropTB,
  DairyProductionSystemTypeNonIrrigatedCropTB
])
export type DairyProductionSystemTypeT = Static<typeof DairyProductionSystemTypeTB>

export const OrganicCarbonHistogramRecordTB = Type.Object({
  interval: Type.Number(),
  value: Type.Number(),
})
export type OrganicCarbonHistogramRecordT = Static<typeof OrganicCarbonHistogramRecordTB>

export const LOOCExistingSoilResponseTB = Type.Object({
  polygonArea: Type.Number(),
  polygonBDAverage: Type.Number(),
  polygonOCPercAverage: Type.Number(),
  surroundingOCPercHistogram: Type.Array(OrganicCarbonHistogramRecordTB),
  warningMessages: Type.Array(Type.String()),
  soilDepth: Type.Number(),
  status: Type.Optional(Type.Number()),
})
export type LOOCExistingSoilResponseT = Static<typeof LOOCExistingSoilResponseTB>

export const CohortToPropertyTB = Type.Object({
  id: Type.String(), // uuid
  cohortId: Type.String(),
  propertyId: Type.String(),
})
export type CohortToPropertyT = Static<typeof CohortToPropertyTB>


export const PropertyTypeFarmTB = Type.Literal('farm')
export type PropertyTypeFarmT = Static<typeof PropertyTypeFarmTB>
export const PropertyTypeFeedlotTB = Type.Literal('feedlot')
export type PropertyTypeFeedlotT = Static<typeof PropertyTypeFeedlotTB>
export const PropertyTypeProcessorTB = Type.Literal('processor')
export type PropertyTypeProcessorT = Static<typeof PropertyTypeProcessorTB>
export const PropertyTypeFurtherProcessorTB = Type.Literal('further-processor')
export type PropertyTypeFurtherProcessorT = Static<typeof PropertyTypeFurtherProcessorTB>
export const PropertyTypeDairyTB = Type.Literal('dairy')
export type PropertyTypeDairyT = Static<typeof PropertyTypeDairyTB>

export const PropertyTypeTB = Type.Union([
  PropertyTypeFarmTB,
  PropertyTypeFeedlotTB,
  PropertyTypeProcessorTB,
  PropertyTypeFurtherProcessorTB,
  PropertyTypeDairyTB,
])
export type PropertyTypeT = Static<typeof PropertyTypeTB>

export const PropertyTB = Type.Object({
  id: Type.String(), // uuid
  userId: Type.String(), // uuid, TOOD: superseeded by organisationId
  organisationId: Type.String(), // uuid, TOOD: this in theory is obsolete due to authorization relationships, may still be useful to have a reference in the db?
  propertyType: PropertyTypeTB,
  propertyName: Type.String(), // N.B. called this in the db, TODO: could / should be "name"?
  geom: MultiPolygonTB,
  centroid: PointTB,
  stateId: Type.Optional(Type.Number()), // N.B. entity def allows for null, but optional rather than nullable in application code
  deletedAt: Type.Optional(Type.Date()), // N.B. entity def allows for null, but optional rather than nullable in application code
  pic: Type.Optional(Type.String()), // N.B. entity def allows for null, but optional rather than nullable in application code
  ngr: Type.Optional(Type.String()), // N.B. entity def allows for null, but optional rather than nullable in application code
  rainfall: Type.Optional(Type.Number()), // N.B. entity def allows for null, but optional rather than nullable in application code
  region: Type.Number(),
  area: Type.Number(),
  cattleFarm: Type.Boolean({ default: false }),
  sheepFarm: Type.Boolean({ default: false }),
  grainFarm: Type.Boolean({ default: false }),
  feedlotProductionSystem: Type.Optional(FeedlotProductionSystemTypeTB),
  beefProcessor: Type.Boolean({ default: false }),
  sheepProcessor: Type.Boolean({ default: false }),
  soilCarbonData: Type.Optional(LOOCExistingSoilResponseTB),
  agriwebbFarmId: Type.Optional(Type.String()), // N.B. entity def allows for null, but optional rather than nullable in application code
  country: CountryCodeTB,
  hasCreatedOneOrMoreReductionPlans: Type.Boolean({ default: false }),
  reports: Type.Array(ReportTB),
  trees: Type.Array(Type.Any()), // TODO: tree data structure
  cohortsToProperty: Type.Array(CohortToPropertyTB),
  dairyProductionSystem: Type.Optional(DairyProductionSystemTypeTB)
})
export type PropertyT = Static<typeof PropertyTB>

// export const PropertyFarmTB = Type.Composite([
//   Type.Pick(PropertyTB, [
//     'id',
//     'userId',
//     'organisationId',
//     'organisationId',
//   ])
// ])

export const PropertyCreateTB = Type.Omit(PropertyTB, ['id', 'createdDate'])
export type PropertyCreateT = Static<typeof PropertyCreateTB>

// createdDate cannot be updated
export const PropertyUpdateTB = Type.Partial(Type.Omit(PropertyTB, ['id', 'createdDate']))
export type PropertyUpdateT = Static<typeof PropertyUpdateTB>

export const PropertyWithUserRoleTB = Type.Composite([PropertyTB, Type.Object({ role: PermissionRoleTB })])
export type PropertyWithUserRoleT = Static<typeof PropertyWithUserRoleTB>

export const PropertyCreateInviteDTOTB = Type.Object({
  inviteeEmail: Type.String(),
  role: PermissionRoleTB
})
export type PropertyCreateInviteDTOT = Static<typeof PropertyCreateInviteDTOTB>

export const BasePropertySummaryTB = Type.Pick(PropertyTB, [
  'id',
  'propertyName',
  'propertyType',
  'stateId',
  'centroid',
  'area',
  'country',
  'hasCreatedOneOrMoreReductionPlans',
  'organisationId',
])
export type BasePropertySummaryT = Static<typeof BasePropertySummaryTB>

export const FarmPropertyFieldsTB = Type.Object({
  propertyType: PropertyTypeFarmTB,
  cattleFarm: Type.Boolean(),
  sheepFarm: Type.Boolean(),
  grainFarm: Type.Boolean()
})
export type FarmPropertyFieldsT = Static<typeof FarmPropertyFieldsTB>

export const FeedlotPropertyFieldsTB = Type.Object({
  propertyType: PropertyTypeFeedlotTB,
  productionSystem: FeedlotProductionSystemTypeTB,
})
export type FeedlotPropertyFieldsT = Static<typeof FeedlotPropertyFieldsTB>

export const ProcessorPropertyFieldsTB = Type.Object({
  propertyType: Type.Union([PropertyTypeProcessorTB, PropertyTypeFurtherProcessorTB]),
  beefProcessor: Type.Boolean(),
  sheepProcessor: Type.Boolean(),
})
export type ProcessorPropertyFieldsT = Static<typeof ProcessorPropertyFieldsTB>

export const DairyPropertyFieldsTB = Type.Object({
  propertyType: PropertyTypeDairyTB,
  dairyProductionSystem: DairyProductionSystemTypeTB
})
export type DairyPropertyFieldsT = Static<typeof DairyPropertyFieldsTB>

export const FarmSummaryTB = Type.Composite([BasePropertySummaryTB, FarmPropertyFieldsTB])
export type FarmSummaryT = Static<typeof FarmSummaryTB>
export const FeedlotSummaryTB = Type.Composite([BasePropertySummaryTB, FeedlotPropertyFieldsTB])
export type FeedlotSummaryT = Static<typeof FeedlotSummaryTB>
export const ProcessorSummaryTB = Type.Composite([BasePropertySummaryTB, ProcessorPropertyFieldsTB])
export type ProcessorSummaryT = Static<typeof ProcessorSummaryTB>
export const DairySummaryTB = Type.Composite([BasePropertySummaryTB, DairyPropertyFieldsTB])
export type DairySummaryT = Static<typeof DairySummaryTB>

export const PropertySummaryTB = Type.Union([
  FarmSummaryTB,
  FeedlotSummaryTB,
  ProcessorSummaryTB,
  DairySummaryTB
])
export type PropertySummaryT = Static<typeof PropertySummaryTB>
