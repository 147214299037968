import { Type, Static } from '@sinclair/typebox'

import { PermissionRoleTB } from './permission'
import { SubscriptionTB } from './subscription'

export const OrganisationTypeFarmTB = Type.Literal('farm')
export const OrganisationTypeVisionTB = Type.Literal('vision')
export const OrganisationTypeRecordsTB = Type.Literal('records')
export const OrganisationTypeRuminatiTB = Type.Literal('ruminati')

export const OrganisationTypeTB = Type.Union([
  OrganisationTypeFarmTB,
  OrganisationTypeVisionTB,
  OrganisationTypeRecordsTB,
  OrganisationTypeRuminatiTB
])
export type OrganisationTypeT = Static<typeof OrganisationTypeTB>

export const OrganisationMetadataTB = Type.Object({
  description: Type.Optional(Type.String()),
})
export type OrganisationMetadataT = Static<typeof OrganisationMetadataTB>

export const OrganisationPreferencesTB = Type.Object({})
export type OrganisationPreferencesT = Static<typeof OrganisationPreferencesTB>

export const OrganisationTB = Type.Object({
  id: Type.String({
    description: "Ruminati Organisation ID"
  }),
  name: Type.String({
    description: "The Organisation Name as specified by the user"
  }),
  type: OrganisationTypeTB,
  iconSmallUrl: Type.Optional(Type.Union([Type.String(), Type.Null()])), // N.B. entity def allows for null, but optional rather than nullable in application code
  logoUrl: Type.Optional(Type.Union([Type.String(), Type.Null()])), // N.B. entity def allows for null, but optional rather than nullable in application code
  subscription: SubscriptionTB,
  auctionsPlusStatusRetrievalApproved: Type.Boolean(),
  createdDate: Type.Date(),
  deletedAt: Type.Optional(Type.Union([Type.String(), Type.Null()])), // N.B. entity def allows for null, but optional rather than nullable in application code
  metadata: Type.Optional(OrganisationMetadataTB),
  preferences: Type.Optional(OrganisationPreferencesTB)
})
export type OrganisationT = Static<typeof OrganisationTB>

export const OrganisationCreateTB = Type.Omit(OrganisationTB, ['id', 'createdDate', 'subscription', 'auctionsPlusStatusRetrievalApproved'])
export type OrganisationCreateT = Static<typeof OrganisationCreateTB>

// createdDate cannot be updated
export const OrganisationUpdateTB = Type.Partial(Type.Omit(OrganisationTB, ['id', 'createdDate', 'deletedAt', 'subscription']))
export type OrganisationUpdateT = Static<typeof OrganisationUpdateTB>

export const OrganisationWithUserRoleTB = Type.Composite([OrganisationTB, Type.Object({ role: PermissionRoleTB })])
export type OrganisationWithUserRoleT = Static<typeof OrganisationWithUserRoleTB>

export const OrganisationSummaryTB = Type.Pick(OrganisationTB, [
  'id',
  'name',
  'subscription'
])
export type OrganisationSummaryT = Static<typeof OrganisationSummaryTB>
