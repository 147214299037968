import { useNavigate } from "react-router-dom";

import { usePopupStore } from "../../state/popup_store";
import { useDatabaseStore } from "../../state/database_store";
import { useAuthStore } from "../../state/auth_store";

import { Property, Report } from "../../models/property_models";
import DisplayCard from "../display_card";
import CardActionButton from "../buttons/card_action_button";
import InlineResultsSummary from "../inline_results_summary";
import { deleteReport } from "../../services/property_service";

import { getPreviousCompletedReport } from "../../utilities/reports";
import { ruminatiColors } from "../../utilities/colors";
import { FarmReportsPopupId } from "../../screens/farm/farm";
import { getReportNextStep } from "../../utilities/next_steps";
import { Row } from "../styled_layout";
import { BodyText } from "../styled_text";
import { SubscriptionType } from "../../models/subscription_model";
import { getResourcePermissions, permissionsAllowDelete } from "@/utilities/permissions";
import { getCurrentOrganisation } from "@/utilities/organisations";

export type ReportSummaryCardProps = {
  report: Report
  allReports: Report[]
  property: Property
};

export default function ReportSummaryCard({
  report,
  allReports,
  property
}: ReportSummaryCardProps) {

  const navigate = useNavigate();
  const popupStore = usePopupStore();
  const databaseStore = useDatabaseStore();
  const authStore = useAuthStore()
  const user = authStore.user
  const currentOrg = user ? getCurrentOrganisation(user!) : undefined
  const prevCompletedReport = getPreviousCompletedReport(allReports, report)
  const isPrime = currentOrg?.subscription?.type === SubscriptionType.PRIME;
  const reportPermissions = getResourcePermissions(user!, report.id)
  const canDelete = permissionsAllowDelete(reportPermissions)

  function startDeleteOrArchiveReportPopup(reportId: string, removalType: string) {
    popupStore.addPopup(FarmReportsPopupId.DeleteOrArchiveReport, undefined, {
        reportId,
        removalType,
        onConfirm: async () => {
            await deleteReport(reportId)
            databaseStore.removeReportById(reportId)
        }
    })
  }

  function getActionButtons (): JSX.Element[] {
    const removalTypeLabel = report.complete ? 'Archive' : 'Delete'
    return [
      <CardActionButton
          onClick={() => {
            if (report.complete) {
              return navigate(`/form/${report.id}/summary`)
            }
            // Navigate to any empty page and we'll get redirected
            // to the first form page
            navigate(`/form/${report.id}/~`)
          }}
          label="Manage"
      />,
      <CardActionButton
          colorScheme="red"
          onClick={() => startDeleteOrArchiveReportPopup(report.id, removalTypeLabel)}
          label={removalTypeLabel}
          disabled={!canDelete}
          disabledMessage={`You do not have enough permissions to ${removalTypeLabel} this report`}
      />
    ];
  }

  function getFooter (): JSX.Element | undefined {
    const nextStep = getReportNextStep(isPrime, report, property)
    if (nextStep === undefined) return undefined
    return <Row
      style={{
        cursor: 'pointer'
      }}
      onClick={() => {
        if (nextStep) navigate(nextStep.actionLink)}
      }>
        <BodyText style={{marginRight: '12px'}}>Next Steps</BodyText>
        <BodyText>{nextStep?.text}</BodyText>
      </Row>
  }

  function getReportSummary(report: Report, previousReport?: Report): JSX.Element[] {
      const fallbackText = report.complete ? "" : "Emission report in progress";
      return [
          <InlineResultsSummary
              key={report.id}
              report={report}
              previous={previousReport?.complete ? previousReport : undefined}
              fallbackText={fallbackText}
              fallbackColor={ruminatiColors.orange}
          />
      ];
  }

  return (
    <div
        style={{
            padding: "0 0 8px 0",
            width: "100%",
        }}
        key={report.id}
    >
        <DisplayCard
            key={report.id}
            title={`FY ${report.financialYear.toString()}`}
            buttons={getActionButtons()}
            contentArea={getReportSummary(report, prevCompletedReport)}
            date={report.complete ? report.completionDate : report.createdDate}
            footerText={getFooter()}
        />
    </div>
  )
}
