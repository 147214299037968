import { Column, Row } from "../../components/styled_layout";
import { GreenCard } from "../card";
import Heading from "../heading";
import { BodyText } from "../styled_text";

export function IntegrationCard (props: {
  title: string,
  description: string,
  action: JSX.Element,
  logo?: string
}) {
  return (
      <GreenCard
      id={`${props.title}_integration`}
      style={{
          borderWidth: '0px',
          padding: '24px'
      }}>
          <Row style={{marginBottom: '16px'}}>
              <Column style={{ width: '20%', alignItems: 'flex-start' }}>
                  <Row>
                      <img src={props.logo} alt={props.title + "logo"} width="20px" style={{marginRight: '10px'}}/>
                      <Heading level={5}>{props.title}</Heading>
                  </Row>
              </Column>
              <Column style={{ width: '80%', alignItems: 'flex-end' }}>
                  <Row>
                      {props.action}
                  </Row>
              </Column>
          </Row>
          <Row>
              <Column style={{ width: '100%' }}>
                  <BodyText style={{lineHeight: '24px'}}>{props.description}</BodyText>
              </Column>
          </Row>
      </GreenCard>
  )
}
