import DseBeefCalculator from './dse/beef-calculator'
import DseSheepCalculator from './dse/sheep-calculator'
import { convertDSEtoAE } from "../../utilities/dse";
import { ScrollableBody } from "../styled_layout";

export type DSECalculatorType =
    | "beef-dse"
    | "beef-ae"
    | "sheep-dse"
    | "sheep-ae";

export function isCalculatorType(val: string) {
    return ["beef-dse", "beef-ae", "sheep-dse", "sheep-ae"].includes(val);
}

const isDSE = (type: DSECalculatorType): boolean =>
    type === "beef-dse" || type === "sheep-dse";

const isBeef = (type: DSECalculatorType): boolean =>
    type === "beef-ae" || type === "beef-dse";

const isAE = (type: DSECalculatorType): boolean =>
    type === "beef-ae" || type === "sheep-ae";


/**
 * The DSE Calculator form, intended to be shown inside a popup
 */
export default function DSECalculator({
    type,
    onSubmit,
    metadata
}: {
    type: DSECalculatorType;
    onSubmit: (val: number) => void;
    metadata: any;
}) {
    return (
        <div style={{ padding: "32px" }}>
        <ScrollableBody>
            {isBeef(type) ?
                <DseBeefCalculator
                    onSubmit={(dse: number) => {
                        onSubmit(isDSE(type) ? dse : convertDSEtoAE(dse))
                    }}
                    metadata={metadata}
                    isAE={isAE(type)}
                /> :
                <DseSheepCalculator
                    onSubmit={(dse: number) => {
                        onSubmit(isDSE(type) ? dse : convertDSEtoAE(dse))
                    }}
                    metadata={metadata}
                    isAE={isAE(type)}
                />
            }
            </ScrollableBody>
        </div>
    );
}
