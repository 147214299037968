import { Link } from "react-router-dom";
import styled from "styled-components";

import { Column, FixedHeaderWrapper, Row } from "../styled_layout";
import { BodyText } from "../styled_text";
import Icon from "../icon";

import { useAuthStore } from "@/state/auth_store";
import { usePartnerStore } from "@/state/partners";
import { useDatabaseStore } from "@/state/database_store";

import useWindowSize from "@/hooks/useWindowSize";

import { getUnusedInvitations } from "@/utilities/partners";
import { ruminatiColors } from "@/utilities/colors";


import RuminatiLogo from "/icons/ruminati-logo.svg";
import AccountMenuItem from "./account_menu_item";

/**
 * The header shown at the top of all pages
 */
export default function Header() {
    const authStore = useAuthStore()
    const partnerStore = usePartnerStore()
    const databaseStore = useDatabaseStore()

    const invitations = partnerStore.invitations ?? []
    const linkedFarms = partnerStore.linkedFarms ?? []
    const unusedInvitations = getUnusedInvitations(invitations, linkedFarms, databaseStore.properties ?? [])

    const [pageWidth] = useWindowSize()
    const isWide = (pageWidth ?? window.innerWidth) > 1300

    return (
        <FixedHeaderWrapper
            style={{
                borderBottom: `1px solid ${ruminatiColors.green_3_30}`
             }}
        >
            <Row
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                }}
            >
                <Column
                    style={{
                        // (Viewport width - Middle area - header padding) / 2
                        width: isWide ? 'calc((100vw - 936px - 32px) / 2)' : undefined,
                        alignItems: 'flex-start',
                        marginRight: isWide ? 0 : '40px'
                    }}
                >
                   <MenuItem to="/" style={{display: 'flex', borderBottomWidth: '0px'}}>
                        <img src={RuminatiLogo} alt="Ruminati Logo"/>
                    </MenuItem>
                </Column>
                {authStore.user ?
                    <><Column
                        style={{
                            flexGrow: 1,
                            alignItems: 'flex-start'
                        }}
                    >
                        <Row style={{gap: '32px'}}>
                            <MenuItem to="/enterprises">
                                <MenuItemText>Enterprises</MenuItemText>
                            </MenuItem>
                            <MenuItem to="/partners">
                                <Row>
                                    <ShakeEffect>
                                        <MenuItemText>
                                            Data Sharing
                                            {unusedInvitations && unusedInvitations.length > 0 ?
                                                <span className="bellIcon" style={{
                                                    position: 'relative',
                                                    marginLeft: '8px',
                                                    top: '2px'
                                                }}>
                                                    <Icon icon="bell" />
                                                </span>
                                            : undefined}
                                        </MenuItemText>
                                    </ShakeEffect>
                                </Row>
                            </MenuItem>
                        </Row>
                    </Column>
                    <Column>
                        <Row>
                          <AccountMenuItem/>
                        </Row>
                    </Column>
                </> : undefined}
            </Row>
        </FixedHeaderWrapper>
    );
}

const MenuItem = styled(Link)`
    transition: 0.2s;
    padding: 0px 4px;
    border-bottom: 2px solid ${ruminatiColors.transparent};

    :hover {
        color: ${ruminatiColors.dark_green};
        border-bottom-color: ${ruminatiColors.green_3};
    }
`;

const MenuItemText = styled(BodyText)`
    font-size: 16px;
    line-height: 68px;
`;

const ShakeEffect = styled.div`
    :hover, :focus {
        svg {
            animation: bellshake .5s cubic-bezier(.36,.07,.19,.97) both;
            backface-visibility: hidden;
            transform-origin: top;
            animation-iteration-count: 2;
        }
    }

    @keyframes bellshake {
        0% { transform: rotate(0); }
        15% { transform: rotate(10deg); }
        30% { transform: rotate(-10deg); }
        45% { transform: rotate(8deg); }
        60% { transform: rotate(-8deg); }
        75% { transform: rotate(4deg); }
        85% { transform: rotate(-4deg); }
        92% { transform: rotate(2deg); }
        100% { transform: rotate(0); }
      }
`;
