import { Column, Row } from "../styled_layout"
import { LooserBodyText } from "../styled_text"
import { ruminatiColors } from "@/utilities/colors"
import { useAuthStore } from "@/state/auth_store"
import { ROLES } from "@ruminati/permissions/roles"

export default function RelationshipUpdated (props: {
  relationshipId: string
  newRoleId: string
}) {

  const authStore = useAuthStore();
  const relationship = authStore.user?.relationships?.find(r => r.id === props.relationshipId) 
  const org = authStore.user?.organisations?.find(o => o.id === relationship?.resourceId) 
  const role = ROLES.find(r => r.id === props.newRoleId)

  if (!org || !role) return

  return <>
  <Row style={{
    width: '100%',
    justifyContent: 'flex-start',
    marginBottom: '12px'
  }}>
    <Column style={{
      flexGrow: 1,
      alignItems: 'flex-start'
      }}>
      <LooserBodyText style={{
        color: ruminatiColors.green_4
      }}>
         Your permissions in <strong>{org.name}</strong> have been changed to <strong>{role.name}</strong>.
      </LooserBodyText>
    </Column>
  </Row>
</>
}