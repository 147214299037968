import { PropsWithChildren } from "react";
import { ruminatiColors } from "../../utilities/colors";
import Button from "./button";

type OutlineButtonProps = {
    width?: string;
    height?: string;
    disabled?: boolean;
    disabledMessage?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    activeColor?: string;
    textColor?: string;
    borderColor?: string;
    borderRadius?: string;
    fontSize?: string;
};

/**
 * A less prominent (secondary) button. Typically used for the alternative actions
 * on the page.
 * @param width optional. The width of the button in px. Defaults to auto
 * @param height optional. The height of the button in px. Defaults to 48px
 * @param borderRadius optional. The border radius of the button in px. Defaults to 24px
 * @param disabled optional. Prevents click interaction and greys out
 * button. Defaults to false
 * @param children optional. The content of the button
 * @param onClick optional. The function called when the button is clicked
 */
export default function OutlineButton(props: PropsWithChildren<OutlineButtonProps>) {
    const _backgroundColor = "transparent";

    return (
        <Button
            onClick={props.onClick}
            backgroundColor={_backgroundColor}
            hoverBackgroundColor={_backgroundColor}
            activeBackgroundColor={_backgroundColor}
            textColor={
                props.disabled
                    ? ruminatiColors.effective_black_30
                    : props.textColor ?? ruminatiColors.green_3
            }
            hoverTextColor={
                props.disabled
                    ? ruminatiColors.effective_black_60
                    : (props.activeColor ?? ruminatiColors.orange)
            }
            activeTextColor={props.activeColor ?? ruminatiColors.orange}
            border={`1px solid ${props.disabled
                ? ruminatiColors.effective_black_30
                : props.activeColor ?? ruminatiColors.green_3
                }`}
            borderRadius={props.borderRadius ?? "24px"}
            disabled={props.disabled}
            disabledMessage={props.disabledMessage}
            fontSize={props.fontSize ?? "16px"}
            height={props.height ? props.height : "48px"}
            width={props.width ? props.width : "auto"}
            padding="0"
        >
            {props.children}
        </Button>
    );
}
