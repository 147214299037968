import { useMutation, useQuery } from "@tanstack/react-query"

import { baseUrl, deleteHttp, get, patch, post } from '@/services/api_service';
import { getTokens } from '@/services/auth_service';
import { RelationshipCreateT, RelationshipT, RelationshipUpdateT } from "@ruminati/types/relationship";
import useOrgSwitcher from "./useOrgSwitcher";
import { ResourceTypeOrganisationTB } from "@ruminati/types/resource";
import { getCurrentOrganisationId } from "@/utilities/organisations";
import useUserProfile from "./useUserProfile";

export function useRelationshipResourceDetails(relationshipId: string) {
  return useQuery({
    queryKey: ['relationship_resource_details', relationshipId],
    queryFn: () => new Promise<any>(async (resolve, reject) => {
      get(`${baseUrl}/relationships_public/${relationshipId}/resource_details`, getTokens().token)
      .then((result) => {
        resolve(result)
      }).catch(err => {
        reject(err)
      })
    })
  })
}

export function useCreateRelationship() {
  const userProfile = useUserProfile()
  return useMutation({
    mutationFn: (body: RelationshipCreateT) => post(`${baseUrl}/relationships`, body, getTokens().token),
    onSuccess: async () => {
      await userProfile.afterRelationshipChange()
    }
  })
}

export function usePatchRelationship() {
  const userProfile = useUserProfile()
  return useMutation({
    mutationFn: (params: {
      relationshipId: string,
      body: RelationshipUpdateT
    }) => patch(`${baseUrl}/relationships/${params.relationshipId}`, params.body, getTokens().token),
    onSuccess: async () => {
      await userProfile.afterRelationshipChange()
    }
  })
}

export function useAcceptRelationship() {
  const orgSwitcher = useOrgSwitcher()
  const userProfile = useUserProfile()

  return useMutation({
    mutationFn: (params: {
      relationshipId: string,
      recipientId: string
    }) => patch(`${baseUrl}/relationships/${params.relationshipId}/accept`, {
      isPending: false,
      recipientId: params.recipientId
    }, getTokens().token),
    onSuccess: async (relationship: RelationshipT) => {
      if (relationship.resourceType === ResourceTypeOrganisationTB.const) {
        const orgId = getCurrentOrganisationId()
        // If an org is already selected just reload the profile to get the users orgs
        if (!orgId) {
          await orgSwitcher.changeOrganisation(relationship.resourceId)
        }
        await userProfile.afterRelationshipChange()
      }
    }
  })
}

export function useDeleteRelationship() {
  const userProfile = useUserProfile()

  return useMutation({
    mutationFn: (args: {
      relationshipId: string
    }) => deleteHttp(`${baseUrl}/relationships/${args.relationshipId}`, getTokens().token),
    onSuccess: async () => {
      await userProfile.afterRelationshipChange()
    }
  })
}
