import { useEffect, useState } from "react";
import styled from "styled-components";
import { DropdownOptionData, OnChangeFunction } from "../../models/form/form_fields/form_field";
import { ruminatiColors } from "../../utilities/colors";

type RadioBoxProps<T extends string | number | boolean> = {
    id: string;
    name: string;
    size?: string;
    initialValue: T;
    horizontal?: boolean;
    options: DropdownOptionData<T>[];
    onChange?: OnChangeFunction<T>;
    disabled?: boolean
};

// Used as enum for sizes
export const RadioBoxSize = {
    Large: "20px",
    Medium: "18px",
    Small: "16px",
};

/**
 * A group of radio buttons
 * @param name the HTML form name to give the radio buttons
 * @param size optional. The size of the label text in px. Defaults to 18px
 * @param initialValue the initial checked radio box
 * @param horizontal optional. If true, will layout the buttons horizontally,
 * otherwise vertically.
 * @param options an array of the options and their labels and values.
 */
export default function RadioBox<T extends string | number | boolean>(props: RadioBoxProps<T>) {
    const [value, setValue] = useState(props.initialValue);

    useEffect(() => {
        setValue(props.initialValue)
    }, [props.initialValue])

    const shape: "circle" | "square" = "circle";

    const onChange = (value: T) => {
        setValue(value);
        props.onChange?.(value);
    };

    return (
        <RadioBoxWrapper direction={props.horizontal ? "row" : "column"}>
            {props.options.map((option) => {
                return (
                    <RadioBoxLabel
                        key={option.value.toString()}
                        checked={value === option.value}
                        size={props.size ?? "18px"}
                        shape={shape}
                    >
                        <input
                            type="radio"
                            name={props.name}
                            defaultChecked={value === option.value}
                            onChange={() => onChange(option.value)}
                            disabled={props.disabled}
                        />
                        <p style={{ margin: "0 0 0 8px" }}>{option.name}</p>
                    </RadioBoxLabel>
                );
            })}
        </RadioBoxWrapper>
    );
}

type RadioBoxWrapperProps = {
    direction: string;
};

const RadioBoxWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    flex-direction: ${(props: RadioBoxWrapperProps) => props.direction};
`;

type RadioBoxLabelProps = {
    key: any;
    checked: boolean;
    size: string;
    shape: "square" | "circle";
};

const RadioBoxLabel = styled.label`
    position: relative;

    font-family: "TTInterfaces", sans-serif;
    font-weight: 600;
    font-size: ${(props: RadioBoxLabelProps) => props.size};
    line-height: 32px;

    padding: 5px 10px;

    cursor: pointer;

    display: flex;
    align-items: center;

    color: ${(props: RadioBoxLabelProps) =>
        props.checked
            ? ruminatiColors.green_3
            : ruminatiColors.effective_black_30};

    transition: 0.3s ease color;
    user-select: none;

    &:hover {
        color: ${ruminatiColors.orange_50};
    }

    &:active,
    &:focus-within {
        color: ${ruminatiColors.orange};
    }

    &::before {
        content: "";

        position: absolute;
        background-color: ${(props: RadioBoxLabelProps) =>
        props.checked ? "currentColor" : "transparent"};
        height: 12px;
        width: 12px;
        border-radius: ${(props: RadioBoxLabelProps) =>
        props.shape === "square" ? "4px" : "12px"};

        transform: ${(props: RadioBoxLabelProps) =>
        props.shape === "square"
            ? "translateX(6px);"
            : "translateX(6px) translateY(-0.5px);"};
    }

    input[type="radio"] {
        // Hide default radio button
        appearance: none;
        background-color: #fff; /* For iOS < 15 to remove gradient background */
        margin: 0; /* Not removed via appearance */

        // Custom square
        font: inherit;
        color: currentColor;
        width: 24px;
        height: 24px;
        border-radius: ${(props: RadioBoxLabelProps) =>
        props.shape === "square" ? "8px" : "12px"};
        border: 1px solid currentColor;
        background-color: ${ruminatiColors.bone};

        cursor: pointer;
    }
`;
