export const ruminatiColors = {
    bone: "#F8F5F1",
    bone_90: "rgba(248, 245, 241, 0.9)",
    bone_60: "rgba(248, 245, 241, 0.6)",
    bone_0: "rgba(248, 245, 241, 0)",
    approval_green: "rgba(130, 200, 112, 1)",
    light_green: "#728975",
    dark_green: "rgba(69, 95, 76, 1)",
    green_3: "rgba(89, 111, 91, 1)",
    green_4: "rgba(114, 137, 117, 1)",
    green_3_5: "rgba(89, 111, 91, 0.05)",
    green_3_10: "rgba(89, 111, 91, 0.1)",
    green_3_15: "rgba(89, 111, 91, 0.15)",
    green_3_50: "rgba(89, 111, 91, 0.5)",
    green_3_30: "rgba(89, 111, 91, 0.3)",
    light_orange: "rgba(255, 173, 115, 1)",
    orange: "#FF9839",
    orange_5: "rgba(255, 152, 57, 0.05)",
    orange_50: "rgba(255, 152, 57, 0.5)",
    orange_40: "rgba(255, 152, 57, 0.4)",
    orange_40_opaque: "rgba(251, 208, 168, 1)", // equivalent color when over bone
    orange_20: "rgba(255, 152, 57, 0.2)",
    orange_faded: "rgba(255, 173, 115, 0.5)",
    orange_dark: "#dc822f",
    red: "#E98172",
    effective_black: "rgba(49, 59, 52, 1)",
    effective_black_60: "rgba(49, 59, 52, 0.6)",
    effective_black_30: "rgba(49, 59, 52, 0.3)",
    tag_1: "#E8D696",
    tag_2: "#FFAD73",
    tag_3: "#C1AD94",
    tag_4: "#87B8AC",
    tag_5: "#7299BD",
    tag_6: "#A8C8DA",
    blue: "#0b89bf",
    data_blue: "#6598B4",
    data_green: "#74A398",
    data_orange: "#FF8E3C",
    data_yellow: "#FFBC58",
    data_blue_translucent: "rgba(168, 200, 218, 0.4)",
    data_green_translucent: "rgba(135, 184, 172, 0.3)",
    data_orange_translucent: "rgba(255, 173, 115, 0.2)",
    transparent: "rgba(0, 0, 0, 0)",
    disabled_grey: "#f0edea",
    disabled_grey_dark: "#959593"

};

// Converts 0.0 into '00' or 0.5 into '75'
// https://davidwalsh.name/hex-opacity
// https://stackoverflow.com/a/62560922
function convertOpacityToHex (opacityNumber: number): string {
    return Math.floor(opacityNumber * 255).toString(16)
}

export function combineHexColorAndOpacityToHexWithOpacity (hexColor: string, opacityNumber: number): string {
    return `${hexColor}${convertOpacityToHex(opacityNumber)}`
}
