import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Checkbox, ConfigProvider } from "antd";

import Screen from "./screen";
import Heading from "../components/heading";
import { Column, Row, Wrapper } from "../components/styled_layout";
import AuthFooter from "../components/footers/auth_footer";
import { signUpEmailPassword } from "../services/auth_service";
import { useAuthStore } from "../state/auth_store";

import { SignUpDTO } from "../models/api_models";
import { Form } from "../models/form/form";
import {
  DropdownFormField,
  EmailFormField,
  TextFormField,
} from "../models/form/form_fields/basic_form_fields";
import { countriesDropdownOptions } from "./../utilities/countries";
import { FieldSize } from "@/models/form/form_fields/form_field";
import { GreenCard, InfoCardText } from "@/components/card";
import { BodyText } from "@/components/styled_text";
import { ruminatiColors } from "@/utilities/colors";
import { usePartnerStore } from "@/state/partners";
import { composeIconUrl } from "@/utilities/partners";
import { VisionOrganisationBasicDTO } from "@/models/vision";
import { checkPromoCode } from "@/services/subscription_service";
import { TextField } from "@/components/form/input_field";
import HiddenFormField from "@/models/form/form_fields/hidden_form_field";
import { useRelationshipResourceDetails } from "@/hooks/relationships";

type CouponDetails = {
  promoCode: string;
  couponName: string;
};

/**
 * A screen allows the user to sign up to Ruminati
 * @returns the Sign Up Screen component
 */
export default function SignUpScreen() {
  const navigate = useNavigate();
  const authStore = useAuthStore();
  const partnerStore = usePartnerStore();

  const [searchParams, _setSearchParams] = useSearchParams();
  const orgId = searchParams.get("visionOrgId");
  const voucherPoolId = searchParams.get("voucherPoolId");
  const promoCode = searchParams.get("promoCode");
  const relationshipInvitationId = searchParams.get("relationshipInvitationId");

  const partnerRecord = orgId ? partnerStore.miscOrgs[orgId] : undefined;

  const partnerSupportsLinkViaRegisterFlow =
    partnerRecord &&
    partnerRecord !== "loading" &&
    partnerRecord.supportLinkViaRegisterFlow;
  const partnerRequiresReferenceId =
    partnerRecord &&
    partnerRecord !== "loading" &&
    partnerRecord.requireClientReferenceIdDuringRegister;

  const relationshipData = relationshipInvitationId ? useRelationshipResourceDetails(relationshipInvitationId) : undefined

  const [localState, setLocalState] = useState<{
    formData: any;
    partnerReferenceId: undefined | string;
    consentChecked: boolean;
    errorText: undefined | string;
    promoCodeDetails: undefined | CouponDetails;
    relationshipResourceDetails: any;
  }>({
    formData: {},
    partnerReferenceId: undefined,
    consentChecked: false,
    errorText: undefined,
    promoCodeDetails: undefined,
    relationshipResourceDetails: undefined
  });

  const signUpForm = {
    fields: {
      firstName: new TextFormField({
        placeholder: "First name",
        required: true,
        size: FieldSize.Half,
      }),
      lastName: new TextFormField({
        placeholder: "Last name",
        required: true,
        size: FieldSize.Half,
      }),
      company: !relationshipInvitationId ? new TextFormField({
        placeholder: "Company",
        required: false,
      }) : new HiddenFormField(),
      country: new DropdownFormField({
        placeholder: "Country",
        options: countriesDropdownOptions,
        required: true,
      }),
      email: new EmailFormField({
        placeholder: "Email address",
        required: true,
      }),
      password: new TextFormField({
        placeholder: "Password",
        required: true,
        obscured: true,
        useCleanStringValidator: false,
        validator: (value) => value !== undefined && value?.length >= 6,
      }),
      confirmPassword: new TextFormField({
        placeholder: "Confirm password",
        required: true,
        obscured: true,
        useCleanStringValidator: false,
        validator: (value, state) => value === state.get.password,
      }),
    },
  };

  // const [partnerReferenceId, setPartnerReferenceId] = useState<string | undefined>(undefined);
  // const [errorText, setErrorText] = useState<string | undefined>(undefined);
  // const [promoCodeDetails, setPromoCodeDetails] = useState<CouponDetails | undefined>(undefined);

  // TODO: type should be something like RegisterFormDataT, and relate one way or another to RegisterDTOT
  /**
    * Checks the signup data, and if valid, signs up the user
    * @returns promise resolving on sign up completion or false if form invalid
    */
  async function signup(signUpData: SignUpDTO) {
      if (
        partnerRequiresReferenceId &&
        localState.partnerReferenceId === undefined
      ) {
        setLocalState({
          ...localState,
          errorText: "Partner Reference ID is empty",
        });
      }

      // Handle confirm password
      if (signUpData.password !== signUpData.confirmPassword) {
        setLocalState({
          ...localState,
          errorText: "Passwords don't match",
        });
      }

      try {
        const apiData = {
          ...signUpData,
          promoCode: promoCode ?? undefined,
          shareAllVisionOrgId:
            partnerSupportsLinkViaRegisterFlow && orgId ? orgId : undefined,
          voucherPoolId:
            partnerSupportsLinkViaRegisterFlow && voucherPoolId
              ? voucherPoolId
              : undefined,
          partnerReferenceId:
            partnerRequiresReferenceId && localState.partnerReferenceId
              ? localState.partnerReferenceId
              : undefined,
          relationshipInvitationId: relationshipInvitationId ?? undefined
        };

        const user = await signUpEmailPassword(apiData);
        if (user) {
          authStore.signIn(user);
          navigate("/verifyEmail");
        }
      } catch (err) {
        if (err instanceof Error) {
          if (err.message === "USER_EXISTS") {
            setLocalState({
              ...localState,
              errorText: "Email is already registered on Ruminati",
            });
          }
        }
      }
    }

  function partnerText(org: VisionOrganisationBasicDTO) {
    if (org.supportLinkViaRegisterFlow) {
      let copy = undefined;
      let consentCheckbox = undefined;
      let clientRefId = undefined;

      if (org.consentTerms) {
        copy = (
          <InfoCardText
            style={{ margin: "10px 0px 10px 16px", paddingRight: "16px" }}
            dangerouslySetInnerHTML={{ __html: org.consentTerms }}
          />
        );
        consentCheckbox = (
          <InfoCardText style={{ marginTop: 20 }}>
            <ConfigProvider
              theme={{
                components: {
                  Checkbox: {
                    colorPrimary: ruminatiColors.green_3,
                    colorPrimaryHover: ruminatiColors.green_4,
                  },
                },
              }}
            >
              <Checkbox
                checked={localState.consentChecked}
                onChange={() => {
                  setLocalState({
                    ...localState,
                    consentChecked: !localState.consentChecked,
                  });
                }}
              >
                <BodyText style={{ marginLeft: 0 }}>
                  I consent to sharing my data
                </BodyText>
              </Checkbox>
            </ConfigProvider>
          </InfoCardText>
        );
      } else {
        copy = (
          <InfoCardText>
            You've been invited to create a Ruminati account by {org.name}. By
            registering this account you agree to have your Ruminati PRIME
            subscription sponsored by {org.name} and to share your emissions
            data with them.
          </InfoCardText>
        );
      }

      if (org.requireClientReferenceIdDuringRegister) {
        clientRefId = (
          <InfoCardText
            style={{ margin: "10px 0px 10px 16px", paddingRight: "16px" }}
          >
            <TextField
              id="clientReferenceId"
              size="small"
              placeholder="Client Reference ID"
              width="460px"
              onChange={(value) => {
                setLocalState({
                  ...localState,
                  partnerReferenceId: value,
                });
              }}
            />
          </InfoCardText>
        );
      }

      return [copy, consentCheckbox, clientRefId].filter(
        (e) => e !== undefined,
      );
    } else {
      return `NOTE: ${org.name} does not currently support the automatic sharing flow.`;
    }
  }

  useEffect(() => {
    if (promoCode) {
      checkPromoCode(promoCode).then((promoCode: CouponDetails) => {
        setLocalState({
          ...localState,
          promoCodeDetails: promoCode,
        });
      });
    } else if (relationshipInvitationId && relationshipData) {
      if (relationshipData.isLoading) return
      if (relationshipData.data) setLocalState({
        ...localState,
        relationshipResourceDetails: relationshipData.data
      })
    }
  }, [promoCode, relationshipInvitationId, relationshipData?.isLoading]);

  const consentToSignup =
    partnerRecord && partnerRecord !== "loading" && partnerRecord.consentTerms
      ? localState.consentChecked
      : true;
  const signUpDisabled =
    (partnerRequiresReferenceId &&
      localState.partnerReferenceId === undefined) ||
    !consentToSignup;

  return (
    <Screen pageTitle="Create Account">
      <SignUpWrapper>
        <Wrapper style={{ padding: "0 0 24px 0" }}>
          <Heading level={2}>Sign up to Ruminati</Heading>
        </Wrapper>
        {partnerRecord && partnerRecord !== "loading" ? (
          <Row style={{ marginBottom: "20px" }}>
            <GreenCard style={{ border: "none" }}>
              <Row>
                <Column style={{ width: "15%", alignSelf: "flex-start" }}>
                  {partnerRecord.icon_small_url ? (
                    <img
                      src={composeIconUrl(partnerRecord.icon_small_url)}
                      height="50px"
                      style={{ marginTop: "20px" }}
                    ></img>
                  ) : undefined}
                </Column>
                <Column style={{ width: "85%" }}>
                  <BodyText
                    style={{
                      textAlign: "left",
                      color: ruminatiColors.green_3,
                      lineHeight: "24px",
                      width: "100%",
                    }}
                  >
                    {partnerText(partnerRecord)}
                  </BodyText>
                </Column>
              </Row>
            </GreenCard>
          </Row>
        ) : undefined}

        {promoCode && localState.promoCodeDetails ? (
          <Row style={{ marginBottom: "20px" }}>
            <GreenCard style={{ border: "none" }}>
              <Row>
                <Column style={{ width: "5%" }}>🎉</Column>
                <Column style={{ width: "95%" }}>
                  <BodyText
                    style={{
                      marginLeft: "16px",
                      textAlign: "left",
                      color: ruminatiColors.green_3,
                      lineHeight: "24px",
                      width: "100%",
                    }}
                  >
                    Congratulations, you've got a Promo Code, {promoCode}, which
                    will automatically give you a subscription to Ruminati PRIME
                    when you register your account.
                  </BodyText>
                </Column>
              </Row>
            </GreenCard>
          </Row>
        ) : undefined}

        {relationshipInvitationId && localState.relationshipResourceDetails ? (
          <Row style={{ marginBottom: "20px", width: '100%' }}>
            <GreenCard style={{ border: "none" }}>
              <Row>
                <Column style={{ width: "100%" }}>
                  <BodyText
                    style={{
                      marginLeft: "16px",
                      textAlign: "center",
                      color: ruminatiColors.green_3,
                      lineHeight: "24px",
                      width: "100%",
                      fontSize: '16px'
                    }}
                  >
                    Welcome, you've been invited to join <strong>{localState.relationshipResourceDetails.name}</strong>.
                  </BodyText>
                </Column>
              </Row>
            </GreenCard>
          </Row>
        ) : undefined}

        <SignUpFormWrapper>
          <Form<SignUpDTO>
            data={signUpForm}
            initialValue={localState.formData}
            onSubmit={(value) => signup(value)}
            submitText="Sign up"
            error={localState.errorText}
            buttonWidth="100%"
            fieldSize="small"
            rowGapSize="0px"
            buttonSize="medium"
            disableSubmitButton={signUpDisabled}
          />

          <AuthFooter
            type="signup"
            shareAllVisionOrgId={
              partnerSupportsLinkViaRegisterFlow && orgId ? orgId : undefined
            }
            voucherPoolId={
              partnerSupportsLinkViaRegisterFlow && voucherPoolId
                ? voucherPoolId
                : undefined
            }
            promoCode={promoCode ?? undefined}
            partnerReferenceId={localState.partnerReferenceId}
            googleSignUpDisabled={signUpDisabled}
            relationshipInvitationId={relationshipInvitationId ?? undefined}
          />
        </SignUpFormWrapper>
      </SignUpWrapper>
    </Screen>
  );
}

export const SignUpWrapper = styled.div`
  flex-grow: 1;
  width: 800px;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 80px 0 102px 0;
`;

export const SignUpFormWrapper = styled.div`
  flex-grow: 1;
  width: 464px;
`;
