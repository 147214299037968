import { Form, FormData } from "../../models/form/form";
import { GetStartedDairy, GetStartedFarm, GetStartedFeedlot, GetStartedProcessor, GetStartedProperty } from "../../screens/get_started_screen";
import { useIntegrationsStore } from "../../state/integrations";
import { CheckBoxFormField, DropdownFormField, RadioBoxFormField, TextFormField } from "../../models/form/form_fields/basic_form_fields";
import { EnterpriseType } from "./get_started_enterprise";
import { DairyProductionSystemType, FeedlotSystemType, PropertyType } from "../../models/property_models";
import { useAuthStore } from "../../state/auth_store";
import { CountryCode } from "../../utilities/countries";
import { dairyProductionSystems } from "@/models/form_options";

import { HelpDrawerAndTab, HelpDrawerContentWrapper, HelpDrawerExplanatoryContentContainer, HelpDrawerExplanatoryContentText, HelpDrawerExplanatoryContentTitle, HelpDrawerVideoContainer } from "../HelpDrawer";
import { Accordion, AccordionItem } from "../Accordion";
import { HasAgriwebbIntegration } from "@/models/integrations";

type BaseFormData = {
    name: string;
    pic?: string;
}

export type FarmFormData = BaseFormData & {
    farmingType: string[];
    ngr?: string;
    agriwebbFarmId?: string;
}

export type FeedlotFormData = BaseFormData & {
    system: string;
}

export type ProcessorFormData = BaseFormData & {
    propertyType: PropertyType.Processor | PropertyType.FurtherProcessor;
    processingType: string[];
}

export type DairyFormData = BaseFormData & {
    dairyProductionSystem: DairyProductionSystemType
}

type GetStartedNameProps = {
    property: GetStartedProperty;
    setProperty: (property: Partial<GetStartedProperty>) => void;
    onSubmit: (value: Partial<GetStartedProperty>) => void;
    showMissingFields: boolean;
    setShowMissingFields: (show: boolean) => void;
};

export default function GetStartedName(props: GetStartedNameProps) {
    const integrationsStore = useIntegrationsStore();
    const user = useAuthStore((state) => state.user);

    const farmOptions = integrationsStore.integrationsRetrieved && integrationsStore.integrations?.agriwebb && integrationsStore.agriWebbFarms ? integrationsStore.agriWebbFarms?.map(farm => {
        return {
            name: farm.name,
            value: farm.id
        }
    }) : [];

    return (
        <>
            <GetStartedForm
                property={props.property}
                userCountryCode={user?.country as CountryCode ?? CountryCode.Australia}
                hasAgriWebbIntegration={integrationsStore.integrations?.agriwebb}
                options={farmOptions}
                onSubmit={async (value) => {
                    props.setProperty(value);
                    await props.onSubmit(value);
                }}
                showMissingFields={props.showMissingFields}
                setShowMissingFields={props.setShowMissingFields}
            />
            <HelpDrawerAndTab
                renderContent={() => {
                    return (
                        <HelpDrawerContentWrapper>
                            <HelpDrawerExplanatoryContentContainer>
                                <HelpDrawerExplanatoryContentTitle>Entering your Enterprise Details</HelpDrawerExplanatoryContentTitle>
                                <HelpDrawerExplanatoryContentText>
                                    Enter your topline enterprise details on this page - your property name, what you produce and your PIC code if you're a livestock producer, or your National Growers Register Code if you're a grain producer.
                                </HelpDrawerExplanatoryContentText>
                            </HelpDrawerExplanatoryContentContainer>
                            <HelpDrawerVideoContainer>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/iQlIvkUekT8?si=zMcFrP4J2MWDlItu&loop=1&rel=0" title="Ruminati Help" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </HelpDrawerVideoContainer>
                            <Accordion>
                                <AccordionItem
                                    renderTitle="Can multiple farms be entered into the one emissions report?"
                                    renderContent="Yes, you can calculate emissions for multiple farms as part of a single business with our Ruminati PRIME tool. While we generally suggest entering all your data under one ABN for simplicity, we’re also working on enhancing our tool to handle multiple PIC codes within one report, making it even easier to manage emissions across all your farms."
                                />
                            </Accordion>
                        </HelpDrawerContentWrapper>
                    )
                }}
            />
        </>
    );
}

type GetStartedFormProps = {
    property: GetStartedProperty,
    onSubmit: (value: Partial<GetStartedProperty>) => Promise<void>,
    userCountryCode?: CountryCode,
    hasAgriWebbIntegration: HasAgriwebbIntegration | undefined,
    options: any,
    showMissingFields: boolean;
    setShowMissingFields: (show: boolean) => void;
};

function GetStartedForm(props: GetStartedFormProps) {
    const displayProps: any = { buttonWidth: "228px", fieldSize: "small" };
    const submitText = 'Continue'
    if (props.property.type === EnterpriseType.Farm) {
        return <Form
            data={farmFormData(props.hasAgriWebbIntegration, props.options, props.userCountryCode)}
            initialValue={props.property}
            submitText={submitText}
            fieldSize="small"
            onSubmit={props.onSubmit}
            showMissingFields={props.showMissingFields}
            setShowMissingFields={props.setShowMissingFields}
            {...displayProps}
        />
    } else if (props.property.type === EnterpriseType.Feedlot) {
        return <Form
            data={feedlotFormData()}
            initialValue={props.property}
            submitText={submitText}
            fieldSize="small"
            onSubmit={props.onSubmit}
            showMissingFields={props.showMissingFields}
            setShowMissingFields={props.setShowMissingFields}
            {...displayProps}
        />
    } else if (props.property.type === EnterpriseType.ProcessingFacility) {
        return <Form
            data={processorFormData()}
            initialValue={props.property}
            submitText={submitText}
            fieldSize="small"
            onSubmit={props.onSubmit}
            showMissingFields={props.showMissingFields}
            setShowMissingFields={props.setShowMissingFields}
            {...displayProps}
        />
    } else if (props.property.type === EnterpriseType.Dairy) {
        return <Form
        data={dairyFormData()}
        initialValue={props.property}
        submitText={submitText}
        fieldSize="small"
        onSubmit={props.onSubmit}
        showMissingFields={props.showMissingFields}
        setShowMissingFields={props.setShowMissingFields}
        {...displayProps}
        />
    }

    throw new Error('[get_started_name.tsx] Unimplemented enterprise type')
}

export function farmFormDataAU(hasAgriWebbIntegration: HasAgriwebbIntegration | undefined, options: any): FormData<Partial<FarmFormData>, Partial<GetStartedFarm>> {
    return {
        fields: {
            name: new TextFormField({
                required: true,
                label: "Property Name",
                validator: (value) => value !== undefined && value.length > 0,
            }),
            farmingType: new CheckBoxFormField({
                required: true,
                placeholder: "What are you farming?",
                options: [
                    {
                        name: "Cattle",
                        value: "cattle",
                    },
                    {
                        name: "Sheep",
                        value: "sheep",
                    },
                    {
                        name: "Crops",
                        value: "grain",
                    },
                ],
            }),
            pic: new TextFormField({
                required: (state) =>
                    state.get["farmingType"] ? state.get["farmingType"].includes("cattle") ||
                        state.get["farmingType"].includes("sheep") : false,
                label: "Property Identification Code",
                validator: (value) => value !== undefined && value.length === 8,
                tip: "Your Property Identification Code (PIC) is an eight-character code allocated by the Department of Primary Industries (DPI) or an equivalent authority in each state or territory to identify a livestock-producing property. Producers must have a PIC to move livestock on and off a property – it forms the basis of Australia’s food safety and traceability programs.",
            }),
            ngr: new TextFormField({
                required: (state) => state.get["farmingType"] ? state.get["farmingType"].includes("grain") : false,
                label: "National Growers Register Code",
                validator: (value) => value !== undefined && value.length === 8,
                tip: "Your National Growers Register Code is an eight-character code that identifies your grain growing enterprise and allows you to interact more efficiently with grain handlers, traders & marketers.",
            }),
            agriwebbFarmId: new DropdownFormField({
                label: "Select a linked AgriWebb Farm to allow data pre-filling",
                options: options,
                tip: hasAgriWebbIntegration ? "Selecting an AgriWebb Farm enables Ruminati to pre-fill certain information like your property boundary and DSE figures." : "Enable the AgriWebb integration on your Account page which enables Ruminati to pre-fill a range of information for your property & reports.",
                disabled: !hasAgriWebbIntegration
            }),
        },
        transformer: {
            in: (value) => ({
                name: value.name,
                farmingType: [
                    ...value.cattleFarm ? ["cattle"] : [],
                    ...value.sheepFarm ? ["sheep"] : [],
                    ...value.grainFarm ? ["grain"] : [],
                ],
                agriwebbFarmId: value.agriwebbFarmId,
                ngr: value.ngr,
                pic: value.pic
            }),
            out: (value) => ({
                name: value.name,
                cattleFarm: value.farmingType?.includes("cattle"),
                sheepFarm: value.farmingType?.includes("sheep"),
                grainFarm: value.farmingType?.includes("grain"),
                ngr: value.ngr,
                pic: value.pic,
                agriwebbFarmId: value.agriwebbFarmId,
                stateId: undefined,
                geom: undefined,
                type: EnterpriseType.Farm
            })
        }
    }
};

export function farmFormDataNZ(): FormData<Partial<FarmFormData>, Partial<GetStartedFarm>> {
    return {
        fields: {
            name: new TextFormField({
                required: true,
                label: "Property Name",
            }),
            farmingType: new CheckBoxFormField({
                required: true,
                placeholder: "What are you farming?",
                options: [
                    {
                        name: "Cattle",
                        value: "cattle",
                    },
                    {
                        name: "Sheep",
                        value: "sheep",
                    },
                ],
            }),
        },
        transformer: {
            in: (value) => ({
                name: value.name,
                farmingType: [
                    ...value.cattleFarm ? ["cattle"] : [],
                    ...value.sheepFarm ? ["sheep"] : [],
                ],
            }),
            out: (value) => ({
                name: value.name,
                cattleFarm: value.farmingType?.includes("cattle"),
                sheepFarm: value.farmingType?.includes("sheep"),
                stateId: undefined,
                geom: undefined,
                type: EnterpriseType.Farm
            })
        }
    }
};

export function farmFormData(hasAgriWebbIntegration: HasAgriwebbIntegration | undefined, options: any, userCountryCode?: CountryCode): FormData<Partial<FarmFormData>, Partial<GetStartedFarm>> {
    switch (userCountryCode) {
        case CountryCode.NewZealand:
            return farmFormDataNZ();
        case CountryCode.Australia:
        default:
            return farmFormDataAU(hasAgriWebbIntegration, options);
    }
};

export function feedlotFormData(): FormData<Partial<FeedlotFormData>, Partial<GetStartedFeedlot>> {
    return {
        fields: {
            name: new TextFormField({
                required: true,
                label: "Property Name",
            }),
            system: new DropdownFormField({
                label: "Production System",
                options: [
                    { name: "Drylot", value: "drylot" },
                    { name: "Solid Storage", value: "solid-storage" },
                    { name: "Composting", value: "composting" },
                    { name: "Uncovered Anaerobic Lagoon", value: "anaerobic-lagoon" },
                ],
                required: true
            }),
            pic: new TextFormField({
                required: (state) =>
                    state.get["farmingType"] ? state.get["farmingType"].includes("cattle") ||
                        state.get["farmingType"].includes("sheep") : false,
                label: "Property Identification Code",
                validator: (value) => value !== undefined && value.length === 8,
                tip: "Your Property Identification Code (PIC) is an eight-character code allocated by the Department of Primary Industries (DPI) or an equivalent authority in each state or territory to identify a livestock-producing property. Producers must have a PIC to move livestock on and off a property – it forms the basis of Australia’s food safety and traceability programs.",
            }),
        },
        transformer: {
            in: (value) => ({
                name: value.name,
                system: value.productionSystem,
                pic: value.pic,
            }),
            out: (value) => ({
                name: value.name,
                productionSystem: value.system as (FeedlotSystemType | undefined),
                pic: value.pic,
                geom: undefined,
                type: EnterpriseType.Feedlot
            })
        }
    }
}

export function processorFormData(isUpdate?: boolean): FormData<Partial<ProcessorFormData>, Partial<GetStartedProcessor>> {
    return {
        fields: {
            name: new TextFormField({
                required: true,
                label: "Property Name",
            }),
            pic: new TextFormField({
                required: (state) =>
                    state.get["farmingType"] ? state.get["farmingType"].includes("cattle") ||
                        state.get["farmingType"].includes("sheep") : false,
                label: "Property Identification Code",
                validator: (value) => value !== undefined && value.length === 8,
                tip: "Your Property Identification Code (PIC) is an eight-character code allocated by the Department of Primary Industries (DPI) or an equivalent authority in each state or territory to identify a livestock-producing property. Producers must have a PIC to move livestock on and off a property – it forms the basis of Australia’s food safety and traceability programs.",
            }),
            ...isUpdate ? {} : {
                propertyType: new RadioBoxFormField({
                    label: "Processor Type",
                    required: true,
                    options: [{
                        name: "Abattoir & Primary Processor",
                        value: PropertyType.Processor
                    }, {
                        name: "Further Processor (Grinders)",
                        value: PropertyType.FurtherProcessor
                    }],
                }),
            },
            processingType: new CheckBoxFormField({
                required: true,
                placeholder: "Meat types processed",
                options: [
                    {
                        name: "Cattle",
                        value: "cattle",
                    },
                    {
                        name: "Sheep",
                        value: "sheep",
                    },
                ],
            }),
        },
        transformer: {
            in: (value) => ({
                name: value.name,
                propertyType: value.propertyType,
                processingType: [
                    ...value.beefProcessor ? ["cattle"] : [],
                    ...value.sheepProcessor ? ["sheep"] : [],
                ],
                pic: value.pic
            }),
            out: (value) => ({
                name: value.name,
                beefProcessor: value.processingType?.includes("cattle"),
                sheepProcessor: value.processingType?.includes("sheep"),
                geom: undefined,
                type: EnterpriseType.ProcessingFacility,
                propertyType: value.propertyType,
                pic: value.pic
            })
        }
    };
};

export function dairyFormData(_isUpdate?: boolean): FormData<Partial<DairyFormData>, Partial<GetStartedDairy>> {
    return {
        fields: {
            name: new TextFormField({
                required: true,
                label: "Property Name"
            }),
            pic: new TextFormField({
                required: true,
                label: "Property Identification Code",
                validator: (value) => value !== undefined && value.length === 8,
                tip: "Your Property Identification Code (PIC) is an eight-character code allocated by the Department of Primary Industries (DPI) or an equivalent authority in each state or territory to identify a livestock-producing property. Producers must have a PIC to move livestock on and off a property – it forms the basis of Australia’s food safety and traceability programs.",
            }),
            dairyProductionSystem: new DropdownFormField({
                label: "Primary Production System",
                options: dairyProductionSystems,
                required: true
            }),
        }
    };
};
