// A collection of "hard-coded" Roles that we make available in the app without a db request.
// TODO: consider whether these should just move into the Roles table in the db anyway... may depend on how we want to manage / update them in the future

import { type RoleT } from "@ruminati/types/role"

export const ROLE_ORG_ADMIN: RoleT = {
  id: "cbe69ecd-3b2c-460f-b66a-34bc3aa1a7b5",
  name: "Organisation Admin",
  description: "An Organisation Administrator can perform any action across an Organisation.",
  createdDate: new Date().toISOString(),
  resourcePermissions: {
    "Organisation": {
      permissions: [
        'create',
        'read',
        'update',
        'delete',
      ],
      "Relationship": {
        permissions: [
          'create',
          'read',
          'update',
          'delete',
        ]
      },
      "Organisation": {
        permissions: [
          'read'
        ]
      },
      "Property": {
        permissions: [
          'create',
          'read',
          'update',
          'delete',
        ],
        "Relationship": {
          permissions: [
            'create',
            'read',
            'update',
            'delete',
          ]
        },
        "Report": {
          permissions: [
            'create',
            'read',
            'update',
            'delete',
          ],
          "Relationship": {
            permissions: [
              'create',
              'read',
              'update',
              'delete',
            ]
          },
        }
      }
    }
  }
}

export const ROLE_REPORT_MANAGER_INTERNAL: RoleT = {
  id: "5c0108a9-d430-4f45-894e-f805486e320a",
  name: "Report Manager",
  description: "A Report Manager can create and edit reports for Enterprises in the Organisation.",
  createdDate: new Date().toISOString(),
  resourcePermissions: {
    "Organisation": {
      permissions: [
        'read',
      ],
      "Relationship": {
        permissions: [
          'read',
        ]
      },
      "Property": {
        permissions: [
          'read',
        ],
        "Report": {
          permissions: [
            'create',
            'read',
            'update',
            'delete',
          ],
          "Relationship": {
            permissions: [
              'create',
              'read',
              'update',
              'delete',
            ]
          },
        }
      }
    }
  }
}

export const ROLE_REPORT_MANAGER_EXTERNAL: RoleT = {
  id: "b72ae7c6-5b7c-446d-b6d3-23503209ccab",
  name: "Report Manager Partner",
  description: "A Report Manager can create and edit reports for Enterprises in the Organisation.",
  createdDate: new Date().toISOString(),
  resourcePermissions: {
    "Organisation": {
      permissions: [
        'read',
      ],
      "Property": {
        permissions: [
          'read',
        ],
        "Report": {
          permissions: [
            'create',
            'read',
            'update',
            'delete',
          ],
          "Relationship": {
            permissions: [
              'create',
              'read',
              'update',
              'delete',
            ]
          },
        }
      }
    }
  }
}

export const ROLE_REPORT_VIEWER_INTERNAL: RoleT = {
  id: "2c9afc54-f21f-4dbd-8d70-45a0e15ce54d",
  name: "Report Viewer",
  description: "A Report Viewer can view reports for Enterprises in the Organisation.",
  createdDate: new Date().toISOString(),
  resourcePermissions: {
    "Organisation": {
      permissions: [
        'read',
      ],
      "Relationship": {
        permissions: [
          'read',
        ]
      },
      "Property": {
        permissions: [
          'read',
        ],
        "Report": {
          permissions: [
            'read',
          ]
        }
      }
    }
  }
}

export const ROLE_REPORT_VIEWER_EXTERNAL: RoleT = {
  id: "8a40d7ef-9cb5-432b-a1f8-84c711690805",
  name: "Report Viewer Partner",
  description: "A Report Viewer can view reports for Enterprises in the Organisation.",
  createdDate: new Date().toISOString(),
  resourcePermissions: {
    "Organisation": {
      permissions: [
        'read',
      ],
      "Property": {
        permissions: [
          'read',
        ],
        "Report": {
          permissions: [
            'read',
          ]
        }
      }
    }
  }
}

export const ROLE_PARTNER_MANAGER: RoleT = {
  id: "5ae249d8-93fd-4dc4-94b8-38a95a99eac8",
  name: "Partner Manager",
  description: "A Partner Manager can manage reports for Enterprises in the Organisation, and also manage their own member's relationships to the Organisation.",
  createdDate: new Date().toISOString(),
  resourcePermissions: {
    "Organisation": {
      permissions: [
        'read',
      ],
      "Relationship": {
        permissions: [
          'create',
          'read',
          'update',
          'delete',
        ]
      },
      "Property": {
        permissions: [
          'read',
        ],
        "Report": {
          permissions: [
            'create',
            'read',
            'update',
            'delete',
          ],
          "Relationship": {
            permissions: [
              'read',
            ]
          }
        }
      }
    }
  }
}

export const ROLE_PARTNER_ORG_ADMIN: RoleT = {
  id: "21b715bf-574d-40fc-b4bd-83d8ee936b89",
  name: "Partner Organisation Admin",
  description: "An Partner Organisation Administrator can perform any action across the Partner Organisation, as well as actions concerning connected Farm Organisations.",
  createdDate: new Date().toISOString(),
  resourcePermissions: {
    "Organisation": {
      permissions: [
        'create',
        'read',
        'update',
        'delete',
      ],
      "Relationship": {
        permissions: [
          'create',
          'read',
          'update',
          'delete',
        ]
      },
      "Organisation": {
        permissions: [
          'read'
        ],
        // N.B. would ideally have a contextual rule on CRUD of connected Org Relationships to say that "only Relationships pertaining to Partner Org members are CRUD-able"... but for now, this is handled in relationships controller as hard-coded logic.
        "Relationship": {
          permissions: [
            'create',
            'read',
            'update',
            'delete',
          ]
        }
        // N.B. no need for a Partner Org admin to "look through" to other Farm Org resources, as each Partner Org member should get their own direct Relationship (with appropriate role / permissions) to the Farm Org.
      }
    }
  }
}

export const ROLE_PARTNER_ORG_MEMBER: RoleT = {
  id: "2cdf6c19-9e96-4a0f-b8c0-7a82045e9a82",
  name: "Partner Organisation Member",
  description: "An Partner Organisation Member can perform some actions on the Partner Organisation, as well as view resources from connected Farm Organisations.",
  createdDate: new Date().toISOString(),
  resourcePermissions: {
    "Organisation": {
      permissions: [
        'read',
      ],
      "Relationship": {
        permissions: [
          'read',
        ]
      },
      "Organisation": {
        permissions: [
          'read',
        ],
        "Relationship": {
          permissions: [
            'read',
          ]
        },
        // N.B. no need for a Partner Org member to "look through" to other Farm Org resources, as each Partner Org member should get their own direct Relationship (with appropriate role / permissions) to the Farm Org.
      }
    }
  }
}

export const ROLE_SUPPORT_USER: RoleT = {
  id: "9d2140be-e240-4713-9d12-6529f6ea1037",
  name: "Support User",
  description: "A Support User has system-wide permissions.",
  createdDate: new Date().toISOString(),
  resourcePermissions: {
    "Organisation": {
      permissions: [
        'create',
        'read',
        'update',
        'delete',
      ],
      "Relationship": {
        permissions: [
          'create',
          'read',
          'update',
          'delete',
        ]
      },
      "Organisation": {
        permissions: [
          'create',
          'read',
          'update',
          'delete',
        ],
        "Relationship": {
          permissions: [
            'create',
            'read',
            'update',
            'delete',
          ]
        },
        "Organisation": {
          permissions: [
            'create',
            'read',
            'update',
            'delete',
          ],
          "Relationship": {
            permissions: [
              'create',
              'read',
              'update',
              'delete',
            ]
          }
        },
        "Property": {
          permissions: [
            'create',
            'read',
            'update',
            'delete',
          ],
          "Relationship": {
            permissions: [
              'create',
              'read',
              'update',
              'delete',
            ]
          },
          "Report": {
            permissions: [
              'create',
              'read',
              'update',
              'delete',
            ],
            "Relationship": {
              permissions: [
                'create',
                'read',
                'update',
                'delete',
              ]
            },
          }
        }
      }
    }
  }
}

// property override roles
// N.B. on the client these appear as the same name as the org-level roles, for consistency of user understanding
export const ROLE_ORG_ADMIN_PROPERTY_OVERRIDE: RoleT = {
  id: "4967870e-b60a-46da-8196-518decf00d98",
  name: "Organisation Admin",
  description: "An Organisation Administrator can perform any action across an Organisation.",
  createdDate: new Date().toISOString(),
  resourcePermissions: {
    "Property": {
      permissions: [
        'create',
        'read',
        'update',
        'delete',
      ],
      "Relationship": {
        permissions: [
          'create',
          'read',
          'update',
          'delete',
        ]
      },
      "Report": {
        permissions: [
          'create',
          'read',
          'update',
          'delete',
        ],
        "Relationship": {
          permissions: [
            'create',
            'read',
            'update',
            'delete',
          ]
        }
      }
    }
  }
}

export const ROLE_REPORT_MANAGER_INTERNAL_PROPERTY_OVERRIDE: RoleT = {
  id: "348a3960-fac4-44f8-bad0-e17259cb2850",
  name: "Report Manager Internal",
  description: "A Report Manager can create and edit reports for Enterprises in the Organisation.",
  createdDate: new Date().toISOString(),
  resourcePermissions: {
    "Property": {
      permissions: [
        'read',
      ],
      "Report": {
        permissions: [
          'create',
          'read',
          'update',
          'delete',
        ],
        "Relationship": {
          permissions: [
            'create',
            'read',
            'update',
            'delete',
          ]
        },
      }
    }
  }
}

export const ROLE_REPORT_MANAGER_EXTERNAL_PROPERTY_OVERRIDE: RoleT = {
  id: "8b0d683f-c264-4f23-9f87-8d26aa5b61b6",
  name: "Report Manager Partner",
  description: "A Report Manager can create and edit reports for Enterprises in the Organisation.",
  createdDate: new Date().toISOString(),
  resourcePermissions: {
    "Property": {
      permissions: [
        'read',
      ],
      "Report": {
        permissions: [
          'create',
          'read',
          'update',
          'delete',
        ],
        "Relationship": {
          permissions: [
            'create',
            'read',
            'update',
            'delete',
          ]
        },
      }
    }
  }
}

export const ROLE_REPORT_VIEWER_INTERNAL_PROPERTY_OVERRIDE: RoleT = {
  id: "d05a0184-e272-4732-991d-0840f5c49355",
  name: "Report Viewer Internal",
  description: "A Report Viewer can view reports for Enterprises in the Organisation.",
  createdDate: new Date().toISOString(),
  resourcePermissions: {
    "Organisation": {
      permissions: [
        'read',
      ],
      "Relationship": {
        permissions: [
          'read',
        ]
      },
      "Property": {
        permissions: [
          'read',
        ],
        "Report": {
          permissions: [
            'read',
          ]
        }
      }
    }
  }
}

export const ROLE_REPORT_VIEWER_EXTERNAL_PROPERTY_OVERRIDE: RoleT = {
  id: "07651b9b-0701-4683-be2a-c6d8b0c64538",
  name: "Report Viewer External",
  description: "A Report Viewer can view reports for Enterprises in the Organisation.",
  createdDate: new Date().toISOString(),
  resourcePermissions: {
    "Property": {
      permissions: [
        'read',
      ],
      "Report": {
        permissions: [
          'read',
        ]
      }
    }
  }
}

export const ROLE_NO_ACCESS_PROPERTY_OVERRIDE: RoleT = {
  id: "b43e75f1-f335-4d19-b6dc-7de98528665e",
  name: "No Access",
  description: "This role prevents the user from viewing the Property.",
  createdDate: new Date().toISOString(),
  resourcePermissions: {
    "Property": {
      permissions: [
      ],
      "Report": {
        permissions: [
        ]
      }
    }
  }
}

// useful collections of roles for various uses
export const FARM_ORG_ROLES: Array<RoleT> = [
  ROLE_ORG_ADMIN,
  ROLE_REPORT_MANAGER_INTERNAL,
  ROLE_REPORT_MANAGER_EXTERNAL,
  ROLE_REPORT_VIEWER_INTERNAL,
  ROLE_REPORT_VIEWER_EXTERNAL,
]

export const INTERNAL_FARM_ORG_ROLES: Array<RoleT> = [
  ROLE_ORG_ADMIN,
  ROLE_REPORT_MANAGER_INTERNAL,
  ROLE_REPORT_VIEWER_INTERNAL,
]

export const EXTERNAL_FARM_ORG_ROLES: Array<RoleT> = [
  ROLE_PARTNER_MANAGER,
  ROLE_REPORT_MANAGER_EXTERNAL,
  ROLE_REPORT_VIEWER_EXTERNAL,
]

export const PROPERTY_OVERRIDE_ROLES: Array<RoleT> = [
  ROLE_ORG_ADMIN_PROPERTY_OVERRIDE,
  ROLE_REPORT_MANAGER_INTERNAL_PROPERTY_OVERRIDE,
  ROLE_REPORT_MANAGER_EXTERNAL_PROPERTY_OVERRIDE,
  ROLE_REPORT_VIEWER_INTERNAL_PROPERTY_OVERRIDE,
  ROLE_REPORT_VIEWER_EXTERNAL_PROPERTY_OVERRIDE,
  ROLE_NO_ACCESS_PROPERTY_OVERRIDE
]
export const PARTNER_ORG_ROLES: Array<RoleT> = [
  ROLE_PARTNER_ORG_ADMIN,
  ROLE_PARTNER_ORG_MEMBER
]
export const SUPPORT_USER_ROLES: Array<RoleT> = [
  ROLE_SUPPORT_USER
]

export const ROLES: Array<RoleT> = [
  ...FARM_ORG_ROLES,
  ...PROPERTY_OVERRIDE_ROLES,
  ...PARTNER_ORG_ROLES,
  ...SUPPORT_USER_ROLES
]
