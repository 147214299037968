import { Type, Static } from '@sinclair/typebox'

export const DataRequestStandardTB = Type.Literal('standard')
export const DataRequestAdvancedTB = Type.Literal('advanced')

export const DataRequestTB = Type.Union([
  DataRequestStandardTB,
  DataRequestAdvancedTB
])
export type DataRequestT = Static<typeof DataRequestTB>

export const VisionOrgPreferencesTB = Type.Object({
  useClientReferenceId: Type.Optional(Type.Boolean()),
  showExportOnDashboard: Type.Optional(Type.Boolean()),
  supportLinkViaRegisterFlow: Type.Optional(Type.Boolean()),
  requireClientReferenceIdDuringRegister: Type.Optional(Type.Boolean()),
  dataRequestType: Type.Optional(DataRequestTB),
})
export type VisionOrgPreferencesT = Static<typeof VisionOrgPreferencesTB>

export const VisionOrganisationDTOTB = Type.Object({
  id: Type.String(),
  name: Type.String(),
  logo_url: Type.Optional(Type.String()),
  icon_small_url: Type.Optional(Type.String()),
  theme: Type.Optional(Type.Any()), // TODO: what is TB representation of "JSON" type?
  sharedInvitationOrgIds: Type.Optional(Type.Array(Type.String())),
  preferences: Type.Optional(VisionOrgPreferencesTB)
})
export type VisionOrganisationDTOT = Static<typeof VisionOrganisationDTOTB>

export const VisionOrganisationCreateTB = Type.Omit(VisionOrganisationDTOTB, [
  'id',
  'preferences'
])
export type VisionOrganisationCreateDTOT = Static<typeof VisionOrganisationCreateTB>

export const VisionOrganisationSummaryTB = Type.Object({
  id: Type.String(),
  name: Type.String(),
  logo_url: Type.Optional(Type.String()),
  icon_small_url: Type.Optional(Type.String())
})
export type VisionOrganisationSummaryT = Static<typeof VisionOrganisationSummaryTB>