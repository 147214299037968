import { usePopupStore } from "@/state/popup_store"
import { HerbicideOrPesticideUse, ReportGrains_Post3_3Form, ReportPastureForm } from "@/models/report"
import { State } from "@/models/form/form_fields/form_field"
import SmallButton from "./small_button"
import Icon from "../icon"
import { ruminatiColors } from "@/utilities/colors"
import { calculateHerbicideActiveIngredients } from "@/utilities/chemicals"
import { HerbicidePopupId } from "../popup/herbicide-popup"

export default function HerbicideCalculator({
  state
}: {
  state: State<ReportPastureForm | ReportGrains_Post3_3Form>
}) {
  const popupStore = usePopupStore()

  const herbCount = state.get.herbicidesPesticidesUsed?.length ?? 0

  return <SmallButton
    colorScheme='green'
    onClick={() => {
      popupStore.addPopup(
        HerbicidePopupId,
        (herbicidesPesticidesUsed: HerbicideOrPesticideUse[]) => {
          const resultActiveIngredients = calculateHerbicideActiveIngredients(herbicidesPesticidesUsed)
          state.set({
              ...state.get,
              herbicidesPesticidesUsed: herbicidesPesticidesUsed,
              herbicide: resultActiveIngredients.herbicide,

              // Note Pasture Form and Grain form name these next 2 things
              // differently but they are in effect the same              
              herbicidePesticide: resultActiveIngredients.herbicidePesticide,
              herbicidePesticideApplied: resultActiveIngredients.herbicidePesticide
          })
        },
        {
          herbicidesPesticidesUsed: state.get.herbicidesPesticidesUsed
        }
      )
    }}
  >
    <span style={{marginRight: '8px'}}>
      <Icon icon="plus-small" fillColor={ruminatiColors.bone}/>
    </span>
    {herbCount > 0 ? 'Edit Herbicides & Pesticides' : 'Add Herbicides & Pesticides'}
  </SmallButton>
}