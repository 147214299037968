import { Type, Static } from '@sinclair/typebox'

export type ReportBase = {
  id: string
  propertyId: string
  financialYear: number
  algorithmVersion: number
  createdDate: number

  complete: boolean
  resultsReadyForGeneration: boolean

  // TO DO: Improve this so maybe it's a generic?
  completedSections: string[]
  completionDate: null | number

  // TO DO: Add support for results
  // results?: ReportResults
}

export const ReportTB = Type.Object({
  id: Type.String(), // uuid
  uid: Type.String(), // user.id
  propertyId: Type.String(), // uuid
  algorithmVersion: Type.Number(),
  createdDate: Type.Number(), // timestamp TODO: should this be a Date like other createdDates in entities?
  deletedAt: Type.Optional(Type.Date()), // TODO: not nullable in db, but what type is returned?
  financialYear: Type.Number(),
  complete: Type.Boolean(),
  completionDate: Type.Optional(Type.Number()), // timestamp TODO: should this be a Date like other createdDates in entities? N.B. entity def allows for null, but optional rather than nullable in application code
  completedSections: Type.Array(Type.String()), // Set
})
export type ReportT = Static<typeof ReportTB>

export const ReportCreateTB = Type.Omit(ReportTB, [
  'id',
  'createdDate',
  'deletedAt',
  'complete',
  'completionDate',
  'completedSections',
  'algorithmVersion',
  'uid'
])
export type ReportCreateT = Static<typeof ReportCreateTB>
