import { MultiPolygon } from "geojson";
import { FieldSize, FormFieldRenderOptions, InputFormField } from "./form_field";
import { formatReportNumber } from "../../../utilities/functions";
import { TextField } from "../../../components/form/input_field";
import calculateArea from '@turf/area'
import { convertArea } from '@turf/helpers'

export class MultiPolygonFormField extends InputFormField<MultiPolygon> {
    displayValue = (value?: MultiPolygon): string | undefined => {
        if (value) {
            let area = calculateArea(value);
            if (area) area = convertArea(area, 'meters', 'hectares');
            return formatReportNumber(area, { maxDecimalPlaces: 2 });
        }
    }

    render(options: FormFieldRenderOptions<MultiPolygon>): JSX.Element {
        return (
            <TextField
                id={options.id}
                disabled={true}
                value={this.displayValue(options.value)}
                error={options.error}
                placeholder="0"
                onChange={(value) => options.onChange(this.transform(value))}
                label={this.label(options.state)}
                units={this.unit(options.state)}
                tip={this.tip}
                width={FieldSize.toWidth(this.size)}
                size={options.size}
                obscure={this.obscured}
                footerElement={this.children(options.state)}
            />
        );
    }
}