import { Table } from 'antd';
import { createGlobalStyle } from 'styled-components';

// import { useState } from 'react';

import { AllocatedSummary, ReportResults, DairyAlgorithmOutput, FullGrainsResults, FullSheepOrBeefResults, pluckScope2, pluckScope1s, pluckScope3s, FeedlotAlgorithmOutput } from "../../models/property_models";
import { ruminatiColors } from "../../utilities/colors";
import { Column } from "../../components/styled_layout";
import { Property } from "../../models/property_models";
import { Row } from "../../components/styled_layout";
import { BodyText } from "../../components/styled_text";
import Icon, { IconType } from '../../components/icon';
import { FaintCard, InfoCard } from '../../components/card';
import EmissionUnit from '../../models/emission-unit';
import { formatReportNumber } from '../../utilities/functions';
import { ReportGrains, ReportGrains_Post3_3 } from '../../models/report';
import { cropTypes } from '../../models/form_options';
import { EmissionsCategory, getCategoryTotals } from '../../models/property_models';
import { calculatePercentage } from '../../utilities/maths';
import { ruminatiImages } from '../../utilities/images';
// import CollapsableWrapper from '../../components/collapsable_wrapper';

export enum EmissionsGas {
  CO2,
  N20,
  CH4
}

export type ScopeData = {
  label: string
  value: string
  gas?: EmissionsGas
  category?: EmissionsCategory
}

export default function ReportDetailsTab(props: {
  property: Property
  resultsSummary: ReportResults,
  allocatedProductSummary: AllocatedSummary | undefined,
  isPrime: boolean,
  isInPrint: boolean,
  grains: (ReportGrains | ReportGrains_Post3_3)[] | undefined
}) {
  type Product = {
    key: string
    product: string
    emissions: string
    intensity: string,
    breakdown: FullGrainsResults | FullSheepOrBeefResults | DairyAlgorithmOutput | FeedlotAlgorithmOutput
  }

  const products: Product[] = []
  if (props.allocatedProductSummary?.beef && props.resultsSummary.intensity.kgCo2PerKgBeefMeatSold) {
    products.push({
      key: 'beef',
      product: 'Beef Meat',
      emissions: `${formatReportNumber(props.allocatedProductSummary.beef.totalEmissions)} ${EmissionUnit.Total}`,
      intensity: `${formatReportNumber(props.resultsSummary.intensity.kgCo2PerKgBeefMeatSold)} ${EmissionUnit.IntensityLivestock}`,
      breakdown: props.allocatedProductSummary.beef.breakdown
    })
  }

  if (props.allocatedProductSummary?.sheep && props.resultsSummary.intensity.kgCo2PerKgSheepMeatSold) {
    products.push({
      key: 'sheep',
      product: 'Sheep Meat',
      emissions: `${formatReportNumber(props.allocatedProductSummary.sheep.totalEmissions)} ${EmissionUnit.Total}`,
      intensity: `${formatReportNumber(props.resultsSummary.intensity.kgCo2PerKgSheepMeatSold)} ${EmissionUnit.IntensityLivestock}`,
      breakdown: props.allocatedProductSummary.sheep.breakdown
    })
  }

  if (props.allocatedProductSummary?.grains && props.grains) {
    props.allocatedProductSummary.grains.forEach((g, i) => {
      const stableId = g.grainRecordStableId
      const matchingGrain = props.grains?.find(g => g.stableId === stableId)
      let name = `Grain ${i}`

      if (matchingGrain) {
        const cropType = cropTypes.find(ct => ct.value === matchingGrain.cropType)
        if (matchingGrain.name && cropType) name = `${cropType.name} - ${matchingGrain.name}`
        else if (cropType && !matchingGrain.name) name = `${cropType.name}`
      }

      products.push({
        key: `grain_${i}`,
        product: name,
        emissions: `${formatReportNumber(g.totalEmissions)} ${EmissionUnit.Total}`,
        intensity: `${formatReportNumber(g.intensity)} ${EmissionUnit.IntensityGrain}`,
        breakdown: g.breakdown
      })
    })
  }

  if (props.allocatedProductSummary?.dairyMilk && props.resultsSummary.intensity.kgCo2PerKgMilk) {
    products.push({
      key: 'dairyMilk',
      product: 'Milk',
      emissions: `${formatReportNumber(props.allocatedProductSummary.dairyMilk.totalEmissions)} ${EmissionUnit.Total}`,
      intensity: `${formatReportNumber(props.resultsSummary.intensity.kgCo2PerKgMilk)} ${EmissionUnit.DairyIntensityMilkSolids}`,
      breakdown: props.allocatedProductSummary.dairyMilk.breakdown
    })
  }

  if (props.allocatedProductSummary?.dairyMeat && props.resultsSummary.intensity.kgCo2PerKgDairyMeat) {
    products.push({
      key: 'dairyMeat',
      product: 'Dairy Meat',
      emissions: `${formatReportNumber(props.allocatedProductSummary.dairyMeat.totalEmissions)} ${EmissionUnit.Total}`,
      intensity: `${formatReportNumber(props.resultsSummary.intensity.kgCo2PerKgDairyMeat)} ${EmissionUnit.DairyIntensityLW}`,
      breakdown: props.allocatedProductSummary.dairyMeat.breakdown
    })
  }

  if (props.allocatedProductSummary?.feedlotBeef && props.resultsSummary.intensity.kgCo2PerKgMeatFed) {
    products.push({
      key: 'feedlotBeef',
      product: 'Feedlot - Beef',
      emissions: `${formatReportNumber(props.allocatedProductSummary.feedlotBeef.totalEmissions)} ${EmissionUnit.Total}`,
      intensity: `${formatReportNumber(props.resultsSummary.intensity.kgCo2PerKgMeatFed)} ${EmissionUnit.IntensityLivestock}`,
      breakdown: props.allocatedProductSummary?.feedlotBeef.breakdown
    })
  }

  if (props.allocatedProductSummary === undefined) {
    const titleAndContent = props.isPrime ? {
      title: "Detailed breakdown not available for this report",
      content: "Accessing detailed summary information requires a newer form of report within Ruminati. Next time you create a report detailed information will be available."
    } : {
      title: "Not Available",
      content: "Upgrade to Ruminati PRIME to access"
    }
    return (
      <Row style={{marginTop: '40px', width: '100%', justifyContent: 'flex-start'}}>
        <Column style={{width:"100%"}}>
          <InfoCard
          padding="24px"
          content={
            <>
              <BodyText style={{fontWeight: 600, fontSize: '16px', paddingTop: '6px'}}>{titleAndContent.title}</BodyText>
              <BodyText style={{marginTop: '20px', lineHeight: '22px', fontSize: '15px'}}>
                {titleAndContent.content}
              </BodyText>
            </>
          }/>
          <Row style={{marginTop: "40px"}}>
            <img src={ruminatiImages.feeding} alt="cows feeding" width="400px"/>
          </Row>
        </Column>
      </Row>
    )
  }

  function renderGasRow (gas: EmissionsGas, label: string, data: ScopeData[]) {
    return <Row style={{
      justifyContent: 'flex-start',
      width: '100%',
      marginTop: '10px',
      alignItems: 'flex-start'
    }}>
      <Column>
        <BodyText style={{marginRight: '20px',lineHeight: '20px', fontWeight: 600}}>{label}:</BodyText>
      </Column>
      <Column>
        <Row style={{flexWrap: 'wrap', justifyContent: 'flex-start'}}>
          {renderRowOfValues(data.filter(sd => sd.gas === gas))}
        </Row>
      </Column>
    </Row>
  }

  function renderRowOfValues (values: ScopeData[]) {
    return values.map((sd, idx) => {
      return <Row key={idx}>
        <BodyText style={{marginRight: '20px',lineHeight: '20px'}}>{sd.label}: {sd.value}</BodyText>
      </Row>
    })
  }

  function makeScopeContent (scopeTitle: string, scopeData: ScopeData[], marginBottom: string, useGases: boolean) {
    return (
      <Row style={{justifyContent: 'flex-start', width: '100%', marginBottom}}>
        <Column style={{width: '100%'}}>
          <Row style={{
            justifyContent: 'flex-start',
            width: '100%',
            borderBottom: `3px solid ${ruminatiColors.green_3_5}`,
            paddingBottom: '10px'
          }}>
            <BodyText style={{fontWeight: 600}}>
              {scopeTitle}
            </BodyText>
          </Row>

          {useGases ?
            <>
              {renderGasRow(EmissionsGas.CO2, 'CO2', scopeData)}
              {renderGasRow(EmissionsGas.CH4, 'CH4', scopeData)}
              {renderGasRow(EmissionsGas.N20, 'N20', scopeData)}
            </>
          : <Row style={{
            justifyContent: 'flex-start',
            width: '100%',
            marginTop: '10px',
            flexWrap: 'wrap',
          }}>
            {renderRowOfValues(scopeData)}
          </Row>}
        </Column>
    </Row>)
  }

  function makeIconAndPercentage (allocatedProductSummary: AllocatedSummary, category: EmissionsCategory, label: string, icon?: IconType, ) {
      const categoryTotal = getCategoryTotals(allocatedProductSummary, category)
      const asPercentage = calculatePercentage(categoryTotal, props.resultsSummary.totals.total)
      return (<Column style={{fontSize: '3em'}}>
        <BodyText style={{fontFamily: 'Approach', marginBottom: '15px', fontSize: '24px', fontWeight: 500}}>
          {formatReportNumber(asPercentage, {
            maxDecimalSigFig: 0
          })}%
        </BodyText>
        <Icon icon={icon} />
        <BodyText style={{marginTop: '5px'}}>{label}</BodyText>
      </Column>)
  }

  return (
    <>
    <Row style={{
      marginTop: '40px',
      marginBottom: '20px',
      width: '100%'
    }}>
      <FaintCard>
        <Row style={{marginBottom: '30px', paddingBottom: '20px', borderBottom: `1px solid ${ruminatiColors.green_3_30}`}}>
          <BodyText style={{fontSize: '18px', fontFamily: 'Approach'}}>Where did my Emissions come from?</BodyText>
        </Row>
        <Row style={{
          justifyContent: 'space-around',
          width: '100%'
        }}>
          {makeIconAndPercentage(props.allocatedProductSummary, EmissionsCategory.Electricity, 'Electricity', 'electricity')}
          {makeIconAndPercentage(props.allocatedProductSummary, EmissionsCategory.Fuels, 'Fuels', 'fuels')}
          {makeIconAndPercentage(props.allocatedProductSummary, EmissionsCategory.Livestock, 'Livestock', 'livestock')}
          {makeIconAndPercentage(props.allocatedProductSummary, EmissionsCategory.Fertilisers, 'Fertilisers', 'pasture')}
          {makeIconAndPercentage(props.allocatedProductSummary, EmissionsCategory.Other, 'Other', 'other-emissions')}
        </Row>
      </FaintCard>
    </Row>

    {props.isPrime && (
      <Row style={{marginTop: '40px', width: '100%'}}>
        <DetailedReportTableStyle/>
        <Table
          id="detailedReportTable"
          pagination={false}
          style={{
            width: '100%'
          }}
          columns={[
            { title: 'Product', key: 'product', dataIndex: 'product', width: '40%' },
            { title: 'Emissions', key: 'emissions',  dataIndex: 'emissions', align: 'right', width: '30%' },
            { title: 'Intensity ', key: 'intensity',  dataIndex: 'intensity', align: 'right', width: '30%' },
            Table.EXPAND_COLUMN
          ]}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <Row style={{justifyContent: 'flex-start', width: '100%'}}>
                      <Column style={{width: '100%', padding: ' 20px 48px'}}>
                        {makeScopeContent('Scope 1: On-farm', pluckScope1s(record.breakdown), '40px', true)}
                        {makeScopeContent('Scope 2: Off-farm', pluckScope2(record.breakdown), '40px', false)}
                        {makeScopeContent('Scope 3: Pre-farm', pluckScope3s(record.breakdown), '0px', false)}
                      </Column>
                </Row>
              )
            },
            expandIcon: ({ expanded, onExpand, record }) =>
              <div
                onClick={e => onExpand(record, e)}
                style={{
                  transform: expanded ? "translateY(3px)" : "rotate(180deg) translateY(1px)",
                  transition: "0.3s ease",
                  cursor: 'pointer'
                }}>
                <Icon icon="chevronDown" fillColor={ruminatiColors.green_3} />
              </div>
          }}
          dataSource={products}
        />
      </Row>
    )}
    </>)
}

const DetailedReportTableStyle = createGlobalStyle`

  #reportInnerContent {
  
    .ant-collapse-header {
      font-size: 16px;
      font-weight: 500;
      padding: 12px 24px;
      line-height: 32px;
      letter-spacing: 0.02em;
      align-items: center;
    }

    .ant-collapse-content-box {
      padding: 0px;
    }

    .ant-collapse-borderless .ant-collapse-header {
      padding: 4px 0px;
      border-bottom: 1px solid rgba(69, 95, 76, 0.3);
    }

    .ant-collapse-borderless .ant-collapse-content-box {
      padding-top: 14px;
      padding-bottom: 10px;
    }
  }

  #reportInnerContent .ant-table-wrapper {
    border: 1px solid #455F4C;
    border-radius: 8px;
  }

  #detailedReportTable {

    .ant-table-wrapper .ant-table-tbody > tr > td {
      border-bottom: 1px solid rgb(89, 111, 91);
      padding: 24px 16px;
    }

    .ant-table-wrapper .ant-table-tbody > tr:last-child > td {
      border-bottom: 0px solid transparent;
    }

    .ant-table-thead > tr > th {
      padding: 24px 16px;
      background-color: rgba(89, 111, 91, 0.05);
      border-bottom: 1px solid rgb(89, 111, 91);
    }

    tr.ant-table-expanded-row > td {
      background-color: rgba(89, 111, 91, 0.02);
    }
  }
`


