import { Type, Static } from '@sinclair/typebox'
import { PropertySummaryTB } from './property'
import { VisionOrganisationSummaryTB } from './vision-organisation'

export const DataRequestTypeStandardTB = Type.Literal('standard')
export const DataRequestTypeAdvancedTB = Type.Literal('advanced')

export const DataRequestTypeTB = Type.Union([
  DataRequestTypeStandardTB,
  DataRequestTypeAdvancedTB
], { default: DataRequestTypeStandardTB })
export type DataRequestTypeT = Static<typeof DataRequestTypeTB>

export const LinkedFarmStatusTypeSharedTB = Type.Literal('shared')
export const LinkedFarmStatusTypeAcceptedTB = Type.Literal('accepted')
export const LinkedFarmStatusTypeUnlinkedByOrganisationTB = Type.Literal('unlinked_by_org')
export const LinkedFarmStatusTypeUnlinkedByOwnerTB = Type.Literal('unlinked_by_owner')
export const LinkedFarmStatusTypeRemovedByOrganisationTB = Type.Literal('removed_by_org')

export const LinkedFarmStatusTypeTB = Type.Union([
  LinkedFarmStatusTypeSharedTB,
  LinkedFarmStatusTypeAcceptedTB,
  LinkedFarmStatusTypeUnlinkedByOrganisationTB,
  LinkedFarmStatusTypeUnlinkedByOwnerTB,
  LinkedFarmStatusTypeRemovedByOrganisationTB
], { default: LinkedFarmStatusTypeSharedTB })
export type LinkedFarmStatusTypeT = Static<typeof LinkedFarmStatusTypeTB>

export const VisionLinkedFarmTB = Type.Object({
  id: Type.String(), // uuid
  visionOrgId: Type.String(),
  propertyId: Type.String(),
  partnershipRequestSent: Type.String(),
  partnershipRequestAccepted: Type.Optional(Type.String()), // N.B. entity def allows for null, but optional rather than nullable in application code
  status: LinkedFarmStatusTypeTB,
  dateUnlinked: Type.Optional(Type.String()), // N.B. entity def allows for null, but optional rather than nullable in application code
  shareType: DataRequestTypeTB,
  sharedByUserId: Type.String()
})
export type VisionLinkedFarmT = Static<typeof VisionLinkedFarmTB>

export const VisionLinkedFarmVisionClientTB = Type.Object({
  linkedFarm: VisionLinkedFarmTB,
  property: PropertySummaryTB
})
export type VisionLinkedFarmVisionClientT = Static<typeof VisionLinkedFarmVisionClientTB>

export const VisionLinkedFarmFarmClientTB = Type.Object({
  linkedFarm: VisionLinkedFarmTB,
  visionOrganisation: VisionOrganisationSummaryTB
})
export type VisionLinkedFarmFarmClientT = Static<typeof VisionLinkedFarmFarmClientTB>