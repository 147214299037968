import { initializeApp } from "firebase/app";
import { getAuth, Auth, OAuthProvider } from "firebase/auth";

export const microsoftProvider = new OAuthProvider('microsoft.com');
microsoftProvider.setCustomParameters({
  prompt: 'login'
})

export function useFirebaseIdentityPlatform (identityPlatformApiKey: string | null): null | Auth {
  if (identityPlatformApiKey === null) return null
  const config = {
    apiKey: identityPlatformApiKey,
    authDomain: import.meta.env.REACT_APP_AUTH_DOMAIN,
  }
  const app = initializeApp(config)
  const auth = getAuth(app)
  return auth
}