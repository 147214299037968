import styled from "styled-components";
import { Button, ConfigProvider, notification, type NotificationArgsProps } from "antd";

import { BaseText, BodyText } from "@/components/styled_text";

import { ruminatiColors } from "./colors";
import { ANT_DESIGN_THEME } from "./ant-design-theme";

interface OpenNotificationError {
  heading: string;
  message: string | JSX.Element;
  cause?: string;
  opts?: Partial<NotificationArgsProps>;
}

// N.B. using the "deprecated" notification API here
export function openNotificationError ({
  heading,
  message,
  cause,
  opts
}: OpenNotificationError) {
  notification.config({ top: 100 })

  notification.open({
    message: (
      <NotificationHeadingText>{heading}</NotificationHeadingText>
    ),
    description: <>
      {typeof message === 'string' ? 
        <NotificationDescriptionText>{message}</NotificationDescriptionText>
        : message
      }
      {cause && <><br/><NotificationDescriptionText><strong>Cause:</strong> {cause}</NotificationDescriptionText></>}
    </>,
    placement: 'topRight',
    duration: 0,
    type: 'error',
    style: {
      backgroundColor: ruminatiColors.bone,
      borderRadius: '8px'
    },
    ...opts
  })
}

export function agriwebbErrorNotification (callback: Function) {
  const key = 'AgriwebbConnectionError'

  return openNotificationError({
    heading: 'AgriWebb Connection Error',
    message: <>
      <NotificationDescriptionText style={{
        marginBottom: "12px"
      }}>
        Your connection with AgriWebb has expired because it's been a while since you've used it.
      </NotificationDescriptionText>
      <ConfigProvider theme={ANT_DESIGN_THEME}>
        <Button type="primary" onClick={() => {
          callback()
          notification.destroy(key)
        }}>
          Reconnect
        </Button>
      </ConfigProvider>
    </>,
    opts: {
      key
    },
  })
}

// TODO: add more defaults

const NotificationHeadingText = styled(BaseText)`
  font-family: "Approach", sans-serif;
  color: ${ruminatiColors.effective_black};
  font-weight: 600;
  text-align: left;
`;

const NotificationDescriptionText = styled(BodyText)`
  color: ${ruminatiColors.effective_black};
  line-height: 1.4;
`;