import { VisionLinkedFarmFarmClientT } from "@ruminati/types/vision_linked_farm";
import { VisionInvitationFarmClientT } from "@ruminati/types/vision_invitation";
import { Property } from "@/models/property_models";

export const apiUrl = import.meta.env.REACT_APP_API_BASE_URL;

export function getUnusedInvitations (invitations: VisionInvitationFarmClientT[], linkedFarms: VisionLinkedFarmFarmClientT[], properties: Property[]): VisionInvitationFarmClientT[] {
  if (properties.length === 0 && invitations.length > 0) return []
  return invitations.filter(invite => linkedFarms.find(lf => lf.linkedFarm.visionOrgId === invite.invite.visionOrgId) === undefined)
}

export function composeIconUrl(url: string): string {
  if (url.includes('https://')) return url
  return `${apiUrl}${url}`
}