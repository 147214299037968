import OutlineButton from "./outline_button";
import { ruminatiColors } from '../../utilities/colors';

type CardActionButtonProps = {
  label: string;
  colorScheme?: 'red' | 'green'
  width?: string;
  onClick: () => void;
  disabled?: boolean
  disabledMessage?: string
};

/**
 * A button which shows the tools to calculate area popup on click
 */
export default function CardActionButton(props: CardActionButtonProps) {
  const color = props.colorScheme === 'red' ? ruminatiColors.red : ruminatiColors.dark_green

  return (
      <OutlineButton
        activeColor={color}
        textColor={color}
        width={props.width ?? '100px'} 
        height={'40px'}
        fontSize="14px"
        disabled={props.disabled}
        disabledMessage={props.disabledMessage}
        onClick={() =>
          props.onClick()
        }
      >
          {props.label}
      </OutlineButton>
    );
}
