import { useEffect, PropsWithChildren } from 'react';
import LoadingPlaceholder from '../components/loading_placeholder';
import Screen from '../screens/screen'
import { getCurrentUserIntegrations } from '../services/integrations_service';
import { useIntegrationsStore } from "../state/integrations";

/**
 * Gets the users integrations if they have not been loaded 
 */
export default function IntegrationsGuard({
    children,
}: PropsWithChildren<unknown>) {
    const integrationsRetrieved = useIntegrationsStore((state) => state.integrationsRetrieved);
    const setIntegrationRetrieved = useIntegrationsStore((state) => state.setIntegrationRetrieved)
    const setIntegrations = useIntegrationsStore((state) => state.setIntegrations)

    // Fetch the users integrations if not loaded yet
    useEffect(() => {
        if (integrationsRetrieved === false) {
            getCurrentUserIntegrations()
                .then((usersIntegrations) => {
                    if (usersIntegrations) setIntegrations(usersIntegrations)
                    setIntegrationRetrieved(true)
                })
                .catch((err) => {
                    setIntegrationRetrieved(true)
                    console.log(err)
                });
        }
    }, [integrationsRetrieved, setIntegrationRetrieved, setIntegrations]);

    if (!integrationsRetrieved) {
        return <Screen>
            <LoadingPlaceholder />
        </Screen>
    } else {
        return children
    }
}
