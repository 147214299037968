import styled from "styled-components"
import { useState } from "react"
import { VisionInvitationFarmClientT } from "@ruminati/types/vision_invitation";

import { InfoCard } from "../card"
import { Row, Column } from "../styled_layout"
import { BodyText, TextLink, ParagraphText } from "../styled_text"
import { ruminatiColors } from "@/utilities/colors"
import { usePopupStore } from "@/state/popup_store"
import { CorporatePartnerPopupId } from "@/screens/corporate_partners/corporate_partners"
import CheckBoxGroup from "../form/check_box"
import { DataRequestAdvancedTB } from "@ruminati/types/vision-organisation";

export default function SharingPermissionsCard (props: {
  invite: VisionInvitationFarmClientT,
  usingVoucher: boolean,
  checkboxChanged: (checked: boolean) => void
}) {
  const [checkboxOptions, setCheckboxOptions] = useState<number[]>([]);
  const popupStore = usePopupStore()

  const visionOrgName = props.invite.visionOrganisation.name

  return <InfoCard content={
    <Row style={{paddingLeft: '20px'}}>
        <Column>
            <BodyText style={{lineHeight: '24px', textAlign: 'center'}}>
                You're about to share summary information about your enterprise, emissions reports results and reduction plans.
                <br/>
                <TextLink 
                onClick={() => popupStore.addPopup(CorporatePartnerPopupId.ShareDetails, undefined, {
                    isAdvancedShare: props.invite.invite.dataSharingType === DataRequestAdvancedTB.const
                })}
                style={{ color: ruminatiColors.dark_green }}
                >
                    View detailed information on what you'll be sharing
                </TextLink>
            </BodyText>

            <ScrollablePermissionsBody>
                <PermissionsText style={{marginBottom: '10px'}}>
                    {visionOrgName} is requesting consent to access some of your data to enable them to better understand and estimate emissions levels and mitigation action across their supply chain.
                </PermissionsText>
                <PermissionsText style={{marginBottom: '10px'}}>
                    By granting your consent, you are agreeing to {visionOrgName} being provided with access to view these specific datapoints on the Ruminati platform and aggregate them with other data points to estimate their emissions profile.
                </PermissionsText>
                <PermissionsText style={{marginBottom: '10px'}}>
                    This consent does not provide {visionOrgName} with the authority to share your information directly with any other parties (except in aggregated form), or attribute the information directly to your enterprise in their communication with other parties.
                </PermissionsText>
                <PermissionsText>
                    At any time {props.usingVoucher ? 'after completion of the voucher period ' : undefined}you have the ability to withdraw this consent, or review which entities and specific data you have provided your consent to, via the Corporate Partners page in your Ruminati profile. After you withdraw consent {visionOrgName} will still retain access to the datapoints that were shared during the time of the connection, however no new data you create will be shared with them.
                </PermissionsText>
            </ScrollablePermissionsBody>
            
            <CheckBoxGroup
                name={"accept"}
                initialValue={checkboxOptions}
                size="14px"
                options={[
                    {
                        name: 'I accept and understand what I am sharing',
                        value: 1
                    }
                ]}
                onChange={(v) => {
                    if (v === undefined) return
                    setCheckboxOptions(v)
                    if (v.length === 0) return props.checkboxChanged(false)
                    props.checkboxChanged(true)
                }}
            />                        
        </Column>
    </Row>
}/>
}

const PermissionsText = styled(ParagraphText)`
  font-size: 13px;
  line-height: 17px;
`

const ScrollablePermissionsBody = styled.div`
    margin: 14px 0px;
    padding: 8px 20px;
    overflow-y: scroll;
    max-height: 140px;
    border: 1px solid ${ruminatiColors.green_3_30};
    background: ${ruminatiColors.green_3_5};
    border-radius: 8px;
`;