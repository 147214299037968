import { Avatar, Tag } from 'antd';
import { useNavigate } from 'react-router-dom';

import { Column, Row } from "../../components/styled_layout";
import { AccountPageContainer } from './account_screen'
import { ruminatiColors } from '@/utilities/colors';
import { useAuthStore } from '@/state/auth_store';
import { composeIconUrl } from '@/utilities/partners';
import { BodyText } from '@/components/styled_text';
import SmallButton from '@/components/buttons/small_button';
import { getCurrentOrganisation, getPrimaryOrganisation } from '@/utilities/organisations';
import useOrgSwitcher from '@/hooks/useOrgSwitcher';
import { getRoleNameByRoleId } from '@/utilities/permissions';

export default function AccountOrgMembershipsTab() {
  const authStore = useAuthStore();
  if (!authStore.user) return

  const orgs = authStore.user.organisations
  const currentOrg = getCurrentOrganisation(authStore.user)
  const navigate = useNavigate()
  const orgSwitcher = useOrgSwitcher()
  const usersPrimaryOrg = getPrimaryOrganisation(authStore.user)

  return <AccountPageContainer>
    {orgs.map((org, index) => {
      const matchingOrgRelationship = authStore.user!.relationships.find(r => r.resourceId === org.id && r.recipientId === authStore.user?.uid)
      const isOrgActive = currentOrg?.id === org.id

      return <Row
        key={org.id}
        style={{
          padding: index === 0 ? '12px 12px 16px 12px' : '16px 12px',
          borderBottom: index < orgs.length - 1 ? `1px solid ${ruminatiColors.green_3_10}` : undefined
        }}>

        <Column style={{ width: '50px', alignItems: 'flex-start' }}>
            <Avatar
              src={org.iconSmallUrl ? composeIconUrl(org.iconSmallUrl) : undefined}
            > {!org.iconSmallUrl ? org.name[0] : undefined}
            </Avatar>
        </Column>

        <Column style={{ flexGrow: '1', alignItems: 'flex-start' }}>
          <BodyText>
            {org.name}
            {isOrgActive &&
              <Tag
                color={ ruminatiColors.green_3 }
                style={{
                  marginLeft: '30px'
                }}>
                Active
              </Tag>
            }
            {usersPrimaryOrg?.id === org.id && (
              <Tag color={ruminatiColors.green_3_50}>
                Primary
              </Tag>
            )}
          </BodyText>
        </Column>

        <Column style={{ width: '200px', alignItems: 'flex-start' }}>
            <BodyText style={{ paddingLeft: '20px', color: ruminatiColors.green_3_50 }}>
              {getRoleNameByRoleId(matchingOrgRelationship?.roleId!)}
            </BodyText>
            {/* {props.showInviteDate ? <BodyText style={{ paddingLeft: '20px', color: ruminatiColors.green_3_50 }}>Requested {formatDateTimestampAsPrettyString(props.invite.created)}</BodyText> : undefined} */}
        </Column>

        <Column style={{ width: '300px', alignItems: 'flex-end' }}>
          <Row style={{columnGap: '20px'}}>
            {/* <SmallButton
            colorScheme='outline'
            disabledMessage="To leave this Organisation you'll first need to activate it."
            disabled={!isOrgActive}>
              Leave
            </SmallButton> */}
            <SmallButton
              onClick={async () => {
                if (currentOrg?.id === org.id) return navigate("/organisation")
                await orgSwitcher.changeOrganisation(org.id)
                return navigate("/organisation")
              }}
              colorScheme='green'
            >Organisation Settings</SmallButton>
          </Row>
        </Column>
        </Row>
    })}
  </AccountPageContainer>
}
