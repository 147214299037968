import { useAcceptRelationship } from "@/hooks/relationships"
import { Column, Row } from "../styled_layout"
import { LooserBodyText } from "../styled_text"
import { ruminatiColors } from "@/utilities/colors"
import { useAuthStore } from "@/state/auth_store"
import { Button } from "antd"
import { CheckCircleOutlined } from "@ant-design/icons"

export default function RelationshipCreatedOrgToOrg (props: {
  relationshipId: string
  orgName: string
}) {

  const acceptRelationship = useAcceptRelationship()
  const authStore = useAuthStore()
  const relationship = authStore.user?.relationships?.find(r => r.id === props.relationshipId)
  if (!relationship || relationship.deletedAt || !authStore.user) return

  return <>
  <Row style={{
    width: '100%',
    justifyContent: 'flex-start',
  }}>
    <Column style={{
      flexGrow: 1,
      alignItems: 'flex-start'
      }}>
      <LooserBodyText style={{
        color: ruminatiColors.green_4,
        marginBottom: '10px'
      }}>
         You've been invited to help {props.orgName} with their emissions data.
      </LooserBodyText>
      {relationship.isPending &&
        <Button 
        type="primary" 
        size="small" 
        ghost
        onClick={() => {
          acceptRelationship.mutateAsync({
            relationshipId: props.relationshipId,
            recipientId: relationship.recipientId!
          })
        }}
        >
          Accept
        </Button>
      }
      {!relationship.isPending && !relationship.deletedAt &&
        <LooserBodyText>
          <CheckCircleOutlined style={{marginRight: '10px'}}/>
          Actioned
        </LooserBodyText>
      }

    </Column>
  </Row>
</>
}