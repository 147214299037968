import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useRef, useState } from "react";

import Screen from "./screen";
import Heading from "../components/heading";
import { ButtonText } from "../components/styled_text";
import HeaderButton from "../components/buttons/header_button";
import { useDatabaseStore } from "../state/database_store";
import BasicMap from "../components/maps/basic_map";
import { FormRef, ForwardedRefForm } from "../models/form/form";
import { Column, Row } from "../components/styled_layout";
import { CreateDairyDTO, CreateFarmDTO, CreateFeedlotDTO, CreateProcessorDTO, CreatePropertyDTO, FeedlotSystemType, Property, PropertyType } from "../models/property_models";
import getPropertyLayerInformation from "../components/maps/layer_config/property_layer";

import { dairyFormData, farmFormData, feedlotFormData, processorFormData } from "../components/property/get_started_name";
import LoadingButton from "../components/buttons/loading_button";
import { ruminatiColors } from "../utilities/colors";
import SmallButton from "../components/buttons/small_button";
import { usePopupStore } from "../state/popup_store";
import { EditPropetyBoundsReturns } from "../components/popup/property_bounds_editor";
import { updateProperty } from "../services/property_service";
import PageHeader from "../components/headers/page_header";
import { useIntegrationsStore } from "../state/integrations";
import { useAuthStore } from "../state/auth_store";
import { CountryCode } from "@/utilities/countries";
import { GetStartedDairy, GetStartedFarm, GetStartedFeedlot, GetStartedProcessor } from "./get_started_screen";
import { useAgriwebbFarms } from "@/hooks/agriwebb";

/**
 * The Farm screen for seeing the details of a particular farm
 * @returns the Farm Screen component
 */
export default function FarmEditScreen() {
    const navigate = useNavigate();
    const databaseStore = useDatabaseStore()
    const integrationsStore = useIntegrationsStore()
    const popupStore = usePopupStore()
    const user = useAuthStore((state) => state.user)
    const {
        data: agriWebbFarms
    } = useAgriwebbFarms()

    const agriwebbFarmOptions = (agriWebbFarms ?? []).map(farm => {
        return {
            name: farm.name,
            value: farm.id
        }
    })


    const { propertyId } = useParams();
    const property: Property | undefined = databaseStore.properties?.find(
        (p) => p.id === propertyId
    );

    const [propertyGeom, setPropertyGeom] = useState(property ? property.geom : undefined)
    const [formValid, setFormValid] = useState(true)

    const formRef = useRef<FormRef>(null);

    const propertyLayerInfo = getPropertyLayerInformation({
        gj: propertyGeom
    });

    function getFormData() {
        if (!property) return
        if (property.propertyType === PropertyType.Farm) {
            return farmFormData(typeof integrationsStore.integrations?.agriwebb === "boolean" ? integrationsStore.integrations?.agriwebb : undefined, agriwebbFarmOptions, user?.country as CountryCode ?? CountryCode.Australia);
        } else if (property.propertyType === PropertyType.Feedlot) {
            return feedlotFormData();
        } else if (property.propertyType === PropertyType.Processor || property.propertyType === PropertyType.FurtherProcessor) {
            return processorFormData(true);
        } else if (property.propertyType === PropertyType.Dairy) {
            return dairyFormData(true);
        }
        throw new Error(`${property?.propertyType} property type not implemented`);
    }

    const updateEnterprise = async (state: Partial<GetStartedFarm | GetStartedFeedlot | GetStartedProcessor>) => {
        if (!property || !propertyGeom) return
        let newProperty: CreatePropertyDTO | undefined;

        if (property.propertyType === PropertyType.Farm) {
            let farmState = state as GetStartedFarm
            const farmProperty: CreateFarmDTO = {
                name: state.name ?? property.name,
                propertyType: PropertyType.Farm,
                cattleFarm: farmState.cattleFarm ?? property.cattleFarm,
                sheepFarm: farmState.sheepFarm ?? property.sheepFarm,
                grainFarm: farmState.grainFarm ?? property.grainFarm,
                ngr: farmState.ngr ?? property.ngr,
                pic: state.pic ?? property.pic,
                agriwebbFarmId: farmState.agriwebbFarmId ?? property.agriwebbFarmId,
                geom: propertyGeom
            };
            newProperty = farmProperty;
        } else if (property.propertyType === PropertyType.Feedlot) {
            let feedlotState = state as GetStartedFeedlot
            const feedlotProperty: CreateFeedlotDTO = {
                name: state.name ?? property.name,
                propertyType: PropertyType.Feedlot,
                productionSystem: (feedlotState.productionSystem ?? property.productionSystem) as FeedlotSystemType,
                pic: state.pic ?? property.pic,
                geom: propertyGeom
            };
            newProperty = feedlotProperty;
        } else if (property.propertyType === PropertyType.Processor || property.propertyType === PropertyType.FurtherProcessor) {
            let processorState = state as GetStartedProcessor
            const processorProperty: CreateProcessorDTO = {
                name: state.name ?? property.name,
                propertyType: property.propertyType ?? PropertyType.Processor,
                beefProcessor: processorState.beefProcessor ?? property.beefProcessor,
                sheepProcessor: processorState.sheepProcessor ?? property.sheepProcessor,
                pic: state.pic ?? property.pic,
                geom: propertyGeom
            };
            newProperty = processorProperty;
        } else if (property.propertyType === PropertyType.Dairy) {
            let dairyState = state as GetStartedDairy
            const dairyProperty: CreateDairyDTO = {
                name: state.name ?? property.name,
                propertyType: property.propertyType ?? PropertyType.Dairy,
                pic: state.pic ?? property.pic,
                dairyProductionSystem: dairyState.dairyProductionSystem ?? property.dairyProductionSystem!,
                geom: propertyGeom
            };
            newProperty = dairyProperty;
        }

        if (newProperty) {
            const updatedProperty = await updateProperty(property.id, newProperty);
            return updatedProperty;
        } else {
            throw new Error("[farm-edit.tsx] Unimplmented property type");
        }
    };

    const formData = getFormData()

    return (
        <Screen pageTitle="Edit Farm">
            <FarmPageWrapper>
                <PageHeader>
                    <HeaderButton
                        icon="upArrow"
                        iconPos="left"
                        onClick={() => navigate(`/farm/${propertyId}`)}
                    >
                        Cancel
                    </HeaderButton>
                </PageHeader>

                <FarmPageContainer>
                    <Row style={{ width: "100%", marginBottom: "40px" }}>
                        <Column style={{ width: "100%", alignItems: "center" }}>
                            <Heading level={2}>Edit {property?.propertyType} Details</Heading>
                        </Column>
                    </Row>

                    <Column
                        style={{
                            width: "100%",
                            marginTop: "40px",
                            rowGap: "16px",
                        }}
                    >
                        {
                        property && propertyId && formData ?
                        <>
                            <ForwardedRefForm
                            data={formData}
                            initialValue={property}
                            hideSubmitButton={true}
                            onChange={async () => {
                                setFormValid(formRef.current?.validate() ?? false)
                            }}
                            fieldSize="small"
                            onSubmit={async () => {
                                console.log('Farm edit submit')
                            }}
                            ref={formRef}
                            />
                            <Row
                                style={{
                                    width: "100%",
                                    height: "112px",
                                    pointerEvents: "none",
                                    borderRadius: " 8px",
                                    border: `1px solid ${ruminatiColors.dark_green}`,
                                    overflow: "hidden",
                                    position: "relative",
                                }}
                            >
                                <BasicMap
                                    height="112px"
                                    layers={[propertyLayerInfo.layer]}
                                    bounds={propertyLayerInfo.bounds}
                                    fitToBoundsChangeOnBoundsChange={true}
                                    attributionControl={false}
                                />
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        pointerEvents: "auto"
                                    }}
                                >
                                    <SmallButton
                                        borderRadius="48px"
                                        height="36px"
                                        colorScheme={"outline"}
                                        onClick={() => {
                                            popupStore.addPopup("property-bounds", (data: EditPropetyBoundsReturns) => {
                                                if (!data.geom) return
                                                setPropertyGeom(data.geom)
                                            }, { geom: propertyGeom })
                                        }}
                                    >
                                        <ButtonText>Edit Boundary</ButtonText>
                                    </SmallButton>
                                </div>
                            </Row>
                            <LoadingButton
                                size="medium"
                                disabled={!formValid}
                                onClick={async () => {
                                    const state = formRef.current?.getState()
                                    if (state) {
                                        const updatedProperty = await updateEnterprise(state);
                                        if (updatedProperty) {
                                            databaseStore.updateProperty(updatedProperty)
                                            navigate(`/farm/${property.id}`)
                                        }
                                    }
                                }}
                            >
                                Save changes
                            </LoadingButton>
                        </>
                        : undefined
                        }
                    </Column>
                </FarmPageContainer>
            </FarmPageWrapper>
        </Screen>
    );
}

const FarmPageWrapper = styled.div`
    display: flex;
    align-items: center;

    flex-direction: column;

    width: 100%;
    flex-grow: 1;
`;

const FarmPageContainer = styled.div`
    width: 90%;
    max-width: 700px;
    text-align: left;
    padding-bottom: 40px;
`;
