import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRollbar } from "@rollbar/react";

import Screen from "./screen";

// import { useAcceptResourceInvitation } from "@/hooks/resource_invitations";

import { convertUnknownErrortoStringable } from "@/utilities/errors";
import { useAcceptRelationship } from "@/hooks/relationships";
import { useAuthStore } from "@/state/auth_store";

// Transient screen in order to send a POST request to accept the invitation (as per route params).
// N.B. can't (in theory) do a server-side route that the user just visits directly from an email link, and then redirects to appropriate page in app, as it would need to be GET - and GET should not mutate resources (see https://developer.mozilla.org/en-US/docs/Glossary/Safe/HTTP).
// TODO: would be ideal to do this sort of thing in a route loader - per upgrade of React Router / transition to something like Tanstack Router
export default function AcceptRelationshipInvitationScreen() {
  const navigate = useNavigate();
  const rollbar = useRollbar()
  const { relationshipId } = useParams();
  const authStore = useAuthStore()

  const {
    mutateAsync: useAcceptRelationshipMutateAsync
  } = useAcceptRelationship()

  // on page load
  useEffect(() => {
    async function acceptInvite () {
      if (relationshipId && authStore.user) {
        try {
          await useAcceptRelationshipMutateAsync({ 
            relationshipId,
            recipientId: authStore.user.uid
           })
          // TODO: some success toast saying invitation was accepted?
          navigate("/")
        } catch(err) {
          // TODO: some error toast saying the invitation could not be accepted?
          rollbar.error(`Error accepting relationship invitation ${relationshipId}`, {
            errorDetails: convertUnknownErrortoStringable(err)
          })
          navigate("/")
        }
      } else {
        // TODO: some error toast saying the no invitation?
        // in practice, is it actually possible to arrive at this screen without an invitation id?
        navigate("/")
      }
    }

    acceptInvite()
  }, [])

  // TODO: some loading state while the invite is being accepted?
  return (
    <Screen />
  );
}
