import { createGlobalStyle } from 'styled-components'
import {
  CheckCircleFilled,
  CloseCircleFilled,
  InfoCircleFilled
} from "@ant-design/icons";
import { Popover, Table } from "antd";
import type { TableProps } from 'antd'

import { ruminatiColors } from "@/utilities/colors";
import { BodyText } from "../styled_text";

import { 
  ROLES,
  ROLE_ORG_ADMIN,
  ROLE_REPORT_MANAGER_INTERNAL,
  ROLE_REPORT_MANAGER_EXTERNAL,
  ROLE_REPORT_VIEWER_INTERNAL,
  ROLE_REPORT_VIEWER_EXTERNAL,
  ROLE_PARTNER_MANAGER,
  ROLE_PARTNER_ORG_ADMIN,
  ROLE_PARTNER_ORG_MEMBER
 } from "@ruminati/permissions/roles";

type AccessType = {
  title: string;
  view: boolean;
  create: boolean;
  deleteRole: boolean;
  edit: boolean;
}

const RELATIONSHIP_TYPE_ACCESS: Map<string, AccessType[]> = new Map([
  [ROLE_ORG_ADMIN.id, [
    {
      title: "Org Settings",
      view: true,
      create: true,
      deleteRole: true,
      edit: true,
    },
    {
      title: "Org Members",
      view: true,
      create: true,
      deleteRole: true,
      edit: true,
    },
    {
      title: "Properties",
      view: true,
      create: true,
      deleteRole: true,
      edit: true,
    },
    {
      title: "Reports",
      view: true,
      create: true,
      deleteRole: true,
      edit: true,
    },
    {
      title: "Reduction Plans",
      view: true,
      create: true,
      deleteRole: true,
      edit: true,
    },
    {
      title: "Data Sharing",
      view: true,
      create: true,
      deleteRole: true,
      edit: true,
    },
  ]],
  [ROLE_REPORT_MANAGER_INTERNAL.id, [
    {
      title: "Org Settings",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
    {
      title: "Org Members",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
    {
      title: "Properties",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
    {
      title: "Reports",
      view: true,
      create: true,
      deleteRole: true,
      edit: true,
    },
    {
      title: "Reduction Plans",
      view: true,
      create: true,
      deleteRole: true,
      edit: true,
    },
    {
      title: "Data Sharing",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
  ]],
  [ROLE_REPORT_VIEWER_INTERNAL.id, [
    {
      title: "Org Settings",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
    {
      title: "Org Members",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
    {
      title: "Properties",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
    {
      title: "Reports",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
    {
      title: "Reduction Plans",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
    {
      title: "Data Sharing",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
  ]],
  [ROLE_REPORT_MANAGER_EXTERNAL.id, [
    {
      title: "Org Settings",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
    {
      title: "Org Members",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
    {
      title: "Properties",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
    {
      title: "Reports",
      view: true,
      create: true,
      deleteRole: true,
      edit: true,
    },
    {
      title: "Reduction Plans",
      view: true,
      create: true,
      deleteRole: true,
      edit: true,
    },
    {
      title: "Data Sharing",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
  ]],
  [ROLE_REPORT_VIEWER_EXTERNAL.id, [
    {
      title: "Org Settings",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
    {
      title: "Org Members",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
    {
      title: "Properties",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
    {
      title: "Reports",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
    {
      title: "Reduction Plans",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
    {
      title: "Data Sharing",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
  ]],
  [ROLE_PARTNER_MANAGER.id, [
    {
      title: "Org Settings",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
    {
      title: "Org Members - From Partner",
      view: true,
      create: true,
      deleteRole: true,
      edit: true,
    },
    {
      title: "Properties",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
    {
      title: "Reports",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
    {
      title: "Reduction Plans",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
    {
      title: "Data Sharing",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
  ]],
  [ROLE_PARTNER_ORG_ADMIN.id, [
    {
      title: "Org Settings",
      view: true,
      create: true,
      deleteRole: true,
      edit: true,
    },
    {
      title: "Org Members",
      view: true,
      create: true,
      deleteRole: true,
      edit: true,
    },
    {
      title: "Clients",
      view: true,
      create: true,
      deleteRole: true,
      edit: true,
    }
  ]],
  [ROLE_PARTNER_ORG_MEMBER.id, [
    {
      title: "Org Settings",
      view: true,
      create: true,
      deleteRole: true,
      edit: true,
    },
    {
      title: "Org Members",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    },
    {
      title: "Clients",
      view: true,
      create: false,
      deleteRole: false,
      edit: false,
    }
  ]]
])

export default function AccessSummaryTooltip(props: {
  roleId: string;
}) {
  const columns:TableProps<AccessType>['columns'] = [
    {
      title: "",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Create",
      dataIndex: "create",
      key: "create",
      align: "center",
      render: (_, { create }) => <>{<RelevantIcon allowed={create} />}</>,
    },
    {
      title: "View",
      dataIndex: "read",
      key: "read",
      align: "center",
      render: (_, { view }) => <>{<RelevantIcon allowed={view} />}</>,
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      align: "center",
      render: (_, { edit }) => <>{<RelevantIcon allowed={edit} />}</>,
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "deleteRole",
      align: "center",
      render: (_, { deleteRole }) => (
        <>{<RelevantIcon allowed={deleteRole} />}</>
      ),
    },
  ];
  const rows: AccessType[] | undefined = RELATIONSHIP_TYPE_ACCESS.get(props.roleId)
  const role = ROLES.find(r => r.id === props.roleId)

  return (
    <Popover
      placement="topRight"
      color={ruminatiColors.bone}
      overlayStyle={{
        width: "350px",
      }}
      overlayInnerStyle={{
        padding: "20px"
      }}
      content={
        <>
          <BodyText style={{ fontSize: 16, fontWeight: 700, marginTop: "6px", marginBottom: "10px" }}>
            {role?.name} Role
          </BodyText>
          <BodyText style={{ marginBottom: "6px", lineHeight: '19px' }}>
            {role?.description}
          </BodyText>
          <AccessTableStyle/>
          <Table
            id="access_summary_table"
            rowKey="title"
            dataSource={rows}
            columns={columns}
            size="small"
            pagination={false}
          />
        </>
      }
    >
      <InfoCircleFilled
        style={{
          color: ruminatiColors.green_3,
          marginLeft: "8px",
        }}
      />
    </Popover>
  );
}

function RelevantIcon(props: { allowed: boolean }) {
  return props.allowed ? (
    <CheckCircleFilled style={{
      fontSize: '16px',
      color: ruminatiColors.dark_green,
    }} />
  ) : (
    <CloseCircleFilled style={{
      fontSize: '16px',
      color: ruminatiColors.red,
    }} />
  );
}


const AccessTableStyle = createGlobalStyle`
  #access_summary_table {
    .ant-table-cell {
      border-bottom-color: ${ruminatiColors.green_3_10};
    }
    .ant-table-thead {
      th::before {
        width: 0px;
      }
    }
  }
`
