import { InfoCard } from "../card";
import { Row, Column } from "../styled_layout";
import { Form, FormData } from "../../models/form/form";
import { DropdownFormField } from "@/models/form/form_fields/basic_form_fields";
// import { getCookies } from "@/utilities/cookies";
// import { CURRENT_ORGANISATION_ID_COOKIE_KEY } from "@ruminati/constants";

import { DropdownOptionData } from "@/models/form/form_fields/form_field";
import { BodyText } from "../styled_text";
import { ruminatiColors } from "@/utilities/colors";
import { AccessManagementPopupId } from "@/screens/organisation/member_tab_farm_org"
import { usePopupStore, PopupState } from "@/state/popup_store";
import { PermissionRoleT } from "@ruminati/types/permission";
import { UserMemberOfOrganisationT } from "@ruminati/types/user";
import { useDatabaseStore } from "@/state/database_store";
import { Property } from "@/models/property_models";

import {
  ROLE_REPORT_MANAGER_INTERNAL,
  ROLE_REPORT_VIEWER_INTERNAL,
 } from "@ruminati/permissions/roles";

export interface AddMemberDTO {
  propertyId: string;
  role: PermissionRoleT
}

const memberForm = (popupStore: PopupState, properties: Property[]): FormData<AddMemberDTO> => ({
    fields: {
        propertyId: new DropdownFormField({
          required: true,
          placeholder: 'Property',
          options: properties.map(p => {
            const out: DropdownOptionData<string> = {
              value: p.id,
              name: p.name
            }
            return out
          })
        }),
        role: new DropdownFormField({
          placeholder: "Role",
          children: () => {
            return <BodyText
            onClick={(() => {
              popupStore.addPopup(AccessManagementPopupId.RoleDescriptionPopup)
            })}
            style={{
              cursor: 'pointer',
              borderBottom: `1px solid ${ruminatiColors.dark_green}`
            }}>Find out more about roles</BodyText>
          },
          options: [ROLE_REPORT_MANAGER_INTERNAL, ROLE_REPORT_VIEWER_INTERNAL].map(r => {
            const out: DropdownOptionData<string> = {
              value: r.id,
              name: r.name ?? ''
            }
            return out
          }),
          required: true,
        }),
    }
})

export default function AddOrgMemberPropertyRolePopup(_props: {
  orgMember: UserMemberOfOrganisationT,
  onSubmit: () => void
}) {
  // const cookies = getCookies()
  // const currentOrgId = cookies[CURRENT_ORGANISATION_ID_COOKIE_KEY]
  const popupStore = usePopupStore()
  const properties = useDatabaseStore().properties ?? []
  // const defaultOrgRole = props.orgMember.permissions.find(p => p.resourceType === 'Organisation')

    // const {
    //   mutateAsync: createOrgInvitationMutateAsync,
    //   isPending: createOrgInvitationIsPending
    // } = useCreateOrgInvitation(currentOrgId)
    // const name = props.orgMember.firstName ? `${props.orgMember.firstName} ${props.orgMember.lastName}` : props.orgMember.email
    const name = "TODO"
    return (<Column style={{
      padding: '20px 40px',
      width: '760px'
    }}>
      <InfoCard
          content={`Assign ${name} a role for a specific property.`}
      />
      <Row style={{height: '20px'}}></Row>
      <Row>
          <Form
            data={memberForm(popupStore, properties)}
            width={"400px"}
            initialValue={{}}
            fieldSize="small"
            submitText={`Assign`}
            buttonSize="medium"
            // disableSubmitButton={createOrgInvitationIsPending}
            onSubmit={async (value) => {
              if (value) {
                // const newMember = await createOrgInvitationMutateAsync({
                //   role: value.role as PermissionRoleT,
                //   id: '',
                //   resourceType: "Property"
                // })
                // if (newMember) props.onSubmit()
              }
            }}
          />
      </Row>
    </Column>
    );
}
