import { OrganisationUpdateT } from "@ruminati/types/organisation";

import { Row } from "../styled_layout";
import { Form, FormData } from "../../models/form/form";
import { TextFormField } from "../../models/form/form_fields/basic_form_fields";
import { FieldSize } from "../../models/form/form_fields/form_field";
import { useAuthStore } from "../../state/auth_store";
import { usePopupStore } from "../../state/popup_store";
import { getCurrentOrganisation } from "@/utilities/organisations";
import { useOrganisationPatch } from "@/hooks/organisations";

export default function OrgProfilePopup() {
  const authStore = useAuthStore()
  const popupStore = usePopupStore()
  const currentOrg = authStore.user ? getCurrentOrganisation(authStore.user) : undefined

  const formConfig: FormData<OrganisationUpdateT> = {
      fields: {
          name: new TextFormField({
              required: true,
              label: "Organisation Name",
              size: FieldSize.Full
          })
      }
    };

    const organisationPatch = useOrganisationPatch()
    const orgInfo: OrganisationUpdateT = {
      name: currentOrg?.name ?? ""
    };

    return (
      <Row
      style={{
        padding: '40px'
      }}>
        <Form
          data={formConfig}
          buttonSize="small"
          width="400px"
          fieldSize="small"
          initialValue={orgInfo}
          submitText="Save changes"
          onSubmit={async (value) => {
            if (currentOrg) {
              await organisationPatch.mutateAsync({
                orgId: currentOrg?.id,
                dto: value
              })
              popupStore.closePopup();
            }
          }}
          />
      </Row>
    );
}
