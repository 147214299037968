import { useState } from "react";

import { Column } from "../../components/styled_layout";
import { AccountPageContainer } from "./organisation_screen";
import { BodyText } from "../../components/styled_text";
import { useAuthStore } from "../../state/auth_store";
import { CountryCode, countryIsNZ } from "../../utilities/countries";

import EmptyPlaceholder from "../../components/empty_placeholder";
import SwitchAntd from "@/components/switch_antd";
import { IntegrationCard } from "@/components/cards/integration_card";
import { getCurrentOrganisation } from "@/utilities/organisations";
import { useOrganisationPatch } from "@/hooks/organisations";

export default function OrgIntegrationsTab() {
  const authStore = useAuthStore();

  const user = authStore.user;
  const userCountryCode = user?.country as CountryCode;

  return (
    <AccountPageContainer style={{ rowGap: "32px" }}>
      {countryIsNZ(userCountryCode) ? (
        <EmptyPlaceholder
          children={
            "We don't currently have any integrations available for NZ-based users."
          }
          key="no-integrations"
        />
      ) : (
        <AuctionsPlusCard />
      )}
    </AccountPageContainer>
  );
}

function AuctionsPlusCard () {
  const authStore = useAuthStore();
  const currentOrg = getCurrentOrganisation(authStore.user!);
  const organisationPatch = useOrganisationPatch()

  const [localState, setLocalState] = useState({
    modifyingAuctionsPlus: false,
    auctionsPlus: currentOrg?.auctionsPlusStatusRetrievalApproved ?? false,
  });

  if (!currentOrg) return

  return (
    <IntegrationCard
      title="AuctionsPlus"
      logo="/images/ap.png"
      action={
        <>
          <BodyText style={{ marginRight: "10px" }}>
            Climate Assessment{" "}
            {localState.auctionsPlus === true ? "Allowed" : "Disallowed"}
          </BodyText>
          <Column style={{ paddingRight: "10px" }}>
            <SwitchAntd
              checked={localState.auctionsPlus}
              disabled={localState.modifyingAuctionsPlus}
              onChange={async (checked) => {
                setLocalState({
                  ...localState,
                  modifyingAuctionsPlus: true,
                  auctionsPlus: checked,
                });
                await organisationPatch.mutateAsync({
                  orgId: currentOrg?.id,
                  dto: {
                    auctionsPlusStatusRetrievalApproved: checked
                  },
                });
                setLocalState({
                  auctionsPlus: checked,
                  modifyingAuctionsPlus: false,
                });
              }}
            />
          </Column>
        </>
      }
      description="Allow AuctionsPlus to detect if your Organisation has completed an emissions report based on a PIC number. Enabling this integration will allow your Organisation to add the Climate Assessed badge to livestock sales listings within AuctionsPlus. Disabling this means AuctionsPlus is not able to request whether your Organisation has completed an emissions report."
    />
  );
}
