import { Type, Static } from '@sinclair/typebox'

// manual typebox types for runtime checking
// see "geojson" module for equivalent published types

// Position is either a two or three number tuple
// see types in "geojson" module
export const PositionTB = Type.Union([
  Type.Tuple([Type.Number(), Type.Number()]),
  Type.Tuple([Type.Number(), Type.Number(), Type.Number()])
])
export type PositionT = Static<typeof PositionTB>

export const MultiPolygonTB = Type.Object({
  type: Type.Literal('MultiPolygon'),
  coordinates: Type.Array(Type.Array(Type.Array(PositionTB)))
})
export type MultiPolygonT = Static<typeof MultiPolygonTB>

export const PointTB = Type.Object({
  type: Type.Literal('Point'),
  coordinates: PositionTB
})
export type PointT = Static<typeof PointTB>