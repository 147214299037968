import { Avatar, Button, Popconfirm, Tooltip } from "antd";
import { DisconnectOutlined } from "@ant-design/icons";

import MemberRow from "@/components/access/member_row";
import Heading from "@/components/heading";
import { Column, Row } from "@/components/styled_layout";
import { BodyText } from "@/components/styled_text";
import { useOrganisationClients, useOrganisationMembers } from "@/hooks/organisations";
import { useAcceptRelationship, useDeleteRelationship } from "@/hooks/relationships";
import { useAuthStore } from "@/state/auth_store";
import { ruminatiColors } from "@/utilities/colors";
import { formatDateTimestampAsPrettyString2 } from "@/utilities/dates";
import { getCurrentOrganisation, getUserOrgMembers } from "@/utilities/organisations";
import { getResourcePermissions } from "@/utilities/permissions";
import { RelationshipRecipientTypeOrganisationTB, RelationshipRecipientTypeUserTB } from "@ruminati/types/relationship";
import { ClientOfOrganisationT } from "@ruminati/types/user";
import { ASSIGN_PARTNER_MEMBER_TO_CLIENT_POPUP_ID } from "@/components/popup/add-partner-member-to-client";
import { usePopupStore } from "@/state/popup_store";
import LoadingPlaceholder from "@/components/loading_placeholder";
import { EXTERNAL_FARM_ORG_ROLES, ROLE_PARTNER_ORG_ADMIN } from "@ruminati/permissions/roles";

type ClientWithMembers = {
  client: ClientOfOrganisationT,
  members: ClientOfOrganisationT[]
}

export default function OrgClientTab() {
  const authStore = useAuthStore();
  const currentOrg = getCurrentOrganisation(authStore.user!);
  const popupStore = usePopupStore()
  const acceptRelationship = useAcceptRelationship()

  const {
    isPending: orgClientsPending,
    data: allClients
  } = useOrganisationClients()

  const {
    isPending: orgMembersPending,
    data: allOrgMembers
  } = useOrganisationMembers()
  const deleteRelationship = useDeleteRelationship()

  if (orgMembersPending || orgClientsPending) return <LoadingPlaceholder/>

  if (!authStore.user || !currentOrg) {
    return undefined
  }
  const orgPermissions = getResourcePermissions(authStore.user, currentOrg.id)
  const currentOrgUserRelationship = authStore.user.relationships.find(r => r.resourceId === currentOrg.id && r.recipientId === authStore.user?.uid)

  const clientOrgs = allClients?.filter(m => m.recipientDetails.recipientType === RelationshipRecipientTypeOrganisationTB.const)
  const clientMembers = allClients?.filter(m => m.recipientDetails.recipientType === RelationshipRecipientTypeUserTB.const)
  
  const userMembersInCurrentOrg = getUserOrgMembers(allOrgMembers ?? [])

  const clientMembersGrouped: ClientWithMembers[] = []
  clientOrgs?.forEach(client => {
    const members = clientMembers?.filter(m => m.resourceDetails.resourceId === client.resourceDetails.resourceId) ?? []
    clientMembersGrouped.push({
      client,
      members
    })
  })

  return <Column style={{padding: '0px 20px'}}>
    <Row style={{
      width: "100%",
      justifyContent: "space-between",
      marginTop: '20px',
      marginBottom: '10px'
    }}>
      <Column>
        <Heading level={5}>Clients</Heading>
      </Column>
    </Row>

    {clientMembersGrouped?.map((clientMember, idx) => {
      const clientName = clientMember.client.resourceDetails.orgName
      if (!authStore.user) return
      const clientPermissions = getResourcePermissions(authStore.user, clientMember.client.relationshipId)
      const clientIsAccepted = clientMember.client.joinedDate
      console.log(clientMember)
      const orgMembersNotYetAllocated = userMembersInCurrentOrg?.filter(m => {
        return !clientMember.members?.find(c => c.recipientDetails.recipientId === m.recipientId)
      })

      return <Row
        key={idx}
        style={{
          border: `1px solid ${ruminatiColors.green_3_15}`,
          borderRadius: '8px',
          padding: '20px',
          width: '100%',
          marginBottom: '40px'
        }}
      >
        <Column style={{width: '100%'}}>
          <Row style={{
            width: '100%',
            paddingBottom: '12px',
            borderBottom: `1px solid ${ruminatiColors.green_3_10}`,
            marginBottom: '12px'
          }}>
            <Column style={{width: '35%', alignItems: 'flex-start'}}>
              <Row style={{columnGap: '12px'}}>
                <Avatar>{clientName![0]}</Avatar>
                <BodyText style={{color: ruminatiColors.dark_green}}>{clientName}</BodyText>
              </Row>
            </Column>
            <Column style={{flexGrow: 1, alignItems: 'flex-start'}}>
              {clientIsAccepted && clientMember.client?.joinedDate &&
                <BodyText style={{color: ruminatiColors.green_3_50}}>
                    Connected {formatDateTimestampAsPrettyString2(clientMember.client?.joinedDate)}
                </BodyText>
              }
              {!clientIsAccepted && clientMember.client?.invitedDate &&
                <BodyText style={{color: ruminatiColors.green_3_50}}>
                    Invited {formatDateTimestampAsPrettyString2(clientMember.client?.invitedDate)}
                </BodyText>
              }
            </Column>

            {clientIsAccepted && <Column>
              <Popconfirm
                title="Remove Client"
                overlayStyle={{
                  width: '300px'
                }}
                description={`Are you sure to remove ${clientName} as a client? This will remove access for all ${currentOrg.name} members.`}
                onConfirm={async () => {
                  await deleteRelationship.mutateAsync({relationshipId: clientMember.client.relationshipId})
                }}
                color={ruminatiColors.bone}
              >
              <Tooltip
                color={ruminatiColors.effective_black}
                title={!clientPermissions?.permissions.delete ? "You do not have enough permissions to Delete this client" : undefined}
              >
              <Button 
                color="danger" 
                variant="link" 
                icon={<DisconnectOutlined/>}
                disabled={!clientPermissions?.permissions.delete}
              >
                Remove Client
              </Button>
              </Tooltip>
              </Popconfirm>
            </Column>
          }
          </Row>

          {clientMember.members.length > 0 && clientMember.members.map((m, idx2) => {
            return <MemberRow
            key={idx2}
            member={m}
            overridePermissions={orgPermissions}
            availableRoles={EXTERNAL_FARM_ORG_ROLES}
            />
          })}

          {clientIsAccepted && orgMembersNotYetAllocated.length > 0 &&
            <Row style={{marginTop: '24px'}}>
              <Tooltip
                color={ruminatiColors.effective_black}
                title={currentOrgUserRelationship?.roleId !== ROLE_PARTNER_ORG_ADMIN.id ? "You do not have enough permissions to assign members to clients" : undefined}
              >
                <Button
                size="small"
                ghost={clientMember.members.length === 0 ? undefined : true}
                disabled={currentOrgUserRelationship?.roleId !== ROLE_PARTNER_ORG_ADMIN.id}
                onClick={() => {
                  popupStore.addPopup(ASSIGN_PARTNER_MEMBER_TO_CLIENT_POPUP_ID, undefined, {
                    partnerOrg: clientMember.client
                  })
                }}
                type="primary"
                >
                  Assign {clientMember.members.length > 0 ? 'another' : ''} member from {currentOrg.name} to {clientName}
                </Button>
              </Tooltip>
            </Row>
          }

        {!clientIsAccepted &&
            <Row style={{marginTop: '24px', columnGap: '20px'}}>
              <Tooltip
                color={ruminatiColors.effective_black}
                title={currentOrgUserRelationship?.roleId !== ROLE_PARTNER_ORG_ADMIN.id ? "You do not have enough permissions to assign members to clients" : undefined}
              >
                <Button
                size="small"
                disabled={currentOrgUserRelationship?.roleId !== ROLE_PARTNER_ORG_ADMIN.id}
                onClick={() => {
                  acceptRelationship.mutateAsync({
                    relationshipId: clientMember.client.relationshipId,
                    recipientId: clientMember.client.recipientDetails.recipientId
                  })
                }}
                type="primary"
                >
                  Accept Client
                </Button>
              </Tooltip>

              <Tooltip
                color={ruminatiColors.effective_black}
                title={currentOrgUserRelationship?.roleId !== ROLE_PARTNER_ORG_ADMIN.id ? "You do not have enough permissions to assign members to clients" : undefined}
              >
                <Button
                size="small"
                disabled={currentOrgUserRelationship?.roleId !== ROLE_PARTNER_ORG_ADMIN.id}
                onClick={() => {
                  deleteRelationship.mutateAsync({
                    relationshipId: clientMember.client.relationshipId,
                  })
                }}
                type="primary"
                danger
                >
                  Decline Client
                </Button>
              </Tooltip>
            </Row>
          }

        </Column>
      </Row>
    })}
  </Column>
}
