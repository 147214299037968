/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from 'zustand';

export type PopupData = {
    id: string;
    callback?: (val: any) => void;
    metadata?: any;
};

export interface PopupState {
    popupStack: PopupData[];
    addPopup: (
        id: string,
        callback?: (val: any) => void,
        metadata?: any
    ) => void;
    closePopup: () => void;
}

export const usePopupStore = create<PopupState>((set) => ({
    /**
     * A list of popup ids. The currently showing popup id will be at the
     * front of the queue.
     */
    popupStack: [],

    /**
     * Adds a popup to the queue to display to the user
     * @param id an identifier for the popup to show
     * @returns void
     */
    addPopup: (id: string, callback?: (val: any) => void, metadata?: any) =>
        set((state) => ({
            popupStack: [...state.popupStack, { id, callback, metadata }],
        })),

    /**
     * Removes a popup from the queue. Will not necessarily close all popups
     * if there are others in the queue, the next popup will show instead.
     * @returns void
     */
    closePopup: () =>
        set((state) => {
            return { popupStack: state.popupStack.slice(0, state.popupStack.length - 1) };
        }),
}));
