import { Column, Row } from "../styled_layout"
import { LooserBodyText } from "../styled_text"
import { ruminatiColors } from "@/utilities/colors"

export default function RelationshipDeletedFromUserToOrg (props: {
  orgName: string
}) {
  return <>
  <Row style={{
    width: '100%',
    justifyContent: 'flex-start',
    marginBottom: '12px'
  }}>
    <Column style={{
      flexGrow: 1,
      alignItems: 'flex-start'
      }}>
      <LooserBodyText style={{
        color: ruminatiColors.green_4
      }}>
         You've been removed {props.orgName}.
      </LooserBodyText>
    </Column>
  </Row>
</>
}