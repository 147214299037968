import { Type, Static } from '@sinclair/typebox'

export const CountryAustraliaTB = Type.Literal('Australia')
export const CountryNewZealandTB = Type.Literal('New Zealand')

export const CountryTB = Type.Union([
  CountryAustraliaTB,
  CountryNewZealandTB
])
export type CountryT = Static<typeof CountryTB>

// Use ISO 3166-1 Alpha-3 codes
// https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
export const CountryCodeAustraliaTB = Type.Literal('AUS')
export const CountryCodeNewZealandTB = Type.Literal('NZL')

export const CountryCodeTB = Type.Union([
  CountryCodeAustraliaTB,
  CountryCodeNewZealandTB
])
export type CountryCodeT = Static<typeof CountryCodeTB>