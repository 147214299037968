import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { baseUrl, get, patch } from "@/services/api_service"
import { getTokens } from "@/services/auth_service"
import { NotificationAppT, NotificationUpdateT } from "@ruminati/types/notification"

export function useNotifications() {
  return useQuery({
    queryKey: ['notifications'],
    queryFn: () => new Promise<Array<NotificationAppT>>(async (resolve, reject) => {
      get(`${baseUrl}/notifications`, getTokens().token)
      .then((result) => {
        resolve(result)
      }).catch(err => {
        reject(err)
      })
    })
  })
}


export function useNotificationPatch(notificationId: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (dto: NotificationUpdateT) => patch(`${baseUrl}/notifications/${notificationId}`, dto, getTokens().token),
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: ['notifications'] })
      queryClient.setQueryData(['notifications'], (notifications: Array<NotificationAppT>) => {
        // Keep the notifications in the correct order
        // so no shuffling in the notification panel
        const out = notifications.slice()
        const idx = out.findIndex(n => n.id === notificationId)
        if (idx > -1 && notifications[idx]) out[idx] = {
          ...notifications[idx],
          ...data
        }
        return out
      })
    }
  })
}
