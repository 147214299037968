import { useEffect, PropsWithChildren, useState } from 'react';
import LoadingPlaceholder from '../components/loading_placeholder';
import Screen from '../screens/screen'
import { usePartnerStore } from '../state/partners';
import { getVisionInvitations, getPendingOrAcceptedFarmLinkages, getShareAll } from '../services/vision_service';

export default function CorporatePartnersGuard({
    children,
}: PropsWithChildren<unknown>) {
    const partnerStore = usePartnerStore()
    const [allDataReady, setAllDataReady] = useState<boolean>(false)

    useEffect(() => {
      if (partnerStore.linkedFarms && partnerStore.invitations && partnerStore.shareAlls) {
        setAllDataReady(true)
        return
      }
      if (partnerStore.linkedFarms === undefined) {
          getPendingOrAcceptedFarmLinkages().then(linkedFarms => {
            if (linkedFarms) partnerStore.setLinkedFarms(linkedFarms)
          }).catch(() => partnerStore.setLinkedFarms([]))

          getVisionInvitations().then(invitations => {
            if (invitations) partnerStore.setInvitations(invitations)
            else partnerStore.setInvitations([])
          }).catch((_err) => {
            partnerStore.setInvitations([])
          })

          getShareAll().then(shareAlls => {
            if (shareAlls) partnerStore.setShareAlls(shareAlls)
          }).catch(() => partnerStore.setShareAlls([]))
      }
    }, [partnerStore])


    if (!allDataReady) {
        return <Screen>
            <LoadingPlaceholder />
        </Screen>
    } else {
        return children
    }
}
