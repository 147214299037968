import styled from "styled-components";

import { BodyText } from "@/components/styled_text";
import { useAuthStore } from "@/state/auth_store";
import { getCurrentOrganisation } from "@/utilities/organisations";
import { Column, Row } from "@/components/styled_layout";
import { ruminatiColors } from "@/utilities/colors";
import { usePopupStore } from "@/state/popup_store";
import Icon from "@/components/icon";
import { useOrganisationMembers } from "@/hooks/organisations";
import { 
  ROLE_PARTNER_ORG_ADMIN,
  PARTNER_ORG_ROLES
 } from "@ruminati/permissions/roles";

import {
  getResourcePermissions, 
  permissionsAllowCreateInvite,
  permissionsAllowEdit
} from "@/utilities/permissions";
import { RelationshipRecipientTypeUserTB } from "@ruminati/types/relationship";
import MainButton from "@/components/buttons/main_button";
import MemberRow from "@/components/access/member_row";
import LoadingPlaceholder from "@/components/loading_placeholder";


export enum AccessManagementPopupId {
    AddMember = 'add-member',
    AddPropertySpecificRole = 'add-property-specific-role',
    RoleDescriptionPopup = 'role-description',
    DeleteMember = 'delete-member'
}

export default function AccountAccessManagementTab() {
  const authStore = useAuthStore();
  const popupStore = usePopupStore()
  const currentOrg = getCurrentOrganisation(authStore.user!);
  
  if (!authStore.user || !currentOrg) {
    return undefined
  }

  const {
    isPending, 
    data: allOrgMembers
  } = useOrganisationMembers()

  const orgPermissions = getResourcePermissions(authStore.user, currentOrg.id)
  const allowedToInvite = permissionsAllowCreateInvite(orgPermissions)

  if (isPending) return <LoadingPlaceholder/>
  if (!allOrgMembers || !authStore.user || !orgPermissions) return undefined

  const userMembers = allOrgMembers.filter(m => m.recipientType === RelationshipRecipientTypeUserTB.const)
  
  const adminRoleForOrg = ROLE_PARTNER_ORG_ADMIN

  const orgAdmins = userMembers.filter((m) => {
    if (m.role.id === adminRoleForOrg.id) return true;
    return false;
  })

  const orgMembers = userMembers.filter((m) => {
    if (m.role.id !== adminRoleForOrg?.id && m.direct) return true;
    return false;
  })

  const hasMoreThanOneActiveOrgAdmin = orgAdmins.length > 1 && orgAdmins.filter((a) => a.joinedDate).length > 1;

  return (
    <Column
      style={{
        padding: '0px 20px'
      }}
    >

    <Row style={{
      width: "100%",
      justifyContent: "space-between",
      marginTop: '20px',
      marginBottom: '10px'
    }}>
      <Column>
        <SectionHeading>Organisation Members</SectionHeading>
      </Column>
      <Column>
        <MainButton
          colorScheme="green"
          onClick={() => popupStore.addPopup(AccessManagementPopupId.AddMember)}
          disabled={!allowedToInvite}
          disabledMessage="You do not enough permissions to invite others to this Organisation"
          size="small"
        >
          <div style={{ padding: "0 8px 0 4px" }}>
            <Icon icon="add" />
          </div>
          Add Member
        </MainButton>
      </Column>
    </Row>

    {orgAdmins.map((m, idx) => {
      // Note - this prevents the Admin for making themselves 'Not Admin'
      // when there is only 1 Admin
      const onlyOneAdmin = m.joinedDate && !hasMoreThanOneActiveOrgAdmin ? true : false;
      const relationshipPermissions = getResourcePermissions(authStore.user!, m.relationshipId)
      const allowedToEdit = permissionsAllowEdit(relationshipPermissions)

      return <MemberRow
        key={idx}
        member={m}
        overrideDisable={(onlyOneAdmin && allowedToEdit) ? {
          reason: "There is only 1 Administrator in this Organisation"
        } : undefined}
        availableRoles={PARTNER_ORG_ROLES}
      />
    })}

    {orgMembers.map((m, idx) => <MemberRow
      availableRoles={PARTNER_ORG_ROLES}
      key={idx}
      member={m}
    />)}

    </Column>
  );
}

const SectionHeading = styled(BodyText)`
  color: ${ruminatiColors.green_3};
  font-size: 16px;
  font-weight: 600;
`;


