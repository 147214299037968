import queryClient from "@/utilities/queryClient"
import { getUserProfile } from "@/services/auth_service"
import { useAuthStore } from "@/state/auth_store"

export default function useUserProfile() {
  const authStore = useAuthStore()

  async function refreshProfile () {
    const userProfile = await getUserProfile()
    if (userProfile) {
      authStore.signIn(userProfile)
    }
  }

  async function afterRelationshipChange () {
    await refreshProfile()
    queryClient.invalidateQueries({ queryKey: ['org_clients'] })
    queryClient.invalidateQueries({ queryKey: ['org_members'] })
  }

  return {
    refreshProfile,
    afterRelationshipChange
  }
}
