import { UserClientT } from "@ruminati/types/user";

import { ROLES } from "@ruminati/permissions/roles";
import { ResourcePermissionsT } from "@ruminati/types/resource";
import { RelationshipT } from "@ruminati/types/relationship";

export function getRoleName (roleId: string): string | undefined {
  return ROLES.find(r => r.id === roleId)?.name
}

export function getResourcePermissions (user: UserClientT, resourceId: string): ResourcePermissionsT | undefined {
  if (user.permissions) {
    return user.permissions.find(p => p.resourceId === resourceId)
  }
  return undefined
}

export function getResourceRelationship (user: UserClientT, resourceId: string): RelationshipT | undefined {
  if (user.relationships) {
    return user.relationships.find(p => p.resourceId === resourceId)
  } 
  return undefined
}

export function getRoleNameByRoleId (roleId: string): string | undefined {
  return ROLES.find(r => r.id === roleId)?.name
}

export function permissionsAllowCreateEnterprise (permissions: ResourcePermissionsT | undefined): boolean {
  if (!permissions) return false
  return permissions.permissions.create.indexOf('Property') > -1
}

export function permissionsAllowCreateReport (permissions: ResourcePermissionsT | undefined): boolean {
  if (!permissions) return false
  return permissions.permissions.create.indexOf('Report') > -1
}

export function permissionsAllowCreateInvite(permissions: ResourcePermissionsT | undefined): boolean {
  if (!permissions) return false
  return permissions.permissions.create.indexOf('Relationship') > -1
}

export function permissionsAllowEdit (permissions: ResourcePermissionsT | undefined): boolean {
  if (!permissions) return false
  return permissions.permissions.update
}

export function permissionsAllowDelete (permissions: ResourcePermissionsT | undefined): boolean {
  if (!permissions) return false
  return permissions.permissions.update
}
