import styled from "styled-components";
import { useState } from "react";
import { DownOutlined, PlusOutlined, WarningOutlined } from "@ant-design/icons";
import { type MenuProps, Space, Dropdown, Typography, Tag, Tooltip, Popconfirm } from "antd";

import { BodyText } from "@/components/styled_text";
import { useAuthStore } from "@/state/auth_store";
import { Column, Row } from "@/components/styled_layout";
import { ruminatiColors } from "@/utilities/colors";
import { usePopupStore } from "@/state/popup_store";
import AccessSummaryTooltip from "@/components/access/access_summary_tooltip";
import {
  ROLE_ORG_ADMIN,
  ROLE_PARTNER_ORG_ADMIN
 } from "@ruminati/permissions/roles";

import {
  getResourcePermissions,
  permissionsAllowEdit,
 } from "@/utilities/permissions";
import { formatDateTimestampAsPrettyString2 } from "@/utilities/dates";
import { ClientOfOrganisationT, UserMemberOfOrganisationT } from "@ruminati/types/user";
import { useDatabaseStore } from "@/state/database_store";
import { RelationshipRecipientTypeUserTB } from "@ruminati/types/relationship";
import { AccessManagementPopupId } from "@/screens/organisation/member_tab_farm_org";
import { useDeleteRelationship, usePatchRelationship } from "@/hooks/relationships";
import { RoleT } from "@ruminati/types/role";
import { ResourcePermissionsT } from "@ruminati/types/resource";

export type AvailableRole = {
  key: string
  label: string
}

export default function MemberRow(props: {
  member: UserMemberOfOrganisationT | ClientOfOrganisationT
  availableRoles: RoleT[]
  allowPropertyOverrides?: boolean
  overridePermissions?: ResourcePermissionsT
  overrideDisable?: {
    reason: string
  }
  // hideBottomBorder?: boolean
}) {
  const authStore = useAuthStore()

  const popupStore = usePopupStore()
  const properties = useDatabaseStore().properties ?? []
  const relationshipPermissions = props.overridePermissions ?? getResourcePermissions(authStore.user!, props.member.relationshipId)

  const allowedToEdit = permissionsAllowEdit(relationshipPermissions)

  const disable = props.overrideDisable ?  props.overrideDisable : !allowedToEdit ? {
    reason: "You don't have enough permissions to Edit this membership"
  } : undefined
  const hasJoinedOrg = props.member.joinedDate;

  const overridePermissions = 'overrides' in props.member ? props.member.overrides ?? [] : []
  const hasOverrides = overridePermissions.length > 0

  const recipientType = 'recipientType' in props.member.recipientDetails ? props.member.recipientDetails.recipientType : 'recipientType' in props.member ? props.member.recipientType : undefined
  const memberIsUser = recipientType === RelationshipRecipientTypeUserTB.const

  const memberIsAdmin = props.member.role.id === ROLE_ORG_ADMIN.id || props.member.role.id === ROLE_PARTNER_ORG_ADMIN.id

  return (
    <Row
      style={{
        width: '100%',
        paddingBottom: '20px',
        marginTop: "20px",
        alignItems: "flex-start",
      }}
    >
      <Column
        style={{
          width: "35%",
          alignItems: "flex-start",
          rowGap: "5px",
        }}
      >
        <Row>
          <TableText style={{ fontWeight: 700 }}>
            {memberIsUser ?
              hasJoinedOrg ?
                `${props.member.recipientDetails.firstName} ${props.member.recipientDetails.lastName}`
                : `${props.member.recipientDetails.email}` :
              hasJoinedOrg ?
                props.member.recipientDetails.orgName
                : 'N/A'
            }
          </TableText>
        </Row>
        <Row>
          <TableText style={{ color: ruminatiColors.green_3_50 }}>
            {memberIsUser ?
              props.member.recipientDetails.email
              : 'N/A'
            }
          </TableText>
        </Row>
      </Column>

      <Column
        style={{
          width: memberIsAdmin ? "35%" : "25%",
          alignItems: "flex-start",
          rowGap: "5px",
        }}
      >
      {!hasJoinedOrg ? (
        <Row>
          <BodyText style={{ color: ruminatiColors.green_3 }}>
              <WarningOutlined style={{ marginRight: "6px" }} />
              Awaiting Response
          </BodyText>
        </Row>
      ) : undefined}
        <Row>
          <BodyText style={{ color: ruminatiColors.green_3_50 }}>
            {hasJoinedOrg && props.member.joinedDate ?
            `Joined ${formatDateTimestampAsPrettyString2(props.member.joinedDate)}` :
            `Invited ${formatDateTimestampAsPrettyString2(props.member.invitedDate)}`
            }
          </BodyText>
        </Row>
      </Column>
      {!memberIsAdmin && <Column
        style={{
          alignItems: "flex-end",
          paddingRight: "15px"
        }}
      >
        {/* <TableText style={{color: ruminatiColors.green_3_50}}>Default Access</TableText> */}

        {hasOverrides && (<Row style={{ marginTop: '25px', justifyContent: 'flex-end' }}>
          {overridePermissions.map((ov, index) => {
            const property = properties.find(p => p.id === ov.resourceId)
            return <Tag closable key={index}>{property?.name}</Tag>
          })}
          <PropertiesSelector/>
          </Row>
        )}

      </Column>
      }
      <Column
        style={{
          flexGrow: 1,
          alignItems: "flex-end",
        }}
      >
        <Row>
          <RoleSelector
            member={props.member}
            disable={disable}
            availableRoles={props.availableRoles}
            addPropertyRole={() => {
              popupStore.addPopup(AccessManagementPopupId.AddPropertySpecificRole, undefined, {
                orgMember: props.member
              })
            }}
          />
        </Row>

        {hasOverrides && (
          overridePermissions.map((_ov, index) => {
            return <Row style={{marginTop: '20px'}} key={index}>
              <RoleSelector
                availableRoles={[]}
                member={props.member}
                disable={disable}
              />
            </Row>
          })
        )}
      </Column>
    </Row>
  );
}

function RoleSelector(props: {
  member: UserMemberOfOrganisationT | ClientOfOrganisationT,
  availableRoles: RoleT[]
  disable?: {
    reason: string
  }
  isPropertySpecific?: boolean
  addPropertyRole?: () => void
}) {
  const role = props.member.role
  const roles: MenuProps["items"] = props.availableRoles.map(r => {
    return {
      label: r.name,
      key: r.id
    }
  })

  const currentRole = roles.find((r) => r?.key === role.id)
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const deleteRelationship = useDeleteRelationship()
  const patchRelationship = usePatchRelationship()

  // if (!props.isPropertySpecific) {
  //   roles.push({
  //       type: 'divider',
  //   }, {
  //     label: "Assign property-specific role",
  //     key: 'pp',
  //     onClick: () => {
  //       if (props.addPropertyRole) props.addPropertyRole()
  //     }
  //   })
  // }

    roles.push(
    {
        type: 'divider',
    },
    {
      label: (
        <Popconfirm
        title={props.isPropertySpecific ? "Remove" : "Remove Access"}
        description={`Are you sure to remove ${props.member.recipientDetails.firstName}?`}
        onConfirm={async () => {
          await deleteRelationship.mutateAsync({
            relationshipId: props.member.relationshipId
          })
        }}
        placement={"top"}
        color={ruminatiColors.bone}
      >
        <span style={{width: '100%'}} onClick={(e) => e.stopPropagation()}>
          {props.isPropertySpecific ? "Remove" : "Remove Access"}
        </span>
      </Popconfirm>),
      key: "delete-relationship",
      danger: true
    });

  const handleVisibleDropdownChange = (flag: boolean) => {
      setDropdownVisible(flag);
  };

  return (<>
      <Tooltip
        color={ruminatiColors.effective_black}
        title={props.disable ? props.disable.reason : undefined}
      >
        {/* Extra wrapper span to deal with
        https://ant.design/components/tooltip#why-does-the-warning-finddomnode-is-deprecated-sometimes-appear-in-strict-mode
        */}
        <span>
          <Dropdown
            placement="bottomRight"
            trigger={["click"]}
            onOpenChange={handleVisibleDropdownChange}
            open={dropdownVisible}
            disabled={props.disable ? true : false}
            menu={{
              items: roles,
              selectable: true,
              disabled: props.disable ? true : false,
              selectedKeys: [role.id],
              onSelect: async ({ key }) => {
                if (key !== 'delete-relationship') {
                  const newRole = props.availableRoles.find(r => r.id === key)
                  if (newRole) {
                    await patchRelationship.mutateAsync({
                      relationshipId: props.member.relationshipId,
                      body: {
                        roleId: newRole?.id
                      }
                    })
                  }
                }
              }
            }}
          >
            <Typography.Link>
              <Space>
                {currentRole && 'label' in currentRole ? currentRole.label : ''}
                <DownOutlined />
              </Space>
            </Typography.Link>
          </Dropdown>
        </span>
      </Tooltip>
      <AccessSummaryTooltip roleId={role.id}/>
  </>);
}

function PropertiesSelector() {
  const databaseStore = useDatabaseStore()
  const properties: MenuProps["items"] = databaseStore.properties?.map(p => {
    return {
      key: p.id,
      label: p.name
    }
  })

  return <Dropdown
        trigger={['click']}
        menu={{
        items: properties,
        selectable: true,
        selectedKeys: ['woodside'],
        multiple: true
      }}>
      <Tag style={{borderStyle: "dashed", marginRight: '0px'}} icon={<PlusOutlined/>} >Add </Tag>
    </Dropdown>
}

const TableText = styled(BodyText)`
  color: ${ruminatiColors.green_3};
`;
