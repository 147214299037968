import { ThemeConfig } from "antd"
import { ruminatiColors } from "./colors"

// See additional overrides in src/index.css

export const ANT_DESIGN_THEME: ThemeConfig = {
  token: {
    colorPrimary: ruminatiColors.green_3,
    colorError: ruminatiColors.red,
    colorTextBase: ruminatiColors.dark_green,
    colorLink: ruminatiColors.green_3,

    fontFamily: 'TTInterfaces',
    fontSize: 14,

    borderRadius: 24,
  },
  components: {
    Button: {
      controlHeight: 36,

      defaultShadow: 'none',

      colorPrimaryHover: ruminatiColors.dark_green,
      primaryShadow: 'none',
      
      defaultBg: ruminatiColors.bone,
      defaultGhostBorderColor: ruminatiColors.green_4,
      defaultGhostColor: ruminatiColors.green_3,

      // Small Styling
      paddingInlineSM: 12,
      controlHeightSM: 30,
      borderRadiusSM: 8
    },
    Collapse: {
      borderRadiusLG: 8,
      contentBg: 'transparent',
      headerBg: 'transparent',
      colorTextHeading: ruminatiColors.green_3
    },
    Dropdown: {
      colorBgElevated: ruminatiColors.bone,
      controlItemBgActive: ruminatiColors.green_3_10,
      controlItemBgActiveHover: ruminatiColors.green_3_30,
    },
    Popover: {
      borderRadiusLG: 8,
    },
    Switch: {
      colorPrimary: ruminatiColors.green_3,
      colorPrimaryHover: ruminatiColors.green_4,
      handleBg: ruminatiColors.bone
    },
    Select: {
        zIndexPopup: 10000,
        colorPrimary: ruminatiColors.orange_50,
        colorPrimaryHover: ruminatiColors.orange_50,
        boxShadow: 'transparent',
        boxShadowSecondary: 'transpanent',
        boxShadowTertiary: 'transparent',
        optionPadding: "16px",
        optionSelectedBg: 'transparent',
        colorBorder: ruminatiColors.green_3,
        selectorBg: ruminatiColors.bone,
        colorBgElevated: ruminatiColors.bone,
        controlHeightLG: 48,
        fontSize: 16,
        fontSizeIcon: 16,
        optionActiveBg: 'transparent',
        optionSelectedFontWeight: 500,
        colorText: ruminatiColors.orange,
        optionSelectedColor: ruminatiColors.orange,
        optionFontSize: 20,
        fontFamily: '"TTInterfaces", sans-serif',
        lineHeight: 1.4
    },
    Table: {
      headerBg: 'transparent',
      rowHoverBg: 'transparent',
      colorBgContainer: 'transparent',

      colorText: ruminatiColors.dark_green,
      borderColor: ruminatiColors.green_3,
      headerBorderRadius: 8,
      headerColor: ruminatiColors.green_3,

      // For the Access Summary Tooltip
      cellPaddingBlockSM: 8,
      cellPaddingInlineSM: 4,
      cellFontSizeSM: 13
    },
    Tag: {
      borderRadiusSM: 4
    },
    Tooltip: {
      borderRadius: 8,
      colorBgSpotlight: ruminatiColors.effective_black
    }
  },
}
