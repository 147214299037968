import { BodyText, LooserBodyText } from "../styled_text"
import { Column, Row } from "../styled_layout"
import { ruminatiColors } from "@/utilities/colors"

import { useNotifications } from "@/hooks/notifications"
import NotificationItem from "../notifications/notification-item"
import { InboxOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

export default function NotificationPanel() {
  const {
    data: userNotifications
  } = useNotifications()
  const navigate = useNavigate()

  return <Row
    style={{
      width: "100%",
      justifyContent: "space-between",
      alignItems: "stretch",
    }}
  >
    <Column
      style={{
        width: '100%',
        alignItems: "flex-start",
      }}
    >
      <Row style={{
        width: '100%',
        padding: "15px 20px",
        justifyContent: 'space-between',
        borderBottom: `1px solid ${ruminatiColors.green_3_10}`
      }}>
        <BodyText 
         style={{ fontWeight: 700 }}
         >
          Notifications
        </BodyText>
        <ViewAllText
          onClick={() => navigate('/notifications')}
        >
          View All
        </ViewAllText>
      </Row>

      {!userNotifications || userNotifications?.length === 0 ?
        <Row style={{ width: '100%', padding: "15px" }}>
          <Column>
            <InboxOutlined style={{
              fontSize: '26px',
              color: ruminatiColors.green_4
            }}
            />
            <LooserBodyText
              style={{
                margin: '10px 0px',
                fontSize: '15px',
                color: ruminatiColors.green_4
              }}
            >
              No notifications
            </LooserBodyText>
          </Column>
        </Row>
        :
        userNotifications?.map(n => {
          return <NotificationItem key={n.id} notification={n} />
        })}
    </Column>

  </Row>
}

const ViewAllText = styled(BodyText)`
  color: ${ruminatiColors.green_3_50};
  cursor: pointer;
  transition: 0.2s;
  &:hover {
      color: ${ruminatiColors.green_3};
  }
`