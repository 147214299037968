import { Type, Static } from '@sinclair/typebox'

export const ResourceTypeOrganisationTB = Type.Literal('Organisation')
export const ResourceTypePropertyTB = Type.Literal('Property')
export const ResourceTypeReportTB = Type.Literal('Report')
export const ResourceTypeRelationshipTB = Type.Literal('Relationship')

export const ResourceTypeTB = Type.Union([
  ResourceTypeOrganisationTB,
  ResourceTypePropertyTB,
  ResourceTypeReportTB,
  ResourceTypeRelationshipTB
])
export type ResourceTypeT = Static<typeof ResourceTypeTB>

// N.B. if we ever need "ResourceTB", a Type.Union of all resources, be careful re circular dependencies

// TODO: should these live here? just need to make sure no circular deps
export const ResourcePermissionsPermissionsTB = Type.Object({
  read: Type.Boolean(),
  update: Type.Boolean(),
  delete: Type.Boolean(),
  create: Type.Array(ResourceTypeTB)
})
export type ResourcePermissionsPermissionsT = Static<typeof ResourcePermissionsPermissionsTB>

export const ResourcePermissionsTB = Type.Object({
  permissions: ResourcePermissionsPermissionsTB,
  resourceId: Type.String(), // uuid
  resourceType: ResourceTypeTB,
  // N.B. same as ExplodedPathTB, but not imported in order to avoid circular dep
  path: Type.Array(Type.Object({
    id: Type.String(), // uuid
    type: ResourceTypeTB
  }))
})
export type ResourcePermissionsT = Static<typeof ResourcePermissionsTB>

export const PermissionsPerResourceTB = Type.Record(
  Type.String(), // uuid
  ResourcePermissionsTB
)
export type PermissionsPerResourceT = Static<typeof PermissionsPerResourceTB>
