import { useQuery, useMutation } from "@tanstack/react-query"

import { OrganisationT, OrganisationUpdateT } from "@ruminati/types/organisation";
import { ClientOfOrganisationT, UserMemberOfOrganisationT } from "@ruminati/types/user";

import { getTokens } from '@/services/auth_service';
import { baseUrl, get, patch } from '@/services/api_service';

import { getCurrentOrganisationId } from "@/utilities/organisations";
import { useAuthStore } from "@/state/auth_store";

export function useOrganisationPatch () {
  const authStore = useAuthStore()

  return useMutation({
    mutationFn: (details: {
      orgId: string,
      dto: OrganisationUpdateT
    }) => patch(`${baseUrl}/organisations/${details.orgId}`, details.dto, getTokens().token),
    onSuccess: async (data) => {
      const existingOrgRecord = authStore.user?.organisations.find(o => o.id === data.id)
      if (existingOrgRecord) Object.assign(existingOrgRecord, data)
    }
  })
}

export function useOrganisationPartners() {
  return useQuery({
    queryKey: ['org_partners'],
    queryFn: () => new Promise<Array<OrganisationT>>(async (resolve, reject) => {
      get(`${baseUrl}/organisations/partners`, getTokens().token)
      .then((result) => {
        resolve(result)
      }).catch(err => {
        reject(err)
      })
    })
  })
}

export function useOrganisationMembers() {
  return useQuery({
    queryKey: ['org_members'],
    queryFn: () => new Promise<Array<UserMemberOfOrganisationT>>(async (resolve, reject) => {
      const orgId = getCurrentOrganisationId()
      get(`${baseUrl}/organisations/${orgId}/members`, getTokens().token)
      .then((result) => {
        resolve(result)
      }).catch(err => {
        reject(err)
      })
    })
  })
}

export function useOrganisationClients() {
  return useQuery({
    queryKey: ['org_clients'],
    queryFn: () => new Promise<Array<ClientOfOrganisationT>>(async (resolve, reject) => {
      const orgId = getCurrentOrganisationId()
      get(`${baseUrl}/organisations/${orgId}/clients`, getTokens().token)
      .then((result) => {
        resolve(result)
      }).catch(err => {
        reject(err)
      })
    })
  })
}

// export function useDeleteOrgRelation(orgId: string) {
//   return useMutation({
//     mutationFn: (dto: ResourceInvitationCreateDTOT) => post(`${baseUrl}/resource_invitations/create_invite/${orgId}`, dto, getTokens().token)
//   })
// }
