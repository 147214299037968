import { useState, useMemo } from "react"
import styled from "styled-components"
import { Link, useNavigate } from "react-router-dom"
import { Virtuoso } from 'react-virtuoso'
import { Button, Avatar, Tag, Input } from "antd"
import { SettingOutlined, ProfileOutlined, LogoutOutlined } from "@ant-design/icons"

import { useAuthStore } from "@/state/auth_store"

import { BodyText, LooserBodyText } from "../styled_text"
import { Column, Row } from "../styled_layout"
import { ruminatiColors } from "@/utilities/colors"
import { getCurrentOrganisation } from "@/utilities/organisations"

import { OrganisationT } from "@ruminati/types/organisation"
import { logOut } from "@/services/auth_service"
import useOrgSwitcher from "@/hooks/useOrgSwitcher"
import { composeIconUrl } from "@/utilities/partners"
import { getPrimaryOrganisation } from "@/utilities/organisations"

export default function AccountMenuItemContent() {
  const authStore = useAuthStore()

  const navigate = useNavigate()
  const orgSwitcher = useOrgSwitcher()

  const [orgSearchTerm, setOrgSearchTerm] = useState("")

  const user = authStore.user
  if (!user) return

  const usersPrimaryOrg = getPrimaryOrganisation(user)
  const currentOrg = getCurrentOrganisation(user!)

  // memoise to prevent re-calculating large sorts if there are many orgs
  const sortedOrganisations = useMemo(() => {
    return user!.organisations.sort((a, b) => {
      if (a.id === currentOrg?.id) return -1;
      if (b.id === currentOrg?.id) return 1;
      return a.name > b.name ? 1 : -1;
    });
  }, [user.organisations, currentOrg]);

  // only allow searching for orgs if >10 orgs
  const filteredOrganisations = sortedOrganisations.length <= 10
    ? user!.organisations
    : user!.organisations
      .filter(o => orgSearchTerm === "" ? o : o.name.toLowerCase().includes(orgSearchTerm.toLowerCase()))

  function logoutUser() {
    logOut()
    navigate("/login");
  }

  return (
    <Row
      style={{
        width: "100%",
        justifyContent: "space-between",
        alignItems: "stretch",
      }}
    >
      {user!.organisations.length > 1 &&
        <Column
          style={{
            background: ruminatiColors.green_3_5,
            flexGrow: 1,
            padding: "30px 0px",
            alignItems: "flex-start",
            rowGap: "12px",
            borderRight: `1px solid ${ruminatiColors.green_3_10}`,
            maxHeight: "500px",
          }}
        >
          <Column
            style={{
              padding: "0px 15px",
              alignItems: "flex-start",
              rowGap: "12px",
              width: "100%"
            }}
          >
            <BodyText
              style={{
                fontWeight: 600,
                marginBottom: "10px",
              }}
            >
              My Organisations
            </BodyText>
            {user.organisations.length === 0 &&
              <LooserBodyText>You currently don't belong to any organisations.</LooserBodyText>
            }
            {user.organisations.length > 10 && (
              <Input
                placeholder="Organisation name"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOrgSearchTerm(e.target.value)}
              />
            )}
          </Column>

          <Virtuoso
            style={{
              height: filteredOrganisations.length * 48,
              maxHeight: "200px",
              width: "100%"
            }}
            totalCount={filteredOrganisations.length}
            overscan={5}
            itemContent={(index) => {
              const o = filteredOrganisations[index]
              return (
                <div style={{ padding: "0px 15px" }}>
                  <OrgRow
                    key={o.id}
                    org={o}
                    isPrimary={o.id === usersPrimaryOrg?.id}
                    orgActive={o.id === currentOrg?.id}
                    onClick={async (org) => {
                      if (o.id === currentOrg?.id) return
                      await orgSwitcher.changeOrganisation(org.id)
                    }}
                  />
                </div>
              )
            }}
          />

        </Column>
      }
      <Column
        style={{
          padding: "30px 25px",
          minWidth: "225px",
          alignItems: "flex-start",
          rowGap: "12px",
        }}
      >
        <AccountMenuItem
          label="Account Settings"
          link="/account"
          icon={<SettingOutlined style={{ marginRight: '8px' }} />}
        />

        {currentOrg &&
          <AccountMenuItem
            label="Organisation Settings"
            link="/organisation"
            icon={<ProfileOutlined style={{ marginRight: '8px' }} />}
          />
        }

        <Row style={{ width: "100%", marginTop: "20px" }}>
          <Button
            block
            type="primary"
            onClick={() => logoutUser()}
            icon={<LogoutOutlined />}
          >
            Logout
          </Button>
        </Row>
      </Column>
    </Row>
  )
}

function OrgRow(props: {
  org: OrganisationT,
  orgActive: boolean,
  isPrimary?: boolean,
  onClick: (org: OrganisationT) => void
}) {

  return (
    <Row
      style={{
        width: "100%",
        justifyContent: "flex-start",
        cursor: "pointer",
        margin: "10px 0px",
        columnGap: "8px"
      }}
      onClick={() => props.onClick(props.org)}
    >
      <Column>
        <Avatar
          size="small"
          src={props.org.iconSmallUrl && composeIconUrl(props.org.iconSmallUrl)}
        >
          {!props.org.iconSmallUrl && props.org.name[0]}
        </Avatar>
      </Column>

      <Column>
        <MenuRowText active={props.orgActive}>
          {props.org.name}
        </MenuRowText>
      </Column>

      <Column style={{
        alignItems: 'flex-end',
        flexGrow: 2
      }}>
        <Row>
        {props.orgActive && (
          <Tag color={ruminatiColors.green_3} style={{
            marginRight: '0px',
            float: "right"
          }}>
            Active
          </Tag>
        )}

        {props.isPrimary && (
          <Tag color={ruminatiColors.green_3_50} style={{
            marginRight: '0px',
            marginLeft: '8px',
            float: "right",
            cursor: "default"
          }}>
            Primary
          </Tag>
        )}
        </Row>
      </Column>
    </Row>
  );
}

function AccountMenuItem(props: {
  label: string,
  icon: JSX.Element,
  link: string,
}) {
  return (<Link to={props.link}>
    <Row
      style={{
        marginBottom: '8px'
      }}
    >
      <MenuRowText active={true}>
        {props.icon}
        {props.label}
      </MenuRowText>
    </Row>
  </Link>
  );
}

const MenuRowText = styled(BodyText).attrs((props: { active: boolean }) => props)`
  color: ${(props) => `${props.active ? ruminatiColors.dark_green : ruminatiColors.green_4}`};
  transition: 0.3s;

  &:hover {
    color: ${ruminatiColors.green_3_50};
  }
`
