import styled from "styled-components";
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';

import { useNotificationPatch, useNotifications } from "@/hooks/notifications";
import Heading from "@/components/heading";
import { CheckSquareOutlined, InboxOutlined } from "@ant-design/icons";
import { Column, Row } from "@/components/styled_layout";
import { ruminatiColors } from "@/utilities/colors";
import { BodyText, LooserBodyText } from "@/components/styled_text";
import { getNotificationById, NotificationCheckStyle } from "@/components/notifications/notification-item";
import Screen from "./screen";
import { formatDateTimestampAsPrettyString2 } from "@/utilities/dates";


export default function NotificationsScreen() {
  const {
    data: userNotifications
  } = useNotifications()

  const items: CollapseProps['items'] = userNotifications?.map(n => {
    const notificationContent = getNotificationById(n)
    const notificationPatch = useNotificationPatch(n.id)

    return {
      key: n.id,
      label: notificationContent?.title,
      children: notificationContent?.content,
      extra: <Row>
        {formatDateTimestampAsPrettyString2(n.createdDate)}
        {!n.read && <BodyText 
        className="markReadBox"
          style={{
            position: 'absolute',
            right: '-150px'
          }}
          onClick={(e) => {
            e.stopPropagation()
            if (!n.read && notificationPatch.isIdle) {
              notificationPatch.mutateAsync({read: true})
            }
          }}
          >
            <CheckSquareOutlined/> Mark as Read
          </BodyText>
        }
      </Row>,
      styles: {
        header: {
          background: n.read ? undefined : ruminatiColors.green_3_5
        },
        body: {
          background: n.read ? undefined : ruminatiColors.green_3_5
        }
      }
    }
  })

  return <Screen pageTitle="Notifications">
    <NotificationsWrapper>
      <NotificationCheckStyle/>

      <Row style={{
        width: '100%',
        justifyContent: 'flex-start',
        marginBottom: '40px'
      }}>
        <Heading level={4} textAlign="start">Notifications</Heading>
      </Row>

      {!userNotifications || userNotifications?.length === 0 ?
        <Row style={{ width: '100%', padding: "15px" }}>
          <Column>
            <InboxOutlined style={{
              fontSize: '26px',
              color: ruminatiColors.green_4
            }}
            />
            <LooserBodyText
              style={{
                margin: '10px 0px',
                fontSize: '15px',
                color: ruminatiColors.green_4
              }}
            >
              No notifications
            </LooserBodyText>
          </Column>
        </Row>
        :
        <Collapse items={items} style={{ width: '100%' }} />
      }

    </NotificationsWrapper>
  </Screen>
}

const NotificationsWrapper = styled.div`
    display: flex;
    align-items: center;

    flex-direction: column;

    width: 700px;
    flex-grow: 1;

    padding-top: 60px;
`;
