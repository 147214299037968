import { getCurrentOrganisationId } from "@/utilities/organisations";
import {
    SubscriptionType,
    SubscriptionPrime,
    SubscriptionUpgradeResponse,
    SubscriptionDTO,
} from "../models/subscription_model";
import { get, post, baseUrl } from "./api_service";
import { getTokens } from "./auth_service";

function getSubscriptionUrl () {
    const orgId = getCurrentOrganisationId()
    return  `${baseUrl}/organisations/${orgId}/subscription`;
}

export async function upgradeToPrime(voucher?: string | undefined): Promise<
    SubscriptionUpgradeResponse | SubscriptionPrime | undefined
> {
    const subscriptionBaseUrl = getSubscriptionUrl()
    const data: SubscriptionDTO = {
        subscriptionType: SubscriptionType.PRIME
    };
    if (voucher) data.voucherCode = voucher
    const response = await post(
        `${subscriptionBaseUrl}`,
        data,
        getTokens().token
    );

    // When upgrading from BASE to PRIME.
    if ("checkoutUrl" in response) {
        return SubscriptionUpgradeResponse.fromJSON(response);
    }

    // When enabling PRIME auto-renew.
    if ("subscription" in response) {
        return SubscriptionPrime.fromJSON(response["subscription"]);
    }
    return undefined;
}

export async function cancelSubscription() {
    const data: SubscriptionDTO = { subscriptionType: SubscriptionType.BASE };
    const subscriptionBaseUrl = getSubscriptionUrl()
    return await post(`${subscriptionBaseUrl}`, data, getTokens().token);
}


export async function checkPromoCode(promoCode: string) {
    return await get(`${baseUrl}/stripe/promocode/${promoCode}`)
}
