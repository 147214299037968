import CalculateAreaButton from "../../../components/buttons/calculate_area_button";
import ElectricityUseButton from "../../../components/buttons/electricityuse_button";
import { LegendItem, LegendSwatch } from "../../../components/maps/legend";
import { DrawAndCalculateAreaPopupReturns } from "../../../components/popup/draw_and_calculate_area_calculator";
import { NumberFormField, DropdownFormField, TextFormField, RadioBoxFormField } from "../../../models/form/form_fields/basic_form_fields";
import { State, FieldSize, ConcatMethod, NumberType } from "../../../models/form/form_fields/form_field";
import { LivestockClassAUS, ReportCattlePurchases, ReportConsumables, ReportFeedlotCattlePurchases, ReportMineralSupplements, ReportPasture, ReportPasturePreV3_5, ReportPastureNZ, ReportSheepPurchases, ReportSupplmentaryFeed, ReportTrees, ReportPastureForm, ReportGrains_Post3_3Form } from "../../../models/report";
import { nzVegetationTypes, vegetationTypes, soilTypes, cattlePurchaseRegions, AusBeefClasses, mineralSupplementTypes, truckTypes, OtherNFertilisersClasses } from "../../../models/form_options";
import { CountryCode } from "../../../utilities/countries";
import { formatNumber } from "../../../utilities/functions";
import { MultiPolygon } from "geojson";
import HiddenFormField from "../../../models/form/form_fields/hidden_form_field";
import { ReportPageMultipleForms, ReportFormPage } from "../../../models/form/form_page";
import { ReportFormPageId, inputPercent, outputPercent } from "../../../utilities/forms";
import { createExistingTreePlantingLayerAndLegend, createProposedTreePlantingLayerAndLegend } from "../../../components/maps/layer_config/tree_layers";
import FuelUseButton from "@/components/buttons/fueluse_button";
import { MultiInputFormField } from "@/models/form/form_fields/multi_input_form_field";
import { RepeatableArrayFieldWrapper } from "@/models/form/form_fields/form_field_wrappers";
import { CustomFieldWithState, SeparatorField } from "@/models/form/form_fields/decoration_form_fields";
import { Row, Column } from "@/components/styled_layout";
import { BodyText } from "@/components/styled_text";
import { InfoCard } from "@/components/card";
import SmallButton from "@/components/buttons/small_button";
import { combinedHerbicideAndHerbicidePesticideOptions } from "@/models/form_chemical_lookup";
import { calculateHerbicideActiveIngredients } from "@/utilities/chemicals";

import { HelpDrawerExplanatoryContentText } from "@/components/HelpDrawer";
import { AccordionItemContentText } from "@/components/Accordion";
import { TextLink } from "@/components/styled_text";
import { PropertyType } from "@/models/property_models";
import Icon from "@/components/icon";
import HerbicideCalculator from "@/components/buttons/calculate-herbicide";

export const consumables = (propertyCountryCode: CountryCode, propertyType?: PropertyType): ReportFormPage<ReportConsumables> => ({
    id: ReportFormPageId.Consumables,
    title: "Consumables",
    image: "consumables.png",
    formData: {
        fields: {
            petrol: new NumberFormField({
                required: true,
                label: "Petrol use",
                unit: "L/year",
                size: FieldSize.Half,
                tip: "The total amount of petrol used on your farm in the baseline year. This should include on road fuel use related to the farm business.",
                children: (state) => {
                    return (<FuelUseButton
                        fuelType='Petrol'
                        onSubmit={(val) => {
                            val = formatNumber(val, { maxDecimalSigFig: 4 });
                            state.set({
                                ...state.get,
                                petrol: val,
                            });
                        }}
                    />)
                },
            }),
            diesel: new NumberFormField({
                required: true,
                label: "Diesel use",
                unit: "L/year",
                size: FieldSize.Half,
                tip: "The total amount of diesel used on your farm in the baseline year. This should include on road fuel use related to the farm business.",
                children: (state) => {
                    return (<FuelUseButton
                        fuelType='Diesel'
                        onSubmit={(val) => {
                            val = formatNumber(val, { maxDecimalSigFig: 4 });
                            state.set({
                                ...state.get,
                                diesel: val,
                            });
                        }}
                    />)
                },
            }),
            electricityUsage: new NumberFormField({
                required: true,
                label: "Electricity usage",
                unit: "kWh/year",
                size: FieldSize.Half,
                tip: "The total amount of electricity used on your farm in the baseline year. This would include electricity used for workshops, offices, shearing sheds and pumps.",
                children: (state) => {
                    if (propertyCountryCode === CountryCode.NewZealand) return <></>
                    return (<ElectricityUseButton
                        state={state}
                        onSubmit={(val) => {
                            val = formatNumber(val, { maxDecimalSigFig: 4 });
                            state.set({
                                ...state.get,
                                electricityUsage: val,
                            });
                        }}
                    />)
                },
            }),
            percentRenewables: new NumberFormField({
                required: true,
                label: "% of Electricity sourced from Renewables",
                unit: '%',
                size: FieldSize.Half,
                validator: (value) => value !== undefined && value <= 100,
                tip: "Enter a number between 0 and 100% that reflects the percentage of electricity sourced from renewables.",
            }),
        },
        transformer: {
            in: (value) => ({
                ...value,
                percentRenewables: value.percentRenewables * 100,
            }),
            out: (value) => ({
                ...value,
                percentRenewables: value.percentRenewables / 100,
            }),
        },
    },
    helpDrawerContent: {
        title: "Adding Consumables Data",
        content: (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <HelpDrawerExplanatoryContentText style={{ fontWeight: 700 }}>Scope 2 & Scope 3</HelpDrawerExplanatoryContentText>
                <HelpDrawerExplanatoryContentText>
                    Enter the data for consumables you have <span style={{ textDecoration: 'underline' }}>sourced externally</span> on this page. Please don’t enter any renewable energy you have generated on your property.
                    </HelpDrawerExplanatoryContentText>
                <HelpDrawerExplanatoryContentText>
                    <strong>Ruminati Tip:</strong> Use the ‘Calculate’ button to enter your petrol, diesel or electricity bill and automatically calculate your consumables usage.
                </HelpDrawerExplanatoryContentText>
            </div>
        ),
        videoLink: propertyType === PropertyType.Feedlot
          ? "https://www.youtube.com/embed/cK3dVNylBgw?si=19yZZXIk0P9QrW6r&loop=1&rel=0"
          : "https://www.youtube.com/embed/tL_PUDNPZT8?si=UsSM_VDXZJkOk2t9&loop=1&rel=0",
        accordionItems: [
            {
                title: "Do I include my home’s electricity usage or personal vehicle usage in my emissions report?",
                content: (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                        <AccordionItemContentText style={{ fontWeight: 700 }}>
                            Only the operations for your business should be considered, not your residential house or personal vehicle.
                        </AccordionItemContentText>
                        <AccordionItemContentText>
                            If you have a farm office or employee accommodation, this should be considered as part of your emissions because it is part of the business operation. A good rule of thumb is to include only what you claim to the ATO.
                        </AccordionItemContentText>
                    </div>
                )
            },
            {
                title: "Do I include renewable energy generated on my property in my electricity usage or in % sourced from renewables?",
                content: "No. Only include electricity you've sourced externally and paid for. If you have a Green Power plan or similar, enter that in the “% sourced from Renewables” field."
            },
        ]
    }
});

const pastureAU = (propertyType?: PropertyType): ReportFormPage<ReportPastureForm, ReportPasture> => ({
    id: ReportFormPageId.PasturePost3_4,
    title: "Pasture",
    description:
        "For farms with cropping enterprises, this section is for pasture only.\nYou will be asked to enter the details for your crop enterprise in another section.",
    image: "pasture.png",
    formData: {
        fields: {
            ureaDetailed: new MultiInputFormField({
                options: [
                    { key: "drylandPasture", label: "Dryland Pasture" },
                    { key: "drylandCrops", label: "Dryland Fodder Crops" },
                    { key: "irrigatedPasture", label: "Irrigated Pasture" },
                    { key: "irrigatedCrops", label: "Irrigated Fodder Crops" },
                ],
                title: "Urea Usage",
                useLabelsAbove: true,
                allUnits: "tonnes of N",
                columnWidth: FieldSize.Quarter
            }),
            lime: new NumberFormField({
                required: true,
                label: "Lime",
                unit: "tonnes/year",
                size: FieldSize.Third,
                tip: "The weight of lime applied to pastures on your property for the baseline year.",
            }),
            fractionLime: new NumberFormField({
                required: true,
                label: "Percentage of Lime as limestone",
                unit: "%",
                tip: "Percentage of Lime as limestone (vs dolomite)",
                size: FieldSize.Third
            }),
            singleSuperphosphate: new NumberFormField({
                required: true,
                label: "Single Superphosphate",
                unit: "tonnes/year",
                size: FieldSize.Third,
                tip: "The weight of single superphosphate applied to pastures on your property for the baseline year.",
            }),
            sep: new SeparatorField(),
            herbicidesPesticidesUsed: createHerbicidePesticideCalculator(),
            herbicide: new NumberFormField({
                required: true,
                label: "Herbicide (eg Paraquat, Glyphosate)",
                unit: "kg a.i.",
                size: FieldSize.Half,
                tip: "The amount of herbicide applied to your pastures in the baseline year.",
            }),
            herbicidePesticide: new NumberFormField({
                required: true,
                label: "Other Herbicides/Pesticides",
                unit: "kg a.i.",
                size: FieldSize.Half,
                tip: "The amount of other general herbicide/pesticides applied to your pastures in the baseline year.",
            }),
            otherNFertilisers: new RepeatableArrayFieldWrapper({
                label: 'Other Nitrogen Used',
                repeatableConfig: {
                  repeatableSectionName: 'Other Nitrogen Purchase'
                },
                data: {
                  fields: {
                    type: new DropdownFormField({
                      required: true,
                      label: 'N Fertiliser',
                      placeholder: "N Fertiliser",
                      options: OtherNFertilisersClasses,
                      concatMethod: ConcatMethod.StringList,
                      size: FieldSize.Half
                    }),
                    amountUsed: new NumberFormField({
                        required: true,
                        label: 'Amount Used',
                        unit: 'Tonnes',
                        size: FieldSize.Half
                    }),
                    productionType: new RadioBoxFormField({
                        label: "Production System",
                        required: true,
                        options: [{
                            value: 'dryland',
                            name: 'Dryland'
                        },
                        {
                            value: 'irrigated',
                            name: 'Irrigated'
                        }]
                    })
                  }
                }
            }),
        },
        transformer: {
            in: (value) => {
                return {
                    ...value,
                    ureaDetailed: {
                        drylandPasture: value.ureaDrylandPasture,
                        drylandCrops: value.ureaDrylandCrops,
                        irrigatedPasture: value.ureaIrrigatedPasture,
                        irrigatedCrops: value.ureaIrrigatedCrops,
                    },
                    fractionLime: inputPercent(value.fractionLime)
                }
            },
            out: (value) => {
                return {
                    ...value,
                    otherNFertilisers: value.otherNFertilisers ?? [],
                    herbicidesPesticidesUsed: value.herbicidesPesticidesUsed ?? [],
                    ureaDrylandPasture: value.ureaDetailed.drylandPasture ?? 0,
                    ureaDrylandCrops: value.ureaDetailed.drylandCrops ?? 0,
                    ureaIrrigatedPasture: value.ureaDetailed.irrigatedPasture ?? 0,
                    ureaIrrigatedCrops: value.ureaDetailed.irrigatedCrops ?? 0,
                    fractionLime: outputPercent(value.fractionLime)
                }
            },
        },
    },
    ...(propertyType === PropertyType.Feedlot ? {
      helpDrawerContent: {
        title: "Adding Pasture Inputs",
        videoLink: "https://www.youtube.com/embed/obOtjUcLS8o?si=STZO93xBZzI6pnZy&loop=1&rel=0",
      }
    }: {})
});

const oldPastureAU: ReportFormPage<ReportPasturePreV3_5> = {
    id: ReportFormPageId.Pasture,
    title: "Pasture",
    description:
        "For farms with cropping enterprises, this section is for pasture only.\nYou will be asked to enter the details for your crop enterprise in another section.",
    image: "pasture.png",
    formData: {
        fields: {
            urea: new NumberFormField({
                required: true,
                label: "Urea",
                unit: "tonnes/year",
                size: FieldSize.Half,
                tip: "The weight of urea applied to pastures on your property for the baseline year.",
            }),
            lime: new NumberFormField({
                required: true,
                label: "Lime",
                unit: "tonnes/year",
                size: FieldSize.Half,
                tip: "The weight of lime applied to pastures on your property for the baseline year.",
            }),
            singleSuperphosphate: new NumberFormField({
                required: true,
                label: "Single Superphosphate",
                unit: "tonnes/year",
                size: FieldSize.Half,
                tip: "The weight of single superphosphate applied to pastures on your property for the baseline year.",
            }),
            pesticide: new NumberFormField({
                required: true,
                label: "Pesticide purchased",
                unit: "litres/year",
                size: FieldSize.Half,
                tip: "The amount of pesticide and herbicide (excluding glyphosate) purchased for use on pastures and by your livestock enterprises in the baseline year. This includes pasture herbicides (excluding glyphosate), pesticides and insecticides, as well as animal health products such as drenches, dips and vaccines",
            }),
            otherNitrogen: new NumberFormField({
                required: true,
                label: "Other Nitrogen",
                unit: "tonnes of N/year",
                size: FieldSize.Half,
                tip: "Any other nitrogen applied to pastures on your property (not included above) for the baseline year. Please enter this as total kilograms of Nitrogen, NOT fertiliser.",
            }),
            glyphosate: new NumberFormField({
                required: true,
                label: "Glyphosate",
                unit: "litres/year",
                size: FieldSize.Half,
                tip: "The amount of glyphosate chemical applied to your pastures in the baseline year.",
            }),
        }
    },
    helpDrawerContent: {
        title: "Adding Pasture Inputs",
        content: (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <HelpDrawerExplanatoryContentText style={{ fontWeight: 700 }}>Scope 1 & Scope 3</HelpDrawerExplanatoryContentText>
                <HelpDrawerExplanatoryContentText>Add your pasture inputs information on this page. Note that this page relates to pasture inputs only and isn’t relevant for cropping. You’ll enter the cropping data on a separate page.</HelpDrawerExplanatoryContentText>
            </div>
        ),
        videoLink: "https://www.youtube.com/embed/On056rZy9ZA?si=dQ3MNkUWtihbc5qQ&loop=1&rel=0",
        accordionItems: [
            {
                title: "How does the tool account for annual pasture rather than perennial?",
                content: "The Ruminati tools focuses on measuring the inputs used rather than distinguishing between annual and perennial pastures. This means that whether your pasture is annual or perennial doesn’t affect the results."
            },
            {
                title: "How can I account for crops that start as pasture, become seed and are then grazed?",
                content: (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                        <AccordionItemContentText>
                            Consider the primary purpose of the crop: for example, if the main goal is seed production, record it as a crop. Once the seed is harvested and the area is grazed in the following season, you'll need to allocate the relevant portion of the inputs to the pasture section.
                        </AccordionItemContentText>
                        <AccordionItemContentText>
                            Essentially, you should track the emissions and inputs separately based on their main usage. This approach ensures that you accurately capture the emissions associated with each phase of the crop’s lifecycle.
                        </AccordionItemContentText>
                    </div>
                )
            }
        ]
    }
};

const pastureNZ: ReportFormPage<ReportPastureNZ> = {
    id: ReportFormPageId.Pasture,
    title: "Pasture",
    description:
        "For farms with cropping enterprises, this section is for pasture only.\nYou will be asked to enter the details for your crop enterprise in another section.",
    image: "pasture.png",
    formData: {
        fields: {
            urea: new NumberFormField({
                required: true,
                label: "Urea",
                unit: "kg/year",
                size: FieldSize.Half,
                tip: "The weight of urea applied to pastures on your property for the baseline year.",
            }),
            lime: new NumberFormField({
                required: true,
                label: "Lime",
                unit: "kg/year",
                size: FieldSize.Half,
                tip: "The weight of lime applied to pastures on your property for the baseline year.",
            }),
            singleSuperphosphate: new NumberFormField({
                required: true,
                label: "Single Superphosphate",
                unit: "kg/year",
                size: FieldSize.Half,
                tip: "The weight of single superphosphate applied to pastures on your property for the baseline year.",
            }),
            pesticide: new NumberFormField({
                required: true,
                label: "Pesticide purchased",
                unit: "litres/year",
                size: FieldSize.Half,
                tip: "The amount of pesticide and herbicide (excluding glyphosate) purchased for use on pastures and by your livestock enterprises in the baseline year. This includes pasture herbicides (excluding glyphosate), pesticides and insecticides, as well as animal health products such as drenches, dips and vaccines",
            }),
            glyphosate: new NumberFormField({
                required: true,
                label: "Glyphosate",
                unit: "litres/year",
                size: FieldSize.Half,
                tip: "The amount of glyphosate chemical applied to your pastures in the baseline year.",
            }),
            diamoniumPhosphate: new NumberFormField({
                required: true,
                label: "Diamonium Phosphate",
                unit: "kg/year",
                size: FieldSize.Half,
                tip: "The amount of diamonium phosphate chemical applied to your pastures in the baseline year.",
            }),
            tripleSuperphosphate: new NumberFormField({
                required: true,
                label: "Triple Superphosphate",
                unit: "kg/year",
                size: FieldSize.Half,
                tip: "The amount of triple superphosphate chemical applied to your pastures in the baseline year.",
            }),
            ammoniumSuphate: new NumberFormField({
                required: true,
                label: "Ammonium Sulphate",
                unit: "kg/year",
                size: FieldSize.Half,
                tip: "The amount of ammonium sulphate chemical applied to your pastures in the baseline year.",
            }),
            calciumAmmoniumNitrate: new NumberFormField({
                required: true,
                label: "Calcium Ammonium Nitrate",
                unit: "kg/year",
                size: FieldSize.Half,
                tip: "The amount of calcium ammonium nitrate chemical applied to your pastures in the baseline year.",
            }),
            muriatePotash: new NumberFormField({
                required: true,
                label: "Muriate of Potash",
                unit: "kg/year",
                size: FieldSize.Half,
                tip: "The amount of muriate of potash chemical applied to your pastures in the baseline year.",
            }),
            otherNitrogen: new NumberFormField({
                required: true,
                label: "Other Nitrogen",
                unit: "kg/year",
                size: FieldSize.Full,
                tip: "Any other nitrogen applied to pastures on your property (not included above) for the baseline year. Please enter this as total kilograms of Nitrogen, NOT fertiliser.",
            }),
        },
        transformer: {
            in: (value) => ({
                ...value,
                urea: value.urea * 1000,
                lime: value.lime * 1000,
                singleSuperphosphate: value.singleSuperphosphate * 1000,
                otherNitrogen: value.otherNitrogen * 1000,
            }),
            out: (value) => ({
                ...value,
                // NZ has some unit changes (kg to tonnes)
                urea: (value.urea ?? 0) / 1000,
                lime: (value.lime ?? 0) / 1000,
                singleSuperphosphate: (value.singleSuperphosphate ?? 0) / 1000,
                otherNitrogen: (value.otherNitrogen ?? 0) / 1000,
            }),
        },
    },
};

export const pasture = (countryCode = CountryCode.Australia, algorithmVersion: number, propertyType?: PropertyType) => {
    if (countryCode === CountryCode.NewZealand) return pastureNZ;
    if (algorithmVersion < 3.5) return oldPastureAU;
    return pastureAU(propertyType)
}

export const trees = (region: number, geom: MultiPolygon, existingTreesOnProperty: ReportTrees[], reportId: string | undefined, propertyCountryCode = CountryCode.Australia, propertyType?: PropertyType): ReportFormPage<ReportTrees> => {
    const isUserNZ = (propertyCountryCode === CountryCode.NewZealand);

    const vegetationOptions = isUserNZ ? nzVegetationTypes() : vegetationTypes(region);
    return ({
        id: ReportFormPageId.Trees,
        title: "Trees",
        description: existingTreesOnProperty.filter(t => t.reportId !== reportId).length === 0 && reportId ?
            "Enter information about trees on your property that were planted since 1990." : "Enter information about any trees planted since 1990 that you haven't mapped in other reports",
        repeatableConfig: {
          repeatableSectionName: "Tree Planting",
          summaryScreenCountName: "Tree Plantings",
        },
        image: "trees.png",
        formData: {
            fields: {
                species: new DropdownFormField({
                    required: true,
                    label:
                        vegetationOptions.length > 0
                            ? "Vegetation type (select the closest match)"
                            : "No tree data available",
                    placeholder: "Vegetation type",
                    summaryLabel: "Vegetation type",
                    options: vegetationOptions,
                    tip: "Enter the nearest vegetation type from the list for the patch/s of vegetation. Only trees planted since 1990 should be entered.",
                }),
                ...isUserNZ ? {} : {
                    soilType: new DropdownFormField({
                        required: true,
                        concatMethod: ConcatMethod.Hide,
                        placeholder: "Soil type",
                        label:
                            soilTypes(region).length > 0
                                ? "Soil type"
                                : "No soil data available",
                        options: soilTypes(region),
                        tip: "Enter the nearest matching soil type for the area. If you're not sure just select the first option in the dropdown.",
                    })
                },
                age: new NumberFormField({
                    type: NumberType.Int,
                    required: true,
                    label: "Age of planting",
                    unit: "years",
                    validator: (value) => value !== undefined && value > 0 && value <= 100,
                    tip: "The number of years since the trees were planted. If they were planted less than a year ago, put 1.",
                }),
                area: new NumberFormField({
                    required: true,
                    label: "Area",
                    unit: "hectares",
                    children: (state) => {
                        const reportGeoms = state.getAll?.filter((f, i) => i !== state.index && f.geom).map(f => f.geom).filter(g => g !== undefined)
                        const {
                            otherReportsTreeLayerAndLegend,
                            reportTreeLayerAndLegend
                        } = createTreeLayers(existingTreesOnProperty, reportId, reportGeoms, state.get.geom)

                        return (<CalculateAreaButton
                            state={state}
                            propertyGeom={geom}
                            additionalLayers={[
                                otherReportsTreeLayerAndLegend.layer,
                                reportTreeLayerAndLegend.layer
                            ]}
                            additionalLegendItems={[
                                otherReportsTreeLayerAndLegend.legend,
                                reportTreeLayerAndLegend.legend,
                                <LegendItem key="this-actual-plantings-legend">
                                    <LegendSwatch color={'#F3DA064D'} borderColor={'#F3DA06'} borderWidth={'2px'} />
                                    Planting (current)
                                </LegendItem>
                            ]}
                            onSubmit={(obj: DrawAndCalculateAreaPopupReturns) => {
                                const area = formatNumber(obj.area, {
                                    maxDecimalSigFig: 4,
                                });
                                state.set({
                                    ...state.get,
                                    area,
                                    geom: obj.geom !== null ? obj.geom : undefined
                                });
                            }}
                        />)
                    },
                    tip: "The area can be made up of multiple patches of vegetation that have the same vegetation type, soil type and age.",
                }),
            },
        },
        helpDrawerContent: {
            title: "Adding Tree Plantings",
            content: (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <HelpDrawerExplanatoryContentText style={{ fontWeight: 700 }}>Sequestration</HelpDrawerExplanatoryContentText>
                    <HelpDrawerExplanatoryContentText>Enter any tree plantings on this page. If you’ve done multiple plantings, click on the ‘Add Tree Planting’ button again to add any additional plantings on your property.</HelpDrawerExplanatoryContentText>
                    <HelpDrawerExplanatoryContentText><strong>Ruminati Tip:</strong> If you don’t know the exact area of your planting, use the ‘Calculate Area From Map’ button to draw where the plantings are on your property using the map tool.</HelpDrawerExplanatoryContentText>
                </div>
            ),
            videoLink: propertyType === PropertyType.Feedlot
              ? "https://www.youtube.com/embed/no3BkoMMuYw?si=A0aQC98jyoYNGPqF&loop=1&rel=0"
              : "https://www.youtube.com/embed/9_yvRBaBC4I?si=mkJFjd9Tla5u1No5&loop=1&rel=0",
            accordionItems: [
                {
                    title: "Why can I only count trees from 1990 onwards?",
                    content: (
                        <AccordionItemContentText>
                            The Ruminati tool counts trees from 1990 onward to align with <a style={{ display: 'inline'}} target="_blank" href="https://www.climateactive.org.au/sites/default/files/2022-09/Draft%20Guideline%20-%20Accounting%20for%20Carbon%20Sequestration%20from%20Tree%20Planting....pdf"><TextLink>Climate Active's draft guidelines</TextLink></a>, which allow tree planting to reduce a farm's baseline emissions. We follow the Meat and Livestock Association's (MLA) CN30 target approach, recognising tree growth in a farm's carbon account to credit producers for land stewardship and emission reduction. This differs from the NGGI approach, which only accounts for agricultural emissions, not vegetation growth. If you need to align with NGGI, you can opt out of including trees in your carbon footprint calculation.
                        </AccordionItemContentText>
                    )
                },
                {
                    title: "What if my property’s soil or trees are not in the drop-down selection?",
                    content: "Our drop-down options come directly from the original GAF tools, pre-selected to run through FullCAM for tree sequestration results. If your soil or trees aren't listed, just pick the type that's most similar. As the GAF tools expand their selections, we'll update the Ruminati platform to reflect those changes."
                }
            ]
        }
    });
};

export function supplementaryFeed(title?: string): ReportFormPage<ReportSupplmentaryFeed> {
    return {
        id: ReportFormPageId.SupplementaryFeed,
        title: title ?? "Supplementary Feed",
        image: "supplementary-feed.png",
        formData: {
            fields: {
                grain: new NumberFormField({
                    required: true,
                    label: "Grain",
                    unit: "tonnes",
                    size: FieldSize.Third,
                    tip: "The total tonnes of grain (excluding cottonseed) purchased by your farm for livestock feed in the baseline year.",
                }),
                hay: new NumberFormField({
                    required: true,
                    label: "Hay",
                    unit: "tonnes",
                    size: FieldSize.Third,
                    tip: "The total tonnes of hay purchased by your farm for livestock feed in the baseline year. If hay is produced on your farm, ensure you have included the relevant inputs (diesel, fertiliser etc.) in the previous sections. This hay should not be included here.",
                }),
                cottonSeed: new NumberFormField({
                    required: true,
                    label: "Cottonseed",
                    unit: "tonnes",
                    size: FieldSize.Third,
                    tip: "The total tonnes of cottonseed purchased by your farm for livestock feed in the baseline year.",
                }),


            },
        },
        ...(title === "Livestock Feed" ? {
          helpDrawerContent: {
            title: "Adding Livestock Feed Data",
            videoLink: "https://www.youtube.com/embed/_KZLOg7UD5Y?si=U3IuoDAHKcCNRHzs&loop=1&rel=0"
          }
        } : {})
    }
}

export function mineralSupplements(title?: string): ReportFormPage<ReportMineralSupplements> {
    return {
        id: ReportFormPageId.MineralSupplements,
        title: title ?? "Mineral Supplements",
        image: "supplementary-feed.png",
        repeatableConfig: {
            repeatableSectionName: "Mineral Supplement",
            repeatableSectionCallback: (formData: Partial<ReportMineralSupplements>) => {
                const supplement = mineralSupplementTypes.find(c => c.value === formData.supplementType)
                return `${supplement ? `Mineral Supplement - ${supplement.name}` : 'Mineral Supplement'}`
            }
        },
        formData: {
            fields: {
                supplementType: new DropdownFormField({
                    required: true,
                    concatMethod: ConcatMethod.StringList,
                    label: "Supplement type",
                    placeholder: "Supplement type",
                    summaryLabel: "Supplement type",
                    options: mineralSupplementTypes,
                    size: FieldSize.Third
                }),
                tonnesOfProduct: new NumberFormField({
                    required: true,
                    label: "Weight of product",
                    unit: "tonnes",
                    size: FieldSize.Third

                }),
                proportionUrea: new NumberFormField({
                    required: true,
                    label: "Proportion urea",
                    unit: '%',
                    validator: (value) => value !== undefined && value <= 100,
                    size: FieldSize.Third

                }),
            },
            transformer: {
                in: (value) => ({
                    ...value,
                    proportionUrea: inputPercent(value.proportionUrea),
                }),
                out: (value) => ({
                    ...value,
                    proportionUrea: outputPercent(value.proportionUrea),
                }),
            },
        },
    }
}

export function allSupplementaryFeedForms(): ReportPageMultipleForms {
    return {
        id: 'all-supplementary-feed-forms',
        title: "Supplementary Feed",
        image: "supplementary-feed.png",
        formComponents: [
            supplementaryFeed(""),
            mineralSupplements()
        ],
        helpDrawerContent: {
            title: "Adding Supplementary Feed",
            content: (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <HelpDrawerExplanatoryContentText style={{ fontWeight: 700 }}>Scope 3</HelpDrawerExplanatoryContentText>
                    <HelpDrawerExplanatoryContentText>Enter your supplementary feed purchases on this page. If your answer is zero, make sure to enter 0 into the field. You can also add any mineral supplements your purchased. Click on the ‘Add Mineral Supplement’ button again to add any additional purchases you made.</HelpDrawerExplanatoryContentText>
                </div>
            ),
            videoLink: "https://www.youtube.com/embed/4LcUyEt9toE?si=SQBzWbjIbwTLr9t6&loop=1&rel=0",
            accordionItems: [
                {
                    title: "Why does the tool ask for feed ‘purchased’ instead of amount used?",
                    content: "We ask for feed purchased because that’s the standard practice in GAF and inventory tools, and it’s typically the most straightforward for you to provide. However, if you’re particularly focused on tracking the actual amount used rather than purchased, you’re welcome to input that data instead, as long as you keep it consistent from year to year."
                }
            ]
          }
    }
}

type CattlePurchaseOptions = {
    isNzProperty: boolean,
    propertyType: PropertyType
}

export function cattlePurchases(options:CattlePurchaseOptions): ReportFormPage<ReportCattlePurchases | ReportFeedlotCattlePurchases> {
    const isFurtherProcessor = options.propertyType === PropertyType.FurtherProcessor
    const sectionName = isFurtherProcessor ? "Beef Product Purchase" : "Cattle Purchase"
    return {
        id: ReportFormPageId.CattlePurchases,
        title: `${sectionName}s`,
        repeatableConfig: {
            repeatableSectionName: sectionName,
            repeatableSectionCallback: (formData: Partial<LivestockClassAUS>) => {
                const cattleClass = AusBeefClasses.find(c => c.value === formData.classId)
                const ccName = cattleClass ? cattleClass?.name : sectionName
                return `${ccName}${formData.name ? ` - ${formData.name}` : ''}`
            }
        },
        image: "cattle-purchases.png",
        formData: {
            fields: {
                origin: !options.isNzProperty ? new DropdownFormField({
                    required: true,
                    label: "Origin of cattle",
                    options: cattlePurchaseRegions,
                    concatMethod: ConcatMethod.StringList,
                    size: !options.isNzProperty ? FieldSize.Half : FieldSize.Full,
                    tip: "Select the region where cattle were purchased from in the baseline year. If you purchased cattle from more than one region, you can add additional locations by selecting 'add another region' below.",
                }) : new HiddenFormField(),
                name: new TextFormField({
                    label: "Identifying Name",
                    required: false,
                    size: !options.isNzProperty ? FieldSize.Half : FieldSize.Full
                }),
                averageWeightPurchased: new NumberFormField({
                    required: true,
                    label: isFurtherProcessor ? 'Total weight purchased' : 'Average weight of animal',
                    unit: isFurtherProcessor ? "tonnes" : "kg/hd",
                    concatMethod: ConcatMethod.Average,
                    tip: isFurtherProcessor ? 'The total liveweight in tonnes of cattle purchased by your business from this region in the baseline year.' : 'The average liveweight of an animal purchased by your business from this region in the baseline year.',
                    size: FieldSize.Half,
                }),
                numberPurchased: !isFurtherProcessor ? new NumberFormField({
                    required: true,
                    label: "Number purchased",
                    type: NumberType.Int,
                    concatMethod: ConcatMethod.Total,
                    tip: "The number of cattle purchased by your business from this region in the baseline year.",
                    size: FieldSize.Half,
                }) : new HiddenFormField(),
                ...(
                    options.propertyType === PropertyType.Feedlot ? {
                        // Feedlot-specific fields
                        distanceTransported: new NumberFormField({
                            required: true,
                            label: "Distance transported to farm",
                            unit: "KM/year",
                            concatMethod: ConcatMethod.Average,
                            size: FieldSize.Half,
                        }),
                        truckType: new DropdownFormField({
                            required: true,
                            label: "Type of truck used",
                            options: truckTypes,
                            size: FieldSize.Half,
                        }),
                    } : {}
                )
            },
            transformer: {
                in: (value) => ({
                    ...value,
                    averageWeightPurchased: isFurtherProcessor ? value.averageWeightPurchased / 1000 : value.averageWeightPurchased,
                    numberPurchased: !isFurtherProcessor ? value.numberPurchased : 1,
                }),
                out: (value) => ({
                    ...value,
                    origin: value.origin ?? (options.isNzProperty ? 14 : 0), // NZ is region 14.
                    averageWeightPurchased: isFurtherProcessor ? value.averageWeightPurchased * 1000 : value.averageWeightPurchased,
                    numberPurchased: !isFurtherProcessor ? value.numberPurchased : 1
                })
            }
        },
        helpDrawerContent: {
            title: "Adding Cattle Purchases",
            content: (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <HelpDrawerExplanatoryContentText style={{ fontWeight: 700 }}>Scope 3</HelpDrawerExplanatoryContentText>
                    <HelpDrawerExplanatoryContentText>Use this page to add your cattle purchases for the year. Click on the ‘Add Cattle Purchase’ button again to add any additional purchases you made.</HelpDrawerExplanatoryContentText>
                </div>
            ),
            videoLink: options.propertyType === PropertyType.Feedlot
              ? "https://www.youtube.com/embed/JEKPZL_V2yc?si=51TVrCmFY-vcwm1a&loop=1&rel=0"
              : "https://www.youtube.com/embed/m2CsfrA-ksA?si=34lqAkbcrAInou08&loop=1&rel=0",
            accordionItems: [
                {
                    title: "Can I group 'purchased cattle' coming from the same region together if I average out the weight?",
                    content: "Yes, you can group 'purchased cattle' from the same vendor or region together and average their weight at purchase, as long as the weights aren’t drastically different. While there might be some minor variations in the final result due to using an average weight, it generally won’t make a significant impact."
                },
                {
                    title: "Why do I need to include the origin of the cattle?",
                    content: "Because of different pasture types across the country, the emissions factor changes depending on where your cattle are from."
                },
            ]
          }
    }
}

export function sheepPurchases(isFurtherProcessor?: boolean): ReportFormPage<ReportSheepPurchases> {
    const sectionName = isFurtherProcessor ? "Sheep Product Purchase" : "Sheep Purchase"
    return {
        id: ReportFormPageId.SheepPurchases,
        title: `${sectionName}s`,
        repeatableConfig: {
            repeatableSectionName: sectionName,
            repeatableSectionCallback: (formData: Partial<LivestockClassAUS>) => {
                const sheepClass = AusBeefClasses.find(c => c.value === formData.classId)
                const ccName = sheepClass ? sheepClass?.name : sectionName
                return `${ccName}${formData.name ? ` - ${formData.name}` : ''}`
            }
        },
        image: "sheep-purchases.png",
        formData: {
            fields: {
                averageWeightPurchased: new NumberFormField({
                    required: true,
                    concatMethod: ConcatMethod.Average,
                    label: `${!isFurtherProcessor ? 'Average' : 'Total'} weight purchased`,
                    unit: isFurtherProcessor ? "tonnes" : "kg lwt/hd",
                    tip: `The ${!isFurtherProcessor ? 'average live weight in kilograms' : 'total weight of product'} at time of purchase, of all sheep made in this purchase.`,
                    size: FieldSize.Half
                }),
                name: new TextFormField({
                    label: "Identifying Name",
                    required: false,
                    size: FieldSize.Half
                }),
                numberPurchased: !isFurtherProcessor ? new NumberFormField({
                    required: true,
                    concatMethod: ConcatMethod.Total,
                    label: "Number purchased",
                    type: NumberType.Int,
                    size: FieldSize.Half,
                    tip: "The number of sheep purchased.",
                }) : new HiddenFormField(),
                proportionMerino: new NumberFormField({
                    required: true,
                    concatMethod: ConcatMethod.Average,
                    label: "Proportion that were merino",
                    unit: "%",
                    size: FieldSize.Half,
                    tip: "A higher emissions factor is used for merino sheep as they are generally lower yielding per kg of carbon dioxide equivalent emitted.",
                    validator: (value) => value !== undefined && value <= 100,
                }),
            },
            transformer: {
                in: (value) => ({
                    ...value,
                    averageWeightPurchased: isFurtherProcessor ? value.averageWeightPurchased / 1000 : value.averageWeightPurchased,
                    numberPurchased: !isFurtherProcessor ? value.numberPurchased : 1,
                    proportionMerino: inputPercent(value.proportionMerino)
                }),
                out: (value) => ({
                    ...value,
                    averageWeightPurchased: isFurtherProcessor ? value.averageWeightPurchased * 1000 : value.averageWeightPurchased,
                    numberPurchased: !isFurtherProcessor ? value.numberPurchased : 1,
                    proportionMerino: outputPercent(value.proportionMerino)
                })
            },
        },
        helpDrawerContent: {
            title: "Adding Sheep Purchases",
            content: (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <HelpDrawerExplanatoryContentText style={{ fontWeight: 700 }}>Scope 3</HelpDrawerExplanatoryContentText>
                    <HelpDrawerExplanatoryContentText>Use this page to add your sheep purchases for the year. Click on the ‘Add Sheep Purchase’ button again to add any additional purchases you made.</HelpDrawerExplanatoryContentText>
                </div>
            ),
            videoLink: "https://www.youtube.com/embed/syOKFyYNnoo?si=hqJEisM220JjgioE&loop=1&rel=0",
            accordionItems: [
                {
                    title: "If I have cross-bred Merino sheep, do I classify them as Merino?",
                    content: "No. Only enter the percentage of sheep you have purchased that are full-blooded Merino. Merino sheep have a higher emissions factor because of their higher body weight- they eat more and therefore produce more emissions."
                }
            ]
          }
    }
}

export function createTreeLayers(existingTreesOnProperty: ReportTrees[], reportId: string | undefined, reportGeoms: MultiPolygon[] | undefined, fieldGeom: MultiPolygon | undefined) {
    const existingTreesNotInReport = existingTreesOnProperty?.filter(t => t.reportId !== reportId)
    const existingTreesNotInReportGeoms = existingTreesNotInReport ? existingTreesNotInReport.filter(t => t.geom).map(f => f.geom) as MultiPolygon[] : []
    const otherReportsTreeLayerAndLegend = createExistingTreePlantingLayerAndLegend(existingTreesNotInReportGeoms, "Plantings in other Reports")

    let existingTreeGeomsInReport = undefined
    if (reportGeoms !== undefined || fieldGeom !== undefined) {
        existingTreeGeomsInReport = reportGeoms?.filter(d => d && d.coordinates[0][0][0][0] !== fieldGeom?.coordinates[0][0][0][0])
    }
    const reportTreeLayerAndLegend = createProposedTreePlantingLayerAndLegend(existingTreeGeomsInReport, "Plantings in this Report")
    return {
        otherReportsTreeLayerAndLegend,
        reportTreeLayerAndLegend
    }
}

export function createHerbicidePesticideCalculator () {
  return new CustomFieldWithState({
      content: (state: State<ReportPastureForm | ReportGrains_Post3_3Form>) => {
          const herbicidePesticidesUsed = state.get.herbicidesPesticidesUsed ?? []
          return <Row>
              <Column>
                  <Row style={{justifyContent: 'flex-start', width: '100%', marginBottom: '10px'}}>
                      <BodyText style={{fontSize: '16px'}}>
                          Herbicides & Pesticides
                      </BodyText>
                  </Row>
                  <Row>
                      <InfoCard content="Enter the herbicides and pesticides you use below, this will calculate the Active Ingredient amounts for you. If you can't find the herbicide or pesticide used you'll need to enter the active ingredient figures yourself below."/>
                  </Row>
                  <Row style={{columnGap: '8px', marginTop: '20px'}}>
                      {herbicidePesticidesUsed.map((f, idx) => {
                          const herbicide = combinedHerbicideAndHerbicidePesticideOptions.find(fo => fo.id === f.id)
                          return <SmallButton
                            colorScheme="outline"
                            key={idx}
                            onClick={() => {
                              const newHerbicidePesticides = herbicidePesticidesUsed.slice()
                              newHerbicidePesticides.splice(idx, 1)
                              const resultActiveIngredients = calculateHerbicideActiveIngredients(newHerbicidePesticides)
                              state.set({
                                  ...state.get,
                                  herbicide: resultActiveIngredients.herbicide,

                                  // Note Pasture Form and Grain form name these next 2 things
                                  // differently but they are in effect the same
                                  herbicidePesticide: resultActiveIngredients.herbicidePesticide,
                                  herbicidePesticideApplied: resultActiveIngredients.herbicidePesticide,

                                  herbicidesPesticidesUsed: newHerbicidePesticides
                              })
                            }}
                            >
                              <span style={{marginRight: '8px'}}>
                                  <Icon icon="bin" />
                              </span>
                              {f.rateApplied} (L or kg)/ha of {herbicide?.name}
                          </SmallButton>
                      })}
                      <HerbicideCalculator state={state}/>
                  </Row>
              </Column>
          </Row>
      }
  })
}
