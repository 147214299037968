import styled from "styled-components";
import { useSearchParams } from "react-router-dom";

import Screen from "../screen";

import Tabs from "../../components/tabs";
import AccountSubscriptionTab from "./org_subscription_tab";
import MemberTabFarmOrg from "./member_tab_farm_org";
import MemberTabPartnerOrg from "./member_tab_partner_org";
import { Column, Row } from "../../components/styled_layout";
import Heading from "../../components/heading";
import { useAuthStore } from "@/state/auth_store";
import { getCurrentOrganisation } from "@/utilities/organisations";
import OrgIntegrationsTab from "./org_integrations_tab";
import OrganisationGeneralInformationTab from "./organisation_profile_tab";
import { InfoCard } from "@/components/card";
import { OrganisationT, OrganisationTypeFarmTB, OrganisationTypeRecordsTB } from "@ruminati/types/organisation";
import OrgClientTab from "./org_client_tab";
import OrgPartnersTab from "./partners_tab";

type PageDetails = {
  id: string
  tabLabel: string
  content: JSX.Element,
}

export default function OrganisationSettingsScreen() {
    
    function getPageById (id: string): PageDetails | undefined {
        return pages.find(p => p.id === id)
    }

    function getPages (currentOrg: OrganisationT | undefined): PageDetails[] {
      if (!currentOrg) return []

      const pages: PageDetails[] = [
        {
          id: 'general',
          tabLabel: 'General',
          content: <OrganisationGeneralInformationTab />,
        },
        {
          id: 'subscription',
          tabLabel: 'Subscription',
          content: <AccountSubscriptionTab />,
        },
        {
          id: 'access_management',
          tabLabel: 'Members',
          content: currentOrg.type === OrganisationTypeRecordsTB.const ? 
            <MemberTabPartnerOrg /> :
            <MemberTabFarmOrg />
        }
      ]

      if (currentOrg.type === OrganisationTypeRecordsTB.const) {
        pages.push({
          id: 'clients',
          tabLabel: 'Clients',
          content: <OrgClientTab />
        })
      } else if (currentOrg.type === OrganisationTypeFarmTB.const) {
        pages.push({
          id: 'partners',
          tabLabel: 'Partners',
          content: <OrgPartnersTab />
        })
        pages.push({
          id: 'integrations',
          tabLabel: 'Integrations Management',
          content: <OrgIntegrationsTab />
        })
      }

      return pages
    }

    const authStore = useAuthStore()

    const user = authStore.user
    const currentOrg = user ? getCurrentOrganisation(authStore.user!) : undefined
    const hasMoreThanOneOrg = user!.organisations.length > 1

    const pages = getPages(currentOrg)

    const [searchParams, setSearchParams] = useSearchParams()
    const selectedTabId = searchParams.get('tab') ?? pages[0].id

    const selectedIndex = pages.findIndex(p => p.id === selectedTabId)
    const selectedPage = getPageById(selectedTabId)

    return (
        <Screen pageTitle="Organisation">
            <AccountMasterContainer>
                <Row style={{ justifyContent: "space-between", marginBottom: '20px'}}>
                  <Heading level={3}>Organisation Settings - { currentOrg?.name }</Heading>
                </Row>

                <Row style={{ marginBottom: '20px'}}>
                  <InfoCard content={`Organisation Settings allows you to manage information for ${currentOrg?.name}. ${hasMoreThanOneOrg ? "If you'd like to manage information for a different organisation use the Organisation switcher in the top right of the menu." : ""}`} />
                </Row>
                <Row>
                    <Column style={{ alignSelf: "center", width: "100%" }}>
                        <Tabs
                            selected={selectedIndex}
                            tabs={pages.map((v) => {
                                return {text: v.tabLabel}
                            })}
                            onTabChange={(index: number) => {
                                const newTab = pages[index]
                                setSearchParams({
                                    tab: newTab.id
                                })
                            }}
                            layoutType="Line"
                        />
                        <AccountPageContainer id={"organisation-page"}>
                            {selectedPage?.content}
                        </AccountPageContainer>
                    </Column>
                </Row>
            </AccountMasterContainer>
        </Screen>
    );
}

export const AccountPageContainer = styled.div`
    width: 100%;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
`;

export const AccountMasterContainer = styled.div`
    width: 936px;
    display: inline-flex;
    flex-direction: column;
    padding-top: 60px;
    margin-bottom: 40px;
`;
