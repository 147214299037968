import { Avatar, Button } from "antd"

import { Column, Row } from "../styled_layout"
import { LooserBodyText } from "../styled_text"
import { usePartnerStore } from "@/state/partners"
import { ruminatiColors } from "@/utilities/colors"
import { CheckCircleOutlined } from "@ant-design/icons"
import { useAuthStore } from "@/state/auth_store"
import { getCurrentOrganisation } from "@/utilities/organisations"

export default function ShareInvite (props: {
  inviteId: string
}) {
  const partnerStore = usePartnerStore()
  const authStore = useAuthStore();

  const currentOrg = getCurrentOrganisation(authStore.user!);

  const invitations = partnerStore.invitations ?? []
  const invite = invitations.find(i => i.invite.id === props.inviteId)
  const linkedFarms = partnerStore.linkedFarms?.filter(lf => lf.linkedFarm.visionOrgId === invite?.invite.visionOrgId)
  const haveNotShared = linkedFarms?.length === 0

  return <>
  <Row style={{
    width: '100%',
    justifyContent: 'flex-start',
    marginBottom: '12px'
  }}>
    <Column style={{
      flexGrow: 1,
      alignItems: 'flex-start'
      }}>
      <LooserBodyText style={{
        color: ruminatiColors.green_4
      }}>
        {currentOrg?.name} has been invited to share its Emissions data by {invite?.visionOrganisation.name}.
      </LooserBodyText>
    </Column>
    <Column style={{width: '40px', marginLeft: '16px'}}>
      <Avatar src={invite?.visionOrganisation.icon_small_url} size={40}/>
    </Column>
  </Row>
  <Row style={{
    width: '100%', 
    justifyContent: 
    'flex-start', 
    columnGap: '10px'
  }}>
    {haveNotShared ? <>
        <Button type="primary" size="small" ghost>Share Now</Button>
        <Button size="small" type="link">View Request</Button>
      </> : <>
        <LooserBodyText>
          <CheckCircleOutlined style={{marginRight: '10px'}}/>
          Actioned
        </LooserBodyText>
      </>
    }
  </Row>
</>
}