import { BodyText } from "../styled_text";
import { Row, Column } from "../styled_layout";

import { INTERNAL_FARM_ORG_ROLES, PARTNER_ORG_ROLES } from "@ruminati/permissions/roles"
import { useAuthStore } from "@/state/auth_store";
import { getCurrentOrganisation } from "@/utilities/organisations";
import { OrganisationTypeRecordsTB } from "@ruminati/types/organisation";

export default function RoleDescriptionPopup() {
  const authStore = useAuthStore()

  const currentOrg = getCurrentOrganisation(authStore.user!);

  const availableRoles = currentOrg?.type === OrganisationTypeRecordsTB.const ? PARTNER_ORG_ROLES : INTERNAL_FARM_ORG_ROLES

  return (
    <Row
      style={{
        width: "600px",
        justifyContent: "flex-start",
      }}
    >
      <Column
        style={{
          width: "100%",
          justifyContent: "flex-start",
          padding: "40px 40px",
        }}
      >
        {availableRoles.map((r, idx) => {
          return (
            <Row
              key={idx}
              style={{
                width: "100%",
                justifyContent: "flex-start",
                marginTop: idx > 0 ? '20px' : undefined
              }}
            >
              <Column>
                <Row
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                  }}
                >
                  <BodyText style={{fontWeight: 700, fontSize: '16px', marginBottom: '4px'}}>
                    {r.name}
                  </BodyText>
                </Row>
                <Row
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                  }}
                >
                  <BodyText style={{lineHeight: '20px'}}>{r.description}</BodyText>
                </Row>
              </Column>
            </Row>
          );
        })}
      </Column>
    </Row>
  );
}
