import { Avatar, Badge, Popover } from "antd"
import { BellFilled, UserOutlined } from "@ant-design/icons"

import { useAuthStore } from "@/state/auth_store"

import { BodyText } from "../styled_text"
import { Column, Row } from "../styled_layout"
import { ruminatiColors } from "@/utilities/colors"
import { getCurrentOrganisation } from "@/utilities/organisations"

import AccountMenuItemContent from "./account_menu_item_content"
import { useNotifications } from "@/hooks/notifications"
import NotificationPanel from "./notification_panel"


export default function AccountMenuItem() {
  const authStore = useAuthStore()
  const {
    data: userNotifications
  } = useNotifications()

  const user = authStore.user
  const currentOrg = user ? getCurrentOrganisation(authStore.user!) : undefined
  const hasMoreThanOneOrg = user!.organisations.length > 1

  return (
      <Row style={{ gap: "20px", cursor: "pointer" }}>
        <Column style={{ alignItems: "flex-end", cursor: 'auto' }}>
          <Row>
            <BodyText
              style={{
                color: ruminatiColors.dark_green,
                fontSize: "15px",
              }}
            >
              {user!.firstName} {user!.lastName}
            </BodyText>
          </Row>
          <Row>
            <BodyText
              style={{
                color: ruminatiColors.green_4,
              }}
            >
              {currentOrg?.name}
            </BodyText>
          </Row>
        </Column>
        <Column>
        <Popover
            trigger="click"
            content={<NotificationPanel />}
            placement="bottomRight"
            color={ruminatiColors.bone}
            overlayStyle={{
              width: "400px",
            }}
            overlayInnerStyle={{
              padding: "0px",
            }}
          >
            {/* Whack in some extra padding 
            for clickability */}
            <span style={{padding: '4px'}}>
              <Badge
                size="small"
                count={userNotifications?.filter(n => !n.read).length}
                color={ruminatiColors.red}
              >
                <BellFilled
                style={{
                  color: ruminatiColors.green_4,
                  fontSize: '18px'
                }}/>
              </Badge>
            </span>
          </Popover>
        </Column>
        <Column>
          <Popover
            trigger="click"
            content={<AccountMenuItemContent />}
            placement="bottomRight"
            color={ruminatiColors.bone}
            overlayStyle={{
              width: hasMoreThanOneOrg ? "560px" : "220px",
            }}
            overlayInnerStyle={{
              padding: "0px",
            }}
          >
            <Avatar
              icon={<UserOutlined/>}
              style={{
                backgroundColor: ruminatiColors.green_4
               }}
            />
          </Popover>

        </Column>
      </Row>
  );
}
