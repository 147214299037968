import { Type, Static } from '@sinclair/typebox'

export const PermissionOperationCreateTB = Type.Literal('create')
export const PermissionOperationReadTB = Type.Literal('read')
export const PermissionOperationUpdateTB = Type.Literal('update')
export const PermissionOperationDeleteTB = Type.Literal('delete')

export const PermissionOperationTB = Type.Union([
  PermissionOperationCreateTB,
  PermissionOperationReadTB,
  PermissionOperationUpdateTB,
  PermissionOperationDeleteTB
])
export type PermissionOperationT = Static<typeof PermissionOperationTB>

export const PermissionRoleCreatorTB = Type.Literal('creator')
export const PermissionRoleViewerTB = Type.Literal('viewer')
export const PermissionRoleEditorTB = Type.Literal('editor')
export const PermissionRoleOwnerTB = Type.Literal('owner')
export const PermissionRoleMemberTB = Type.Literal('member')
export const PermissionRoleReportManagerTB = Type.Literal('reportManager')
export const PermissionRoleReportViewerTB = Type.Literal('reportViewer')
export const PermissionRoleAdministratorTB = Type.Literal('administrator')
// roles
export const PermissionRoleOrgAdminTB = Type.Literal('orgAdmin')
export const PermissionRoleReportManagerInternalTB = Type.Literal('reportManagerInternal')
export const PermissionRoleReportManagerExternalTB = Type.Literal('reportManagerExternal')
export const PermissionRoleReportViewerInternalTB = Type.Literal('reportViewerInternal')
export const PermissionRoleReportViewerExternalTB = Type.Literal('reportViewerExternal')
export const PermissionRoleMetaOrgAdminTB = Type.Literal('metaOrgAdmin')
export const PermissionRoleMetaOrgMemberTB = Type.Literal('metaOrgMember')
// ruminati roles
export const PermissionRoleSupportUserTB = Type.Literal('supportUser')

export const PermissionRoleTB = Type.Union([
  PermissionRoleCreatorTB,
  PermissionRoleViewerTB,
  PermissionRoleEditorTB,
  PermissionRoleOwnerTB,
  PermissionRoleMemberTB,
  PermissionRoleReportManagerTB,
  PermissionRoleReportViewerTB,
  PermissionRoleAdministratorTB,
  PermissionRoleOrgAdminTB,
  PermissionRoleReportManagerInternalTB,
  PermissionRoleReportManagerExternalTB,
  PermissionRoleReportViewerInternalTB,
  PermissionRoleReportViewerExternalTB,
  PermissionRoleMetaOrgAdminTB,
  PermissionRoleMetaOrgMemberTB,
  PermissionRoleSupportUserTB
])
export type PermissionRoleT = Static<typeof PermissionRoleTB>
