import HorizontalDivider from "../../../components/horizontal_divider";
import { BodyText } from "../../../components/styled_text";
import { FieldSize, FormField, State, evaluate, TypeOrFunctionOfState, FormFieldRenderOptions } from "./form_field";
import { Row, Column } from "../../../components/styled_layout";
import { ruminatiColors } from "@/utilities/colors";
import { InfoCard } from "@/components/card";

interface LabelFormFieldArgs {
    label: string;
    align?: string;
    size?: FieldSize
    additionalAction?: TypeOrFunctionOfState<JSX.Element>
}

export class LabelField implements FormField<any> {
    size: FieldSize = FieldSize.Full;
    label: string = "";
    align?: string = "";
    _additionalAction?: TypeOrFunctionOfState<JSX.Element>

    constructor(args: LabelFormFieldArgs) {
        this.size = args.size ?? FieldSize.Full
        this.label = args.label ?? "";
        this.align = args.align === undefined ? "left" : args.align;
        this._additionalAction = args.additionalAction ?? undefined
    }

    additionalAction(state: State<any>) {
        return evaluate(this._additionalAction, state)
    }

    displayValue = () => undefined;

    required = (_: any) => false;

    validate(): boolean {
        return true;
    }

  render(options: FormFieldRenderOptions<null>): JSX.Element {
        return (
            <Row style={{
                width: "100%",
                justifyContent: 'space-between',
                margin: '-10px 0px'
            }} >
                <Column>
                    <BodyText
                        style={{
                            width: "100%",
                            fontSize: "16px",
                            fontWeight: 600,
                            color: ruminatiColors.green_3,
                            textAlign: this.align as any,
                        }}
                    >
                        {this.label}
                    </BodyText>
                </Column>
                <Column>
                    {this.additionalAction(options.state)}
                </Column>
            </Row>
        );
    }
}

export class SeparatorField implements FormField<null> {
    size: FieldSize = FieldSize.Full;

    displayValue = () => undefined;

    required = (_: any) => false;

    validate(): boolean {
        return true;
    }

    render(_options: FormFieldRenderOptions<null>): JSX.Element {
        return (
            <div style={{ width: "100%" }}>
                <HorizontalDivider padding="0px" height='24px'/>
            </div>
        );
    }
}

export type InfoFieldArgs = {
    content: JSX.Element | string
}
export class InfoField implements FormField<null> {
    content: JSX.Element | string

    constructor(args: InfoFieldArgs) {
        this.content = args.content
    }

    size: FieldSize = FieldSize.Full;

    displayValue = () => undefined;

    required = (_: any) => false;

    validate(): boolean {
        return true;
    }

    render(_options: FormFieldRenderOptions<null>): JSX.Element {
        return (
           <Row>
                <InfoCard content={this.content}/>
            </Row>
        );
    }
}

export interface CustomFieldArgs {
    blockSubmission: boolean;
    content: JSX.Element;
}

export class CustomField implements FormField<null> {
    blockSubmission?: boolean; // Whether to block the form from submitting
    content: JSX.Element;

    constructor(args: CustomFieldArgs) {
        this.blockSubmission = args.blockSubmission;
        this.content = args.content;
    }

    size: FieldSize = FieldSize.Full;
    displayValue = () => undefined;
    required = (_: any) => true;
    validate = () => !this.blockSubmission;

    render(_options: FormFieldRenderOptions<null>): JSX.Element {
        return this.content;
    }
}

export interface CustomFieldArgsWithState {
    content: (state: State<any>) => JSX.Element;
}

export class CustomFieldWithState implements FormField<null> {
    content: (state: State<any>) => JSX.Element;

    constructor(args: CustomFieldArgsWithState) {
        this.content = args.content;
    }

    size: FieldSize = FieldSize.Full;
    displayValue = () => undefined;
    required = (_: any) => true;
    validate = () => true;

    render(options: FormFieldRenderOptions<null>): JSX.Element {
        return this.content(options.state);
    }
}
