import styled from "styled-components";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { uid } from 'uid';

import { LinkedFarmStatusTypeAcceptedTB, LinkedFarmStatusTypeSharedTB } from "@ruminati/types/vision_linked_farm";

import { createFarmLinkage, createShareAll } from "../../../services/vision_service";
import { BodyText } from "../../styled_text";
import { CreateLinkedFarmsDTO } from "../../../models/vision";
import { DropdownField } from "../../form/input_field";
import { useDatabaseStore } from "../../../state/database_store";
import { usePartnerStore } from "../../../state/partners";
import { ruminatiColors } from "../../../utilities/colors";
import { Column, Row } from "../../styled_layout";
import LoadingButton from "../../buttons/loading_button";
import { usePopupStore } from "../../../state/popup_store";
import TextButton from "../../buttons/text_button";
import Icon from "../../icon";
import SharingPermissionsCard from "@/components/cards/sharing_permissions";
import { useAuthStore } from "@/state/auth_store";

type SelectedPropertyField = {
    selectorId: string;
    farmId: string | undefined;
};


/**
 * The Add Corporate Partner screen for initiating a new Farm Linkage
 * @returns the Add Corporate Partner Screen component
 */
export default function ConnectCorporatePartnerPopup(props: {
    inviteId: string
    shareAll?: boolean
    onSubmit: () => void
}) {
    const databaseStore = useDatabaseStore();
    const partnerStore = usePartnerStore();
    const user = useAuthStore().user

    const invitations = partnerStore.invitations ?? []
    const linkedFarms = partnerStore.linkedFarms ?? []

    const popupStore = usePopupStore();

    const [searchParams, setSearchParams] = useSearchParams()

    const properties = databaseStore.properties ?? [];

    const [farmIdsToShare, setFarmIdsToShare] = useState<SelectedPropertyField[]>([{ farmId: undefined, selectorId: uid() }]);

    const [permissionGranted, setPermissionGranted] = useState<boolean>(false);

    const selectedInvite = invitations.find(invite => invite.invite.id === props.inviteId)
    if (!selectedInvite) return

    const addFarmIdToList = (farmId: string | undefined) => {
        setFarmIdsToShare(() => [
            ...farmIdsToShare.filter(f => f.farmId !== undefined),
            { farmId, selectorId: uid() }
        ])
    }

    const activeOrPendingLinkedFarms = linkedFarms?.filter(lf => lf.linkedFarm.status === LinkedFarmStatusTypeSharedTB.const || lf.linkedFarm.status === LinkedFarmStatusTypeAcceptedTB.const)

    const createConnection = async () => {
        if (selectedInvite === undefined) return;
        const params: CreateLinkedFarmsDTO = {
            linkedFarms: []
        };

        const existingLinkedFarms = activeOrPendingLinkedFarms || [];
        const filteredFarmIdsToShare = farmIdsToShare
            .filter(selected => selected.farmId !== undefined)
            .map(selected => selected.farmId as string);

        for (const selected of filteredFarmIdsToShare) {
            if (existingLinkedFarms.some(existingLf => existingLf.linkedFarm.visionOrgId === selectedInvite.invite.visionOrgId &&
                existingLf.linkedFarm.propertyId === selected)) {
                continue;
            }
            params.linkedFarms.push({
                visionOrgId: selectedInvite.invite.visionOrgId,
                propertyId: selected,
                shareType: selectedInvite.invite.dataSharingType
            });
        }

        const response = await createFarmLinkage(params);
        if (response) {
            response.forEach(linkedFarm => {
                partnerStore.addLinkedFarm(linkedFarm);
            });
        }
    };

    // Only shows farms not already selected in other dropdowns
    function getFarmsAsOptions(selectorId: string) {
        const unsharedProperties = properties.filter(p => !activeOrPendingLinkedFarms?.some(lf => lf.linkedFarm.propertyId === p.id && lf.linkedFarm.visionOrgId === selectedInvite?.invite.visionOrgId))
        const selectedFarmIds = farmIdsToShare.filter(f => f.selectorId !== selectorId).map(f => f.farmId)
        return unsharedProperties.filter(p => !selectedFarmIds.includes(p.id)).map(p => ({
            value: p.id,
            name: p.name
        }))
    }

    function getFarmSelectorFields() {
        return farmIdsToShare.map((farmField, idx) => (
            <Row key={idx} style={{ width: '100%', marginBottom: '10px' }}>
                <Column style={{ width: '100%' }}>
                    <DropdownField
                        disabled={selectedInvite === undefined}
                        size="small"
                        id={`farm-selector-${idx}`}
                        placeholder="Select enterprise"
                        value={farmField.farmId}
                        options={getFarmsAsOptions(farmField.selectorId)}
                        onSelect={(value) => {
                            if (value) addFarmIdToList(value)
                        }}
                    />
                </Column>
            </Row>
        ))
    }

    function calcShouldBeDisabled (): boolean {
       if (!permissionGranted) return false
       if (!selectedInvite) return false
       if (!props.shareAll) {
         if (farmIdsToShare && farmIdsToShare[0] && !farmIdsToShare[0].farmId) {
            return false
         }
       }
        return true
    }
    const isSubmitDisabled = !calcShouldBeDisabled()

    return (
        <CorporatePartnerWrapper>
            {!props.shareAll ? <>
                <LabelText>
                    Enterprise/s you want to share
                </LabelText>
                {getFarmSelectorFields()}

                {properties.length > 1 ?
                <Row style={{ alignItems: 'center', marginBottom: '14px' }}>
                    <TextButton
                    fontSize="15px"
                    textColor={ruminatiColors.green_3_50}
                    hoverTextColor={ruminatiColors.green_3}
                    activeTextColor={ruminatiColors.green_3}
                    onClick={() => addFarmIdToList(undefined)}
                    >
                        <Icon icon="circlePlus" />
                        Add another enterprise to share
                    </TextButton>
                </Row>
                : undefined 
                }
            </> : undefined
            }
            
            <SharingPermissionsCard
            usingVoucher={false}
            invite={selectedInvite} 
            checkboxChanged={(isChecked) => {
                setPermissionGranted(isChecked)
            }}
            />

            <Row style={{ alignItems: 'center', marginTop: '20px' }}>
                <LoadingButton
                    onClick={async () => {
                        searchParams.delete('inviteId')
                        setSearchParams(searchParams)
                        if (props.shareAll && user && user.uid) {
                            try {
                                await createShareAll({
                                    visionOrgId: selectedInvite.invite.visionOrgId,
                                    userId: user.uid
                                })                                
                            } catch (err) {
                                console.log(err)
                            }
                        } else {
                            try {
                                await createConnection()
                            } catch (err) {
                                console.log(err)
                            }
                        }
                        props.onSubmit()
                        popupStore.closePopup();
                    }}
                    colorScheme="green"
                    width={'120px'}
                    size="small"
                    disabled={isSubmitDisabled}
                >
                    Share
                </LoadingButton>
            </Row>
        </CorporatePartnerWrapper>
    );
}

const CorporatePartnerWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
    max-height: calc(100vh - 300px);
`;

const LabelText = styled(BodyText)`
    font-size: 16px;
    align-self: flex-start;
`;