import { Row } from "../styled_layout";
import { patchUserProfile } from "../../services/auth_service";
import { Form, FormData } from "../../models/form/form";
import { TextFormField } from "../../models/form/form_fields/basic_form_fields";
import { FieldSize } from "../../models/form/form_fields/form_field";
import { useAuthStore } from "../../state/auth_store";
import { usePopupStore } from "../../state/popup_store";

interface AccountInfo {
  firstName: string;
  lastName: string;
}

export default function UserProfilePopup() {
  const authStore = useAuthStore()
  const popupStore = usePopupStore()
    const accountFormData: FormData<AccountInfo> = {
      fields: {
          firstName: new TextFormField({
              required: true,
              placeholder: "First Name",
              size: FieldSize.Full
          }),
          lastName: new TextFormField({
              required: true,
              placeholder: "Last Name",
              size: FieldSize.Full
          })
      }
    };

    const accountInfo: AccountInfo = {
      firstName: authStore.user?.firstName ?? "",
      lastName: authStore.user?.lastName ?? ""
    };

    return (
      <Row
      style={{
        padding: '40px'
      }}>
        <Form
          data={accountFormData}
          buttonSize="small"
          width="400px"
          fieldSize="small"
          initialValue={accountInfo}
          submitText="Save changes"
          onSubmit={async (value) => {
              const newUser = await patchUserProfile(value)
              if (newUser) authStore.signIn(newUser)
              popupStore.closePopup();
          }}
          />
      </Row>
    );
}
