import { useNavigate } from "react-router-dom";

import { usePopupStore } from "../../state/popup_store";
import { useAuthStore } from "../../state/auth_store";
import { useDatabaseStore } from "../../state/database_store";

import { Property, Report, PropertyType } from "../../models/property_models";
import { SubscriptionType } from "../../models/subscription_model";
import DisplayCard from "../display_card";
import CardActionButton from "../buttons/card_action_button";
import InlineResultsSummary from "../inline_results_summary";
import { FarmsPopupId } from '../../screens/farms'
import DropdownSelect, { DropdownSelectOption } from "../dropdown_select";

import { getCurrentFinancialYear, getYearsBetween } from "../../utilities/dates";
import { getReportsOfProperty, getCompletedReports, getReportOfYear } from "../../utilities/reports";
import { ruminatiColors } from "../../utilities/colors";
import { getPropertyNextStep } from "../../utilities/next_steps";
import { BodyText } from "../styled_text";
import { Row } from "../styled_layout";
import { useDeleteProperty } from "@/hooks/properties";
import { openNotificationError } from "@/utilities/notification-defaults";
import { getResourcePermissions, permissionsAllowDelete } from "@/utilities/permissions";
import { getCurrentOrganisation } from "@/utilities/organisations";

export type EnterpriseSummaryCardProps = {
  property: Property
};

export default function EnterpriseSummaryCard({
  property
}: EnterpriseSummaryCardProps) {
  const navigate = useNavigate();

  const databaseStore = useDatabaseStore();
  const popupStore = usePopupStore();
  const authStore = useAuthStore()
  const user = authStore.user

  const currentOrg = user ? getCurrentOrganisation(user!) : undefined
  const enterprisePermissions = getResourcePermissions(user!, property.id)
  const canDelete = permissionsAllowDelete(enterprisePermissions)

  const deleteProperty = useDeleteProperty(property.id)

  const propertyReports: Report[] = getReportsOfProperty(databaseStore.reports ?? [], property.id);
  const completedReportsOnProperty = getCompletedReports(propertyReports).sort((a, b) => b.financialYear - a.financialYear)

  const isPrime = currentOrg?.subscription?.type === SubscriptionType.PRIME;

  const maxReportDate = propertyReports.reduce((maxDate, report) => {
    const reportMaxDate = Math.max(report.completionDate ?? 0, report.createdDate);
    return Math.max(maxDate, reportMaxDate);
  }, 0);

  const date = maxReportDate > 0 ? maxReportDate : undefined

  function startDeleteFarmPopup(propertyId: string, propertyName: string) {
    popupStore.addPopup(FarmsPopupId.DeleteFarm, undefined, {
      propertyId,
      propertyName,
      onConfirm: async () => {
        // N.B. can't easily pass deleteProperty "pending" state to the popup manager, as this object closes over any hook state (and doesn't re-render when it changes)... think about an alternative?
        await deleteProperty.mutateAsync(undefined, {
          onError: (err) => {
            openNotificationError({
              heading: 'Error deleting Enterprise',
              message: 'Looks like something went wrong. Please try again later or let us know if you continue to have problems.',
              cause: err instanceof Error ? err.cause as string : undefined
            })
          }
        })
      }
    })
  }

  function getActionButtons(): JSX.Element[] {
    return [
      <CardActionButton
        onClick={() => navigate(`/farm/${property.id}`)}
        label="Manage"
      />,
      <CardActionButton
        onClick={() => startDeleteFarmPopup(property.id, property.name)}
        colorScheme="red"
        label="Delete"
        disabled={!canDelete}
        disabledMessage={"You don't have enough permissions to Delete this enterprise"}
      />,
      getJumpToBtn()
    ];
  }

  function getJumpToBtn(): JSX.Element {
    const options: DropdownSelectOption[] = [];
    if (isPrime && !(PropertyType.Feedlot === property.propertyType || PropertyType.Processor === property.propertyType || PropertyType.FurtherProcessor === property.propertyType) &&
      completedReportsOnProperty.length > 0
    ) {
      options.push({
        label: "Reduction Plans",
        onClick: () => navigate(`/farm/${property.id}/reductionPlanner/`)
      })
    }

    getYearsBetween(2021, getCurrentFinancialYear() - 1).reverse().forEach(y => {
      const yearReport = getReportOfYear(propertyReports, y)
      if (yearReport) {
        options.push({
          label: `Report FY ${y}`,
          onClick: () => navigate(`/summary/${yearReport.id}`),
        })
      }
    })

    return (
      <DropdownSelect
        width="170px"
        text="Jump To"
        options={options}
        colorScheme="transparent"
        useBlurBackground={false}
      />
    )
  }

  function getPropertySummary(): JSX.Element[] {
    const fallbackText =
      propertyReports.length === 0
        ? "No emission reports created yet"
        : "Emission report in progress";

    return [
      <InlineResultsSummary
        report={completedReportsOnProperty[0]}
        previous={completedReportsOnProperty[1]}
        fallbackColor={propertyReports.length === 0 ? ruminatiColors.effective_black : ruminatiColors.orange}
        fallbackText={fallbackText}
        key={property.id}
      />,
    ];
  }

  function getFooter(): JSX.Element | undefined {
    const nextStep = getPropertyNextStep(isPrime, propertyReports, undefined, property)
    if (nextStep === undefined) return undefined

    return <Row
      style={{
        cursor: 'pointer'
      }}
      onClick={() => {
        if (nextStep) navigate(nextStep.actionLink)
      }
      }>
      <BodyText style={{ marginRight: '12px' }}>Next Steps</BodyText>
      <BodyText>{nextStep?.text}</BodyText>
    </Row>
  }

  return (<DisplayCard
    title={property.name}
    property={property}
    key={`${property.id}${property.name}`}
    buttons={getActionButtons()}
    contentArea={getPropertySummary()}
    footerText={getFooter()}
    date={date}
  />)
}
